<template>
    <div> 
    <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false" :hide-on-outside-click="true"
        :show-close-button="true" :title="$t('central-Varovani')" :contentTemplate="popupAlertTemplate" :width="400"
        :height="200">
        <template #SmazatPoz>
            <h3>{{$t('central-OpravduSiPrejeteSmazat')}}</h3>
            <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained"
                :text="$t('central-Ano')" @click="SetState(-99, true); popupAlert=false " />
            <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained"
                :text="$t('central-Ne')" @click="popupAlert=false" />
        </template>
    </DxPopup>
   
    <div id="ResponsiveBoxAAA">
    <DxResponsiveBox id="aaa"  single-column-screen="sm" width="inherit">
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
               
                <DxItem>
                    <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                    <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                    <DxLocation :row="0" :col="0" :colspan="2" screen="sm" />
                    <DxLocation :row="0" :col="0" :colspan="2" screen="xs" />
                    <div class="pozcontent">
                        <span id="hlavicka" > {{$t('pozadavky-Nazev:')}}
                            <span style="font-weight:lighter" v-bind:title="String.fromCharCode(160)+ pozContent">
                                {{String.fromCharCode(160)+ pozContent }}
                            </span>
                        </span>
                    </div>
                </DxItem>
                <DxItem>
                    <DxLocation :row="0" :col="2" :colspan="1" screen="lg" />
                    <DxLocation :row="0" :col="2" :colspan="1" screen="md" />
                    <DxLocation :row="0" :col="2" :colspan="1" screen="sm" />
                    <DxLocation :row="0" :col="2" :colspan="1" screen="xs" />
                    <div class="pozcontent">
                        <span id="hlavicka" > {{$t('pozadavky-Sledujici')}}
                            <span style="font-weight:lighter" v-bind:title="String.fromCharCode(160)+ pozWatcherTXT ">
                                {{String.fromCharCode(160)+ pozWatcherTXT }}
                            </span>
                            <ChangeOwner v-if="zmena_sledujici" :Disabled="false" :RequestID="pozId" @owner-changed="setSelectedValue" Label="none" :Title="this.$t('central-EditovatSledujiciho')" id="idChangeOwner" :ChangeWatcher="true" >  </ChangeOwner>
                        </span>
                    </div>
                </DxItem>
                <DxItem>
                    <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                    <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                    <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                    <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />
                    <div class="pozcontent">
                        <span id="hlavicka" > {{$t('pozadavky-Zamestnanec:')}}
                            <span style="font-weight:lighter" v-bind:title="String.fromCharCode(160)+ pozOwner ">
                                {{ String.fromCharCode(160)+pozOwner }}
                            </span> 
                            <ChangeOwner v-if="upravitVlastnika" :Disabled="false" :RequestID="pozId" @owner-changed="setSelectedValue" Label="none" id="idChangeOwner" >  </ChangeOwner>                                   
                        </span>
                    </div>
                </DxItem>
                <DxItem>
                    <DxLocation :row="1" :col="1" :colspan="1" screen="lg" />
                    <DxLocation :row="2" :col="0" :colspan="2" screen="md" />
                    <DxLocation :row="2" :col="0" :colspan="2" screen="sm" />
                    <DxLocation :row="2" :col="0" :colspan="2" screen="xs" />
                    <div class="pozcontent">
                        <span id="hlavicka" > {{$t('pozadavky-Stav:')}}
                            <span style="font-weight:lighter" v-bind:title="String.fromCharCode(160)+ pozReqstatetext ">
                                {{String.fromCharCode(160)+ pozReqstatetext }}
                            </span>
                        </span>
                    </div>
                </DxItem>

                <DxItem>
                    <DxLocation :row="1" :col="2" :colspan="1" screen="lg" />
                    <DxLocation :row="2" :col="2" :colspan="1" screen="md" />
                    <DxLocation :row="2" :col="2" :colspan="1" screen="sm" />
                    <DxLocation :row="2" :col="2" :colspan="1" screen="xs" />
                            <!--<div id="DateBoxdiv" height="auto" width="max-content"> 
                                <DxDateBox id="datebox" :label="$t('central-DatumPosledniZmeny')" height="40px" width="200px" :value="pozLastchange" type="datetime"  :readOnly="true"
                                    display-format="dd. MM. yyyy HH:mm" style="margin-top: 5px;" :accept-custom-value="false" :open-on-field-click="true"/>
                                {{ dateFormaterDDMMYYYYHHMM(pozLastchange) }}
                            </div> VERZE DATEBOX-->
                        <span class="pozcontent">
                            <span id="hlavicka" > {{$t('central-DatumPosledniZmeny')+':'}}
                                <span style="font-weight:lighter" v-bind:title="String.fromCharCode(160)+ dateFormaterDDMMYYYYHHMM(pozLastchange) ">
                                    {{String.fromCharCode(160)+ dateFormaterDDMMYYYYHHMM(pozLastchange) }}
                                </span>
                            </span>
                        </span>
                </DxItem>
            </DxResponsiveBox>
            <DxButton v-if=" false " class="button1" id="print" height="auto" width="auto" type="default" styling-mode="contained" 
                      :text="$t('central-Tisk')" @click="Print()" />
        </div>
            <slot />
            
        <div class="obaleniPozadavkyBase" id="obaleniPozadavkyBaseKOMENTAR" >
            <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm">
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxCol :ratio="3" />
                <DxCol :ratio="3" />
                <DxCol :ratio="1" />
                <DxItem>
                    <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                    <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                    <DxLocation :row="3" :col="0" :colspan="1" screen="sm" />
                    <DxLocation :row="3" :col="0" :colspan="1" screen="xs" />
                    
                    <div> 
                        <Attachments :Disabled="!upravit && !pridatPrilohy" :Pozid="this.pozId" :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1" :InstanceId="attachmentsIds[0]" :LabelHeaderWordKey=this.PopisPrilohKey />
                    </div>                         
                </DxItem>
        
                <DxItem>
                    <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                    <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                    <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                    <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />           
                        <div class="DxSelectBoxResponsive" id="komentarDiv">
                            <DxTextArea id="komentar" :label="$t('central-Komentar')" width="100%" height="100%" :value="objKomentar" placeholder=""
                                :read-only="true" /> <!--:hint="$t('central.KomentarEditace')" -->
                        </div>     
                </DxItem>
                </DxResponsiveBox>
        </div>
        <div class="obaleniPozadavkyBase" id="obaleniPozadavkyBaseSCHVALOVANI">
                    <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm">
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="3" />
                    <DxCol :ratio="3" />
                    <DxCol :ratio="1" />
                
                    <DxItem>
                        <DxLocation :row="0" :col="1" :colspan="3" screen="lg" />
                        <DxLocation :row="0" :col="1" :colspan="3" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />
                        <DxScrollView direction="horizontal" showScrollbar="onHover" >
                            <div v-html="obsah" id="schvalproces1" class="schvalovani ResponsiveBoxBaseBottom"> </div>
                        </DxScrollView>
                    </DxItem>
                    <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="1" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="1" screen="xs" />
                        <div class="schvalovani ResponsiveBoxBaseBottom" v-if="schvalovani" id="obaleniKOmentarSchvalAkce">
                            <h3 > {{ $t('pozadavky-Schvalovani:') }}</h3>
                            <form action="your-action" @submit="onFormSubmit($event)">
                                <div id="komentarKeSchvalovaniDiv">
                                <DxTextArea id="komentar" :label="$t('pozadavky-KomentarKeSchvalovani')" :height="65" width="100%" v-model:value="pozKomentar"
                                    placeholder="" :maxLength="100"   />
                                <WarningImage v-if="this.ToFill" ColorRGBWarningBox="rgb(0, 0, 0)" HeightIcon="32px" WidthIcon="32px" PopisChybyKEY="central.VyplnteDuvodDoKomentare" /> <!--v-if="this.formDataCarUsage.vykricnik1"-->
                                </div>  
                            </form>
                        </div>
                    </DxItem>
                </DxResponsiveBox>
            
            </div>

           <div id="SpodniMenu"  height="auto">
            <div id="SpodniMenuTop" v-if="schvalovani" height="150px"> 
                <div id="inner" v-if="AdditionalFields.length>0 " class="divDoplnenaPole">
                    <h3 v-if="AdditionalFields.length>0 ">{{$t('Pozadavky.DoplnenaPole')}}</h3>
                    <div class="child" v-for="item in AdditionalFields" :key="item">
                        <DxTextBox width='300px' height="45px" :label="item.Title" v-if="item.Component=='T'" :id="item.Id" :value="item.Content"
                        :placeholder="item.Detail" @value-changed="setParamValue($event,item);" class="upozorneni"/>           
                        <DxSelectBox width='300px' height="45px" value-expr="id" :value="item.selected" :label="item.Title" v-if="item.Component=='L'" :id="item.Id" :data-source="item.DataSource" :search-enabled="true"
                            display-expr="content" :placeholder="item.Detail" @value-changed="setParamValue($event, item);" class="upozorneni" />
                        <DxDateBox :value="new Date()" height="45px" :placeholder="item.Detail" v-if="item.Component=='D'" :id="item.Id" :label="item.Title" width="160px" type="date" @value-changed="setParamValue($event, item);"
                        display-format="dd. MM. yyyy" :applyButtonText="$t('dotazovac-Potvrdit')" :cancelButtonText="$t('dotazovac-Zrusit')" class="upozorneni" :accept-custom-value="false" :open-on-field-click="true"/>
                        <DxCheckBox width='300px' height="45px" :label="item.Title" v-if="item.Component=='C'" :id="item.Id" :value="item.Content"
                        :placeholder="item.Detail" @value-changed="setParamValue($event, item);" class="upozorneni"/>      
                    </div>
                </div>
            </div>
            <span v-if="ElPodpis && stateid==0"  id="signerlabel" > {{ $t('central-OtevreniEkoisSigneru') }}</span> 
            <span class="SpodniMenuBottom">
                <DxScrollView direction="horizontal" showScrollbar="always" width="100%" >
                    <div class="SpodniMenuBottom">
                        <div v-if="schvalovani" class="childSpodniMenu SpodniMenuBottom" > 
                                <DxButton v-if="actionsPozadavky.some(item => item.id === 1)" height="100%" icon="todo" :text="$t('central-Schvalit')" type="success" styling-mode="contained"  id="schvalit" @click="SetState(1)"
                                    :use-submit-behavior="true" class=" buttonKeSchvaleni button1 actionButtonsPozadavkyResizableA"> 
                                </DxButton>  
                                <DxButton  v-if="actionsPozadavky.some(item => item.id === 3)" height="100%" icon="revert" :text="$t('central-Vratit')" type="default" id="vratit" @click="SetState(3)"
                                    :use-submit-behavior="true" class=" buttonKEditaci button1 actionButtonsPozadavkyResizableA" use/>
                                <DxButton  v-if="actionsPozadavky.some(item => item.id === 2)" height="100%" icon="close" :text="$t('central-Zamitnout')" type="danger" styling-mode="contained" id="zamitnout"
                                    @click="SetState(2)" :use-submit-behavior="true" class="buttonSmazat button1 actionButtonsPozadavkyResizableA" />            
                        </div>
                        <div class="SpodniMenuBottom">
                            <div v-if="!schvalovani" class="SpodniMenuBottom">
                            <div v-for="item in actionsPozadavky" :key="item" class="childSpodniMenu SpodniMenuBottom"  >
                                <DxButton class="button1 actionButtonsPozadavkyResizableA" height="100%" width="auto" type="default" styling-mode="contained"    
                                    :text="item.nazev" :hint="item.nazev" :id="item.id" @click="SetState(item.id)" :class="item.class" :icon="item.icon"
                                    v-show="(item.id != 1) && (item.id != 3) && (item.id != 2) && (item.id != -100)" />    
                            </div>
                        </div>
                            <div v-if="upravit" class="childSpodniMenu" >
                                <DxButton v-if="upravit" icon="edit" width="auto" height="100%"  styling-mode="contained"  :text="$t('central-Editovat')" :hint="$t('central-Editovat')" type="default" 
                                        @click="Edit(); $router.push('/' + typ)"  class="buttonKEditaci actionButtonsPozadavkyResizableA " />
                            </div>
                            <div v-if="ElPodpis && !schvalovani" class="childSpodniMenu">
                                <DxButton v-if="ElPodpis && !schvalovani" class="actionButtonsPozadavkyResizableA " icon="background" width="auto" height="100%"  styling-mode="contained"  :text="$t('central-ElPodpis')" :hint="$t('central-ElPodpis')" type="default" 
                                @click="ElectronicSignature()"  /> <!--v-if="ElPodpis && !schvalovani"-->
                            </div>
                            <div class="childSpodniMenu" height="100%">
                                <PrintVariants :Report="'Pozadavky' + pozReqtypeId"   :PrintID="pozId" height="100%"/>
                            </div>
                        </div>
                               <!--<DxButton v-if="typ == 'Smlouva' && reditel && stateid==1" icon="background" width="auto" height="auto"  styling-mode="contained"  :text="$t('central-ElPodpis')" type="default" 
                                        @click="ElectronicSignature()"  />
                                       -->       
                    </div>
                    </DxScrollView>
            </span>               
           
        </div>
    </div>
</template>
<script>
import ChangeOwner from '@/components/ChangeOwner.vue';
import axios from "axios";
import notify from 'devextreme/ui/notify';
import Attachments from '@/components/Attachments.vue';
import PrintVariants from '@/components/PrintVariants.vue';
import DataSource from 'devextreme/data/data_source';
import WarningImage from '@/components/WarningImage.vue';
import DxScrollView from 'devextreme-vue/scroll-view';
export default {
    /*props: ['test'],*/
    props: {
        PopisPrilohKey: {
            type: String,
            required: false,
            default: 'pozadavky-Prilohy'
        },
    },
    emits: ['toggle_scroll'],
    components: {
        Attachments,
        PrintVariants,
        ChangeOwner,
        WarningImage,
        DxScrollView
    },

data() {
    return {
            upravitSledujiciho: false,
            pridatPrilohy: false,
            ElPodpis: false,
            ESLabel: false,
            upravitVlastnika: false,
            Owner: 0,
            stateid: null,
            Loaded: false,
            LookupValue: -1,
            pozContent: "",
            pozWatcher: 0,
            pozWatcherTXT: "",
            popupAlertTemplate: '',
            popupAlert: false,
            pozLastchange: "",
            pozOwner: "",
            pozReqstatetext: "",
            pozReqtypetext: "",
            pozKomentar: '',
            objKomentar: "",
            schvalovani: false,
            upravit: false,
            zmena_sledujici:true,
            actionsPozadavky: [],
            pozId: window.pozId,
            typ:"",
            pozReqtypeId:0,
            obsah: '',
            reditel: false,
            AdditionalFields:[],
            screen(width) {
                return (width < 700) ? 'sm' : 'lg';
            },
            ToFill:false,
            attachmentsIds: [this.generateInstanceId()],
            
    }
    },
mounted() {
    this.$log.debug('pozadavkyBase')   
    this.DisplayRequest();
    this.LoadBarvyTheme(); 
    this.GetAdditionalFields();   
    var EmpID = null
    if(window.localStorage.getItem("user") != undefined) EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value
    if (EmpID == 3136) this.reditel = true
    //this.Prilohy()
    if (window.location.href.includes('&ToFill=1')) {
        this.$log.debug('URL contains "&ToFill=1"');
        var ObaleniKomentar = document.getElementById('obaleniPozadavkyBaseSCHVALOVANI');
        if (ObaleniKomentar) {
            ObaleniKomentar.classList.add('komentarToFillBackground');
            this.ToFill=true;
            } else {
            console.error('Element with ID "obaleniPozadavkyBaseSCHVALOVANI" not found.');
            this.ToFill=false;
            }
        } else {
        this.$log.debug('URL does not contain "&ToFill=1"');
        this.ToFill=false;
        }
},
    methods: {
        sendEmit(){
            this.$emit('toggle_scroll','komentar');
            this.$log.debug('EMITED toggle_scroll');
        },
        setSelectedValue(e) {
            window.this.$log.debug(e);
            switch (e.element.id) {
                case 'ownerchanged':
                    this.$router.push('/Pozadavky')
                    this.ClosePozadavkyPopups()
                    return 1;
                    case 'watcherchanged':
                        this.DisplayRequest()
                    //this.$router.push('/Pozadavky')
                   // this.ClosePozadavkyPopups()
                    return 1;
            }
        },
        generateInstanceId() {
            return Math.random().toString(36).substr(2, 9);
        },
        ClosePozadavkyPopups() {
            window.thisPozadavky.popupObj = false
            window.thisPozadavky.popupFaktura = false
            window.thisPozadavky.popupPrevod = false
            window.thisPozadavky.popupVyrazeni = false
            window.thisPozadavky.popupObjKnihovna = false
            window.thisPozadavky.popupPracCesta = false
            window.thisPozadavky.popupSmlouva = false
            window.thisPozadavky.popupPracSetkani = false
            window.thisPozadavky.popupPlatKarta = false
            window.thisPozadavky.popupVD = false
            window.thisPozadavky.popupHostKarta = false
            window.thisPozadavky.popupInventuryToConfirm = false       
            
        },
        lightenColor(rgbColor, factor) {
          
          const match = rgbColor.match(/^rgb\((\d+), (\d+), (\d+)\)$/);
          if (!match) {
              throw new Error("Invalid RGB color format");
          }

          // Extrakce stavajicich hodnot
          const red = parseInt(match[1]);
          const green = parseInt(match[2]);
          const blue = parseInt(match[3]);

          // Kalkulace svetlejsi barvy podle zadaneho faktoru
          const newRed = Math.min(255, red + factor);
          const newGreen = Math.min(255, green + factor);
          const newBlue = Math.min(255, blue + factor);

          return `rgb(${newRed}, ${newGreen}, ${newBlue})`;
          },
        LoadBarvyTheme(){
            var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
            var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
            var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
            var lightercolortheme = this.lightenColor(FillColorBarvaTheme,100);
        //console.log('Background Color:', FillColor);
        document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
        document.documentElement.style.setProperty('--colorByThemeLighter', lightercolortheme);
        },
        ElectronicSignature() {
            console.log('uvnitr ElectronicSignature')
             var self = this;
            var auth = new window.Auth(window.channel, window.message);
            var wh = new window.WorkFlow(window.channel, window.message);
            console.log(self.pozId)
            wh.GetRequest(self.pozId,
                function (result2) {
                    self.$log.debug(result2);
                    if (result2.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result2.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        self.$log.debug('reqstate '+ result2.ReqState.value);
                        console.log('reqstate ' + result2.ReqState.value)
                        self.$log.debug(self.ElPodpis);
                        if (self.ElPodpis) {
                            auth.GenerateToken(-1,
                                function (result) {
                                    self.$log.debug(result);
                                    self.$log.debug('ekoissigner:' + self.pozId + '&' + result);
                                    window.location.href = 'ekoissigner:' + self.pozId + '&' + result;
                                }, window.this.ApiError());
                        }
                    }
                }, window.this.ApiError());
                
        },
        FileDelete(FileID) {
            var self = this;
            var gen = new window.General(window.channel, window.message);
            gen.FileDelete(1, self.pozId, FileID,
                function (result) {
                    self.$log.debug(result);
                    self.Prilohy();
                }, window.this.ApiError());

        },
        async downloadItem(url, name, suffix) {
            this.$log.debug(suffix)
            suffix = '.pdf'
            let self=this;
            axios.get(url, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' })
                .then(function (response) {
                    switch (suffix) {
                        case '.pdf': {
                            self.$log.debug(response)
                            const blob = new Blob([response.data], { type: 'application/' + suffix });
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = name;
                            link.click();
                            self.$log.debug(link)
                            window.URL.revokeObjectURL(link.href);
                            break;
                        }
                    }
                })
                .catch(function (error) {
                    self.$log.debug(error)
                    self.$log.debug(JSON.stringify(error))
                })
        },
        Print()
        {
            var g = new window.General(window.channel, window.message);
            let self = this;
            g.Print('Request'+window.pozId, 
                function (result) {
                    self.$log.debug(result);
                    self.hash = window.URL1+'/js/download/' + result.Value.value;
                    self.$log.debug(self.hash + "pozadavek.pdf")
                    self.downloadItem(self.hash, "pozadavek.pdf");

                }, window.this.ApiError());
        },
        DisplayRequest() {
            window.this.$log.debug('DisplayRequest')
            let self=this
            self.actionsPozadavky.length = 0;
            var wh = new window.WorkFlow(window.channel, window.message);
            wh.GetRequest(self.pozId,
                function (result2) {
                    self.$log.debug(self.pozId)
                    self.$log.debug(result2);
                    if (result2.Error.value.ErrorNum.value != 0) {
                        self.upravitSledujiciho=false
                        notify({
                            message: result2.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        self.upravitSledujiciho=true
                        self.ElPodpis = false
                        for (var x = 0; x < result2.Actions.value.items.length; x++) {
                            var id = result2.Actions.value.items[x].Id.value;
                            var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : (id === 1 ? "buttonUlozitProUpravy" : (id === 3 ? "buttonUlozitProUpravy" : (id === 2 ? "buttonUlozitProUpravy" : "buttonDefault")))));
                            var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : (id === 1 ? "upload" : (id === 3 ? "trash" : (id === 2 ? "trash" : "")))));
                            self.actionsPozadavky[self.actionsPozadavky.length] = {
                                nazev: result2.Actions.value.items[x].Value.value,
                                id: result2.Actions.value.items[x].Id.value,
                                class: colorClass,
                                icon: iconName,
                                pozid: self.pozId
                            }; 
                            if (result2.Actions.value.items.some(item => item.Id.value === -100)) self.ElPodpis = true
                        }
                        self.pozContent = result2.Content.value
                        self.pozOwner = result2.Owner.value
                        self.pozReqstatetext = result2.ReqStateText.value
                        self.stateid = result2.ReqState.value
                        self.pozVia = result2.ReqViaText.value
                        self.objKomentar = result2.Comment.value
                        self.pozReqtypeId = result2.ReqType.value
                        self.pozWatcher = result2.Watcher.value
                        self.pozWatcherTXT = result2.WatcherTXT.value
                        switch (self.pozReqtypeId) {
                            case 1:
                                self.typ = 'Objednavka'
                                break;
                            case 11:
                                self.typ = 'Faktura'
                                break;
                            case 2:
                                self.typ = 'Prevodmajetku'
                                break;
                            case 3:
                                self.typ = 'Vyrazenimajetku'
                                break;
                            case 4:
                                self.typ = 'ObjednavkaKnihovna'
                                break;
                            case 5:
                                self.typ = 'Pracovni-obed-vecere'
                                break;
                            case 6:
                                self.typ = 'Vyvojovedilny'
                                break;
                            case 7:
                                self.typ = 'PlatKarta'
                                break;
                            case 9:
                                self.typ = 'InventurniSoupis'
                                break;
                            case 12:
                                self.typ = 'Smlouva'
                                break;
                            case 13:
                                self.typ = 'PracCesta'
                                break;
                            case 14:
                                self.typ = 'HostKarta'
                            break;
                        }
                        self.$log.debug(self.typ)
                        self.pozLastchange = result2.LastChange.value
                        self.pozReqtypetext = result2.ReqTypeText.value
                        self.pozId = result2.Id.value
                        self.upravitVlastnika = result2.AbleToEditOwner.value
                        self.pridatPrilohy = result2.AbleToAddAttachments.value
                        if (result2.AbleToApprove.value == true) self.schvalovani = true
                        else self.schvalovani = false
                        if (result2.AbleToEdit.value == true) {
                            self.upravit = true
                            self.readOnly = false
                        }
                        else {
                            self.upravit = false;
                            self.readOnly = true
                        }
                        if (result2.AbleToChangeWatcher.value == true) self.zmena_sledujici = true
                        else self.zmena_sledujici = false
                        var sloupcePocet = 0
                        self.obsah = '';
                        if (result2.ApproveHistory.value.items.length > 0) {
                            var sloupce = '<h3>' + self.$t('pozadavky-SchvalProces:') + '</h3>'   // Styl nastavovan v Pozadavky v non scoped stylu
                            sloupce += '<table  id=schvaltable bgcolor="#d3cec4">'; //#d5cfbb
                            sloupce += '<td  ></td>'
                            self.obsah += '<tr  id=idtd bordercolor="#FFA500">';
                            var pocetschvaleni = 0;
                            for (x = 0; x < result2.ApproveHistory.value.items.length; x++) {
                                if (self.reditel && self.pozReqtypeId == 12 && result2.ApproveHistory.value.items[x].Resolution.value == 1) { // v tuto chvili se nepouziva
                                    pocetschvaleni++;
                                }
                                if (window.prevLevel != result2.ApproveHistory.value.items[x].Level.value) {
                                    if (window.prevLevel != 0) self.obsah += '</tr>';
                                    window.prevLevel = result2.ApproveHistory.value.items[x].Level.value;
                                }
                                var tag
                                var uroven = result2.ApproveHistory.value.items[x].Level.value + self.$t('pozadavky-Uroven')

                                if (result2.ApproveHistory.value.items[x].RequestGroup.value == 1)
                                    self.obsah += '<th id=idtd>' + uroven + '</th>'
                                if (result2.ApproveHistory.value.items[x].Resolution.value == 0) tag = '<td id=idtd>'; else
                                    if (result2.ApproveHistory.value.items[x].Resolution.value == 1) tag = '<td id=idtd bgcolor="#34d730">'; else
                                        tag = '<td id=idtd bgcolor="#d62c1c" bordercolor="#fff7e8">';
                                self.obsah += tag + result2.ApproveHistory.value.items[x].RequestGroupText.value + '<br>' + result2.ApproveHistory.value.items[x].Approver.value + '<br>';
//                                if (result2.ApproveHistory.value.items[x].ResolutionDate.value.getFullYear() != 1899) self.obsah += result2.ApproveHistory.value.items[x].ResolutionDate.value.toLocaleString() + ' ';

                                self.obsah += result2.ApproveHistory.value.items[x].Comment.value + '</td>';
                                var schvalovatel = '<th id=idth>' + result2.ApproveHistory.value.items[x].RequestGroup.value + self.$t('pozadavky-Schvalovatel') + '</th>'
                                if (result2.ApproveHistory.value.items[x].RequestGroup.value > sloupcePocet) {
                                    sloupce += schvalovatel
                                    sloupcePocet++
                                }

                            }
                            if (self.reditel && pocetschvaleni >= result2.ApproveHistory.value.items.length - 1 ) self.ESLabel = true; // v tuto chvili se nepouziva
                            else self.ESLabel = false; // v tuto chvili se nepouziva
                            self.obsah += '</tr></table>';


                            sloupce += '</tr>'
                            self.obsah = sloupce + self.obsah

                            self.$log.debug('Obsah ' + self.obsah)
                        }
                        self.$log.debug("schvalproces" + result2.ReqType.value)
                        self.Prilohy();                  
                        //document.getElementById("schvalproces" + result2.ReqType.value).innerHTML = obsah;
                        //self.$log.debug('log obsahu'+ window.obsah)
                    }
                }, window.this.ApiError());


        },
        ComponentDatasource(Value, ItemIndex) {
            var self = this
            var gen = new window.General(window.channel, window.message);
            window.this.$log.debug(Value)
            window.this.$log.debug(ItemIndex)
            const data = new DataSource({
                // ...
            }); 
            gen.ListLookup(Value, '', 1, 50,
                function (result) {
                    self.$log.debug(result)
                    const store = data.store();
                    store.clear()
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        store.insert({
                            content: result.List.value.items[x].Detail.value,
                            id: result.List.value.items[x].Id.value,
                        })
                            .then(
                                () => {
                                    data.reload();
                                },
                                (error) => { self.$log.debug(error) /* ... */ }
                            );
                    }
                    if (self.LookupValue > 0) {
                        self.AdditionalFields[ItemIndex].selected = self.LookupValue
                        self.LookupValue = -1
                    }
                    setTimeout(() => {
                        self.Loaded = true
                    }, 500);
                    self.$log.debug('jsem tu')
                    self.$log.debug(data)
                }, window.this.ApiError());
            return data;
        },
        setParamValue(e,fieldData) {
            this.$log.debug(e);
            this.$log.debug(fieldData);
            this.$log.debug(e.element.id)
            switch (this.AdditionalFields[e.element.id - 1].Component) {
                case 'T':
                    this.AdditionalFields[e.element.id - 1].Content = e.value
                    //this.addParamsToLocation({ [this.params[e.element.id - 1].ParamValue]: e.value})
                    this.Loaded = true
                    break;
                case 'L':
                    this.AdditionalFields[e.element.id - 1].Content = e.value
                    // this.addParamsToLocation({ [this.params[e.element.id - 1].ParamValue]: e.value })
                    break;
                case 'D':
                    this.AdditionalFields[e.element.id - 1].Content = e.value.toISOString().slice(0, 10)
                    this.Loaded = true
                    // this.addParamsToLocation({ [this.params[e.element.id - 1].ParamValue]: e.value })
                    //this.params[e.element.id - 1].Content = String(e.value).slice(0, 10);
                    break;
            }
            if(this.Loaded)
            this.SetAdditionalField(e.value, fieldData)
            // var nazev = e.element.id
            //   e.value
        },
        GetAdditionalFields(){
            let self = this;
            this.AdditionalFields.length = 0;
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetAdditionalFields(self.pozId, function(result){
                self.$log.debug(result)
               
                for (var x = 0; x < result.List.value.items.length; x++) {
                    self.AdditionalFields[self.AdditionalFields.length] = {           
                        Id: x+1,
                        ColumnName: result.List.value.items[x].ColumnName.value,
                        Component: result.List.value.items[x].Component.value,
                        TableName: result.List.value.items[x].TableName.value,
                        TitleCZ: result.List.value.items[x].TitleCZ.value,
                        TitleEN: result.List.value.items[x].TitleEN.value,
                        ParamValue: result.List.value.items[x].Value.value,
                        Content: result.List.value.items[x].ComponentValue.value,
                    };
                    if (self.$i18n.locale == 'cs') self.AdditionalFields[x].Title = result.List.value.items[x].TitleCZ.value
                     else self.AdditionalFields[x].Title = result.List.value.items[x].TitleEN.value
                    
                    if (self.AdditionalFields[x].Component == 'D') self.AdditionalFields[x].Content = new Date().toISOString().slice(0, 10)
                    if (self.AdditionalFields[x].Component == 'L') {
                        self.LookupValue = parseInt(result.List.value.items[x].ComponentValue.value)
                        self.AdditionalFields[x].DataSource = self.ComponentDatasource(self.AdditionalFields[x].ParamValue, x)
                    }
                }
                
            }, window.this.ApiError());
        },
        SetAdditionalField(value,fieldData) {
            let self = this;
            this.$log.debug(fieldData)
            this.$log.debug(value)
            if(value==null) value=0
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.SetAdditionalField(self.pozId, fieldData.TableName, fieldData.ColumnName, value,function (result) {
                self.$log.debug(result)
                if (result.Error.value.ErrorNum.value != 0) {
                    notify({
                        message: result.Error.value.ErrorTextCZ.value,
                        position: {
                            my: 'center top',
                            at: 'center top',

                        },
                    }, 'error', 3000); //'info' - barva templatu, 'success', 'warning', 'error'
                }
                else {
                    notify({
                        message: self.$t('pozadavky-VyvojoveDilnyZmenyUlozeny'),
                        position: {
                            my: 'center top',
                            at: 'center top',

                        },
                    }, 'success', 3000); //'info' - barva templatu, 'success', 'warning', 'error'

                }
            }, window.this.ApiError());
        },
        GetHash(FileName, FileID) {
            let self = this;
            var gen = new window.General(window.channel, window.message);
            gen.GetFileHash(FileID,

                function (result) {
                    self.$log.debug(result);
                    self.hash = window.URL1+'/js/download/' + result.Value.value;
                    self.$log.debug(self.hash + " " + FileName)
                    self.downloadItem(self.hash, FileName);

                }, window.this.ApiError());
            gen.DeleteHashedFiles(
                function (result) {
                    self.$log.debug(result);
                }, window.this.ApiError());

        },
        FileUpload(file) {
            this.$log.debug(file)
            var self = this;
            var gen = new window.General(window.channel, window.message);
            var reader = new FileReader();
            reader.onload = (function (theFile) {
                return function (e) {
                    gen.FileUpload(1, self.pozId, theFile.name,
                        e.target.result,
                        function (result) {
                            self.$log.debug(result);
                            if (result.ErrorNum.value != 0) {
                                notify({
                                    message: result.ErrorTextCZ.value,
                                    position: {
                                        my: 'center top',
                                        at: 'center top',

                                    },
                                }, 'error', 3000); //'info' - barva templatu, 'success', 'warning', 'error'
                            }
                            self.Prilohy()
                        }, window.this.ApiError());
                }
            })(file);
            reader.readAsBinaryString(file);

        },
        ProcessError(error) {

          if (error.ErrorNum.value==1) {
            notify({
                message: 'Došlo k chybě',
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 5000);
            this.$log.error(error.ErrorTextCZ.value)

                    } else if (error.ErrorNum.value>1) {
                        notify({
                message: error.ErrorTextCZ.value,
                position: {
                    my: 'center top',
                    at: 'center top',
                },
            }, 'error', 5000);

                    

          }
        },
        Edit() {
            window.localStorage.setItem("pozid", this.pozId)
            window.pozid = this.pozId
            // this.$router.push(link)
            // self.$log.debug(this.pozId)
        },
        addParamsToLocation(params) {
            //  this.$log.debug(window.thisPozadavky)
            this.$log.debug(this.$route)
            this.$log.debug(params)
            this.ParamsQuery = { ...this.ParamsQuery, ...params }
            params = this.ParamsQuery
            history.pushState(
                {},
                null,
                this.$route.path +
                '?' +
                Object.keys(params)
                    .map(key => {
                        return (
                            encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                        )
                    })
                    .join('&')
            )
        },
    SetState(stav, alert) {
            this.$log.debug('SETSTATE')
            this.$log.debug(stav)
            let self = this;
            var wh = new window.WorkFlow(window.channel, window.message);

            if (stav == -1) {
                self.$log.debug('konec')
                this.popupAlert = true
                this.popupAlertTemplate = 'SmazatPoz'
                return 1;
            }

            if (alert == true) {                
                stav = -1
            }
                
            // self.open();
        self.$log.debug(self.pozKomentar)
        self.$log.debug(window.NelzeSchvalovat + ' stavschvalovani');
        if (window.NelzeSchvalovat && stav == 0) {
            notify({
                message: self.$t('pozadavky-NelzeUvestPozadavekKeSchvaleni'),
                position: {
                    my: 'center top',
                    at: 'center top',

                },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
        } else { 
            
            if ((stav == 2 || stav == 3) && self.pozKomentar== '') {
                notify({
                        message: self.$t('pozadavky-VyplnteKomentarKeSchvalovani'),
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                }, 'warning', 5000);
                var ObaleniKomentar = document.getElementById('obaleniPozadavkyBaseSCHVALOVANI');
                if (ObaleniKomentar) { ObaleniKomentar.classList.add('komentarToFillBackground'); }// zabarveni komentare
                self.ToFill=true; // vystrazny vykricnik u komentare
                self.sendEmit();
                
                return 2;
            } else {
                self.ToFill=false;
                } 

            wh.SetState(self.pozId, stav, self.pozKomentar,
                function (result) {

                    self.$log.debug(result);

                    if (result.ErrorNum.value != 0) {
                        notify({
                            message: result.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        var stavText;
                        switch (stav) {
                            case -1: stavText = self.$t('pozadavky-StavZrusen')
                                // this.$router.push('/Pozadavky?Pozid=' + (-window.pozId))
                                break;
                            case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                                // window.thisPozadavky.addParamsToLocation({ po: 555 })
                                //this.$router.push('/Pozadavky?Pozid=' + (-window.pozId))
                                break;
                            case 4: stavText = self.$t('pozadavky-StavUlozeny')
                                break;
                            case '1': case 1:
                                stavText = self.$t('pozadavky-PozadavekSchvalen')
                                break;
                            case '3': case 3:
                                stavText = self.$t('pozadavky-PozadavekVracen')
                                break;
                            case '2': case 2:
                                stavText = self.$t('pozadavky-PozadavekZamitnut')
                        }
                        if (stav == 1 || stav == 3 || stav == 2)
                            notify({
                                message: stavText,
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 3000);
                        else
                            notify({
                                message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 3000);
                            if (stav >= -1 && stav <= 4) {
                                if (stav == 4 && self.stateid == 0) self.$log.debug('No redirect')
                                else {
                                window.thisPozadavky.addParamsToLocation({ Pozid: 0, Typ: -1 })
                                self.ClosePozadavkyPopups()
                                if (self.ElPodpis && stav == 1) {
                                   console.log('volam ElectronicSignature')
                                    self.ElectronicSignature()
                                }
                                return 1;
                                }
                        }
                            self.DisplayRequest();
                        
                    }
                }, window.this.ApiError());
                }
            //self.hide();
 
        },
        dateFormaterDDMMYYYYHHMM(input){
            const date = new Date(input);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');

            const formattedDate = `${day}. ${month}. ${year} ${hours}:${minutes}`;
        return formattedDate;
        }


    }
}

</script>
<style scoped>
:deep(.dx-scrollable-content){
  padding-bottom:4px !important;
}
:deep(#savetopdf){
        margin-right: 0;
        margin-left: 0;
}
:root {
  --colorByTheme: rgb(0, 128, 255);
  --colorByThemeQuarterOpacity: rgb(0, 128, 255);
}
.buttonDefault:hover,.buttonUlozitProUpravy:hover,.buttonKeSchvaleni:hover,.buttonSmazat:hover,.buttonKEditaci:hover{
    background-color: var(--colorByThemeLighter);
    border: 0px;
    
}
.buttonKeSchvaleni{
  background: #5cb83c;
  border-color: none;
  border: 0px;
}
.buttonUlozitProUpravy{
    background: #3cb8b8;
    border-color: none; 
    border: 0px;
}
.buttonSmazat{
    background: #d9534f;
  border-color: none;
  border: 0px;
}
.buttonKEditaci{
    background: #ffcc33;
    border-color: none;
    border: 0px; 
}
.buttonDefault{
    background: none;
    border-color: none;
    border: 0px;
}
#komentarDiv{
    padding-top: 10px;
}
#komentarKeSchvalovaniDiv{
    display:inline-flex;
    width:100%;
}
.obaleniPozadavkyBase{
    border-style: solid;
    border-color: rgba(128, 128, 128, 0.1);
    background-color: rgba(128, 128, 128, 0.075);
        border-width: 1px;
        border-radius: 5px;
        margin: 10px;
        padding: 10px;
        height: max-content;
        color: #000000;
        margin-bottom: 10px;
}
.obaleniPozadavkyBase:last-child{
    margin-bottom: 25px !important;
}
#pozcontent{width: max-content;}
#pozcontent{margin-top: 20px; padding: 10px; padding: 10px; margin-left: 100px;}
#hlavicka{
    margin-top: 5px; 
    margin-bottom: 0; 
    font-weight:550;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
}
#DateBoxdiv{display: flex; flex-direction: row !important;}
#datebox{align-self: center;}
#ResponsiveBoxAAA{margin-left: 10px;}
#SpodniMenu{display:flex; 
    flex-direction: column;
    flex-wrap: nowrap;
    border-style: solid;
    border-color: rgba(66, 66, 66, 0.5);
    border-left: 0;
    border-right: 0;
    border-top: 0;
   border-bottom: 0;
   border-width: 1px;
   border-radius: 2px;
    background-color: rgba(80, 80, 80, 0.3);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    width:99%;
    margin-top: 250px;
    margin: 2px; 
    position: sticky;
    position: -webkit-sticky;
    bottom: 0.5rem;
    z-index: 200;
}

#SpodniMenuTop{
    display:flex; 
    flex-direction: column;
    flex-wrap: nowrap;
}
.SpodniMenuBottom{
    display:flex; 
    flex-direction: row;
    flex-wrap: nowrap;
}
#obaleniPozadavkyBaseKOMENTAR{margin-bottom: 5px;}
#obaleniKOmentarSchvalAkce{
    border-style: solid;
    border-color:  rgba(255, 187, 0, 0);
    padding: 5px;
    border-width: 5px;
        border-radius: 8px;
        
        
}
.schvalovanibuttons{
    margin-right: 15px;
    margin-left: 15px;
}

#komentar, .SchvalovaniKomentarButtons{margin: 5px;}
.ResponsiveBoxBaseBottom{margin: 25px;}
.childSpodniMenu{ margin-left:8px;
 padding-top: 10px;margin-bottom: 10px; border:solid 1px transparent}
 .childSpodniMenu:last-child{margin-right: 5px;}
.childSpodniMenuSchvalovani{float: left; margin-left: 5px; padding-top: 10px;margin-bottom: 5px; border:solid 1px transparent; margin-top:10px}
#idChangeOwner{padding-top: 0px !important;}
@media only screen and (max-width: 768px) {
    #schvalproces1, #komentar, #Prilohy{margin-left: 0px;}
    
    }/*xs */
    @media only screen and (min-width: 778px) and (max-width: 992px) {
        #schvalproces1, #komentar, #Prilohy{margin-left: 0px;}
     }
  /*sm */
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
       

      }/*md */
    @media only screen and (min-width: 1200px) {
    }
    @media screen and (max-width:500px) {
        #SpodniMenu{
            width: 97%;
        }        
    }
    .divDoplnenaPole{
        background-color: var(--colorDoplnenaPole);
        padding: 10px;
        padding-top: 0;
    }
    .komentarToFillBackground {
      background-color: rgba(255, 166, 0,0.40) !important;
    }
    #signerlabel{
        display: block;
    /* margin-bottom: 10px; */
    font-size: small;
    padding: 2.5px;
    background-color: rgba(255, 255, 255,0.85);
    }
   
</style>