<template>

<span id="text" style="white-space: pre-wrap;">{{ Text }}</span>
</template>

<script>
export default {
    name: 'ListTexts',
    components: {
    },
    props: {
        Ident: {
            type: String,
            required: true,
        },
    },
    watch: {
        Ident(newTexts) {
            this.$log.debug(newTexts)
            this.LoadTexts();
        },
    },
    data() {
        return {
            Text: ''
        };
    },
    computed: {


    },
    mounted() {
        this.LoadTexts()
    },

    methods: {
        LoadTexts() {
            this.$log.debug('loadtexts')
            var self = this
            self.Text = ''
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('TEXT:' + this.Ident,
                '', 1, 50,
                function (result) {
                    self.$log.debug(result);
                    if(result.List.value.items.length>0) self.Text = result.List.value.items[0].Value.value
                }, window.this.ApiError());
        },
    }
}
</script>
<style scoped>
#text {
   color: var(--colorByTheme); /* --colorByThemeLighter  --colorByThemeLighterHeaders */
   font-weight: bold;
   line-height: 1.5;
}
</style>