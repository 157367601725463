import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import Pozadavky from '@/views/Pozadavky.vue'
import Vydejky from '@/views/Vydejky.vue'
import Granty from '@/views/Granty.vue'
import Objednavka from '@/views/requests/Objednavka.vue'
import TelSeznam from '@/views/TelSeznam.vue'
import Prevodmajetku from '@/views/requests/Prevodmajetku.vue'
import Vyrazenimajetku from '@/views/requests/Vyrazenimajetku.vue'
import Majetek from '@/views/Majetek.vue'
import Sklad from '@/views/Sklad.vue'
import ObjednavkaKnihovna from '@/views/requests/ObjednavkaKnihovna.vue'
import Vyvojovedilny from '@/views/requests/Vyvojovedilny.vue'
import Smlouva from '@/views/requests/Smlouva.vue'
import PracSetkani from '@/views/requests/PracSetkani.vue'
import PracCesta from '@/views/requests/PracCestaSoukrVozidlo.vue'
import PlatKarta from '@/views/requests/PlatKarta.vue'
import TestRangeBox from '@/views/TestRangeBox.vue'
import HostKarta from '@/views/requests/HostKarta.vue'
import Dochazka from '@/views/Dochazka.vue'
import OsobniInformace from '@/views/OsobniInformace.vue'
//import Error from '@/views/errors/Error.vue'
import Dotazovac from '@/views/Dotazovac.vue'
import Organizace from '@/views/Organizace.vue'
import Aktualne from '@/views/Aktualne.vue'

//import App from '@/App.vue'


const routes = [   
    {
        path: '/:pathMatch(.*)*',
        //name: "Aktualne",
        component: Aktualne,
        meta: { requiresAuth: true, id: 9 }
    },
    {  
        path: "/",
        redirect: "Aktualne",
        meta: { requiresAuth: true, id: 9 }
        //   name: "App",
        //  component: App,  
    },
    {
        path: "/Aktualne",
        name: "Aktualne",
        component: Aktualne,
        meta: { requiresAuth: true, id: 9 }
    },
        {  
        path: "/TestRangeBox",
        name: "TestRangeBox",
        component: TestRangeBox,
    },
    {
        path: "/Login",
        name: "Login",
        component: Login,
        meta: { requiresAuth: false, id: 99 }
    },
    {
        path: "/Pozadavky",
        name: "Pozadavky",
        component: Pozadavky,
        props: true,
        meta: { requiresAuth: true, id: 1 },
        children:[
            {path: ':Niceurl',
            component: Pozadavky
         }
        ],
    },
    {
        path: "/Objednavka",
        name: "Objednavka",
        component: Objednavka,
      //  meta: { requiresAuth: true, id: 1 }
    },
    {
        path: "/Vydejky",
        name: "Vydejky",
        component: Vydejky,
        meta: { requiresAuth: true, id: 10 }
    },
    {
        path: "/PracCesta",
        name: "PracCesta",
        component: PracCesta,
        meta: { requiresAuth: true, id: 13 }
    },
    {
        path: "/OsobniInformace",
        name: "OsobniInformace",
        component: OsobniInformace,
        meta: { requiresAuth: true, id: 20 },
        children:[
            {path: ':Niceurl',
            component: OsobniInformace
         }
        ],
    },
    {
        path: "/Granty",
        name: "Granty",
        component: Granty,
        meta: { requiresAuth: true, id: 2 }
    },
    {
        path: "/TelSeznam",
        name: "TelSeznam",
        params: { id: 1 },
        component: TelSeznam,
        props: true,
        meta: { requiresAuth: true, id: 3 }
    },
    {
        path: "/Prevodmajetku",
        name: "Prevodmajetku",
        component: Prevodmajetku,
       // meta: { requiresAuth: true, id: 1 }
    },
        {
        path: "/Smlouva",
        name: "Smlouva",
        component: Smlouva,
       // meta: { requiresAuth: true, id: 1 }
    },
    {
        path: "/Pracovni-obed-vecere",
        name: "Pracovni-obed-vecere",
        component: PracSetkani,
       // meta: { requiresAuth: true, id: 1 }
    },
    {
        path: "/PlatKarta",
        name: "PlatKarta",
        component: PlatKarta,
       // meta: { requiresAuth: true, id: 1 }
    },
    

    {
        path: "/Vyrazenimajetku",
        name: "Vyrazenimajetku",
        component: Vyrazenimajetku,
      //  meta: { requiresAuth: true, id: 1 }
    },
    {
        path: "/Majetek",
        name: "Majetek",
        component: Majetek,
        meta: { requiresAuth: true, id: 4 }
    },
    {
        path: "/Sklad",
        name: "Sklad",
        component: Sklad,
        meta: { requiresAuth: true, id: 7 }
    },
    {
        path: "/ObjednavkaKnihovna",
        name: "ObjednavkaKnihovna",
        component: ObjednavkaKnihovna,
       // meta: { requiresAuth: true, id: 1 }
    },
    {
        path: "/Dotazovac",
        name: "Dotazovac",
        component: Dotazovac,
        meta: { requiresAuth: true, id: 6 },
        alias: '/Reporter', 
        children:[
            {path: ':Niceurl',
            component: Dotazovac
         }
        ],
    },
    { 
        path: "/Error",
        name: "Aktualne",
        component: Aktualne,
       // meta: { requiresAuth: true, id: 1 }
    },
    {
        path: "/Organizace",
        name: "Organizace",
        component: Organizace,
        meta: { requiresAuth: true, id: 8 }
    },
    {
        path: "/Vyvojovedilny",
        name: "Vyvojovedilny",
        component: Vyvojovedilny,
        //meta: { requiresAuth: true, id: 1 }
    },
    {
        path: "/Dochazka",
        name: "Dochazka",
        component: Dochazka,
        meta: { requiresAuth: true, id: 11 }
    },
    {
        path: "/HostKarta",
        name: "HostKarta",
        component: HostKarta,
        meta: { requiresAuth: true, id: 12 }
    },
   
    
    
]

const router = createRouter({
history: createWebHistory(),
routes
});
/*
router.beforeEach((to, from, next) => {
const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
const isAuthenticated = firebase.auth().currentUser;
this.$log.debug("isauthenticated", isAuthenticated);
if (requiresAuth && !isAuthenticated) {
    next("/login");
} else {
    next();
}
});
*/

export default router
