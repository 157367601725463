<template>
<HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000691182?lang=cs" 
  URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000691182?lang=en" 
PopisLinku='clanky.sklady'></HelpLink>
  <DxScrollView height="90%">
    <div id="container1">
      <div id="zahlaviSklad"> 
        <div class="clNadpisAktualniStranky" height="50px">
          <h2 id="nadpisSklad" class="nadpisH2Moduly prevent-select">{{$t('sklad-Sklad')}}</h2>
        </div>
          <div class="container" id="divHorniLista"  width="100%">
            <span class="childdivHorniLista inlineflex">
              <div id="SelectBoxContent" class="childdivHorniLista" @mouseover="getTitle(sklady,sklad)" v-bind:title="getTitle(sklady,sklad)">
                <DxSelectBox class="selectbox"  :id="1" width='300px' height="45px" :data-source="sklady" display-expr="content" value-expr="id" :value="sklad" ref="sbSklad"
                @value-changed="this.page=1;setSelectedValue($event);TableData(); " :label="$t('sklad.nadpisSelectBox')" label-mode="outside"/>
              </div>
              <div class="inlineflex filterBar childdivHorniLista">
                <span id="filtr">{{$t('central.Skoleni.Filtr')+String.fromCharCode(160)}}</span>
                <div height="15px" class="clCheckBoxForma clDivCheckbox" width="max-content">
                <DxCheckBox class="checkPouzePlatne" v-model:value="checked" :text="$t('sklad-PouzeNenulove')" @value-changed="this.page=1;TableData(); " height="45px" width="auto" ></DxCheckBox>
                </div>
              </div>
              <div class="childdivHorniLista">
              <ListTexts style="margin-left: 10px" Ident="SklOtv"> </ListTexts>
          </div>  
        </span>
          <div id="divPrintVariants" class="childdivHorniLista">
              <PrintVariants Report="Sklad" :PrintID="sklad"  class="childdivHorniLista" customHeight="45px" />
          </div>            
        </div>     
      </div> 
    </div>

    <div class="table">
      <hr class="hrModuly">
        <div class="bordersDataGridPozadavkyModuly">
      <DxDataGrid   column-resizing-mode="widget" :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"  :hoverStateEnabled="true" :rowAlternationEnabled="true" :height="(vyskaZobrazitelna/1.75)"
        ref="mygrid" id="DataGrid" :data-source="GridRows" :show-borders="true" @content-ready="onContentReady"
        key-expr="id" :noDataText="$t('central-NenalezenyZadneZaznamy')" @option-changed="SearchInput"  @cellPrepared="onCellPrepared"
        :editing-texts="{ saveRowChanges: $t('central-Ulozit'), cancelRowChanges: $t('central-Zrusit'),}" @exporting="onExporting">
        <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
        <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :allow-export-selected-data="false"
          :texts="{exportAll: $t('central-ExportVseExcel')}"
          />
          <DxToolbar >
                                        
                                        <DxItem location="before" locate-in-menu="auto"
                                        name="groupPanel"
                                        />
                                        
                                        <DxItem location="after" locate-in-menu="auto" widget="dxButton" >
                                            <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                            type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset" />      
                                        </DxItem>
                                        <DxItem location="after" locate-in-menu="auto"
                                        name="exportButton"
                                        />
                                        <DxItem location="after" locate-in-menu="auto"
                                        name="searchPanel"
                                        :visible="true"
                                        />
            </DxToolbar>    
        <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="GridSklad"
        />
        <DxFilterRow :visible="true"/>
                                <DxFilterPanel :visible="true" :texts="{
                                  clearFilter: $t('central-ZrusitFiltr'), createFilter: $t('central-VytvoritFiltr'), filterEnabledHint: $t('central-FiltrVysvetlivka'), saveChanges: 'Povrdit',
                                }"/>
                                <DxFilterBuilderPopup value="text" />
                                <DxHeaderFilter :visible="true"/>
        <DxColumn width="150px" data-field="id" caption="ID" :allow-editing="false" :visible="false" :formItem="{ visible: false }" />
        <DxColumn width="350px" data-field="name" :caption="$t('sklad-Nazev')">
        </DxColumn>
        <DxColumn width="75px" format=",##0.00" data-field="pocet" :caption="$t('sklad-Pocet')">        
        </DxColumn>
        <DxColumn data-field="jednotka" width="90px" :caption="$t('sklad-Jednotka')" :visible="true" :formItem="{ visible: true }">
        </DxColumn>
        <DxColumn format=",##0.00" width="130px" data-field="mincena" :caption="$t('sklad-CenaOd')">
        </DxColumn>
        <DxColumn format=",##0.00" width="130px" data-field="maxcena" :caption="$t('sklad-CenaDo')">
        </DxColumn>                    
        <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form" editRow="Upravit"
          :texts="{
            saveRowChanges: $t('central-Potvrdit'), cancelRowChanges: $t('central-Zrusit'), editRow: '', deleteRow: $t('central-Smazat'),
            confirmDeleteTitle: $t('central-Varovani'), confirmDeleteMessage: $t('central-OpravduSiPrejeteSmazatZaznam'),
          }" />
        <DxPaging :enabled="false" />
        <DxGroupPanel :visible="true" :empty-panel-text="$t('sklad-PretahneteSemZahlavi')" />
        <DxGrouping :auto-expand-all="true" />
        <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
        <DxSearchPanel :width="150" :visible="true" :placeholder="$t('sklad-Hledat')" />
      </DxDataGrid>
      <br>
      <DxButton icon="chevrondown" type="button" height="auto" :text="$t('central-NacistDalsiZaznamy')" class="button4 buttonsDataGridSpodniLista" v-on:click="Nacist()"
        :disabled="disable" />
      <DxButton icon="arrowdown" type="button" height="auto" :text="$t('central-NacistVse')" class="button4 buttonsDataGridSpodniLista" v-on:click="NacistVse()"
        :disabled="disable" />
      </div>
      <br>
      <br>
    </div>

</DxScrollView>
</template>
<script>
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import {DxExport, DxStateStoring,  DxLoadPanel,DxToolbar,DxHeaderFilter,
        DxFilterRow,
        DxFilterPanel,
        DxFilterBuilderPopup,DxSorting} from 'devextreme-vue/data-grid';
import PrintVariants from '@/components/PrintVariants.vue';
import ListTexts from '@/components/ListTexts.vue';
import HelpLink from '@/components/HelpLink.vue';
export default {
    name: 'SkladView',   
  data() {     
    
    return {
      search: '',
      disable: false,
      page: 1,
      checked: true,
      sklady: [],
      sklad: 0,
      majetekNazev:'',
      zam:'',
      invC:'', 
      vyrC:'',
      misto: '', 
      porizeno: '',
      zarazeno: '',
      porCena:'', 
      msg: this.$t('sklad-Sklad'), 
      GridRows:[],
      popup: false,
      vyskaZobrazitelna:250,
      needFocusToSearchPanel: false
      };
  },
  computed: {
    dataGrid: function () {
      let self = this;
      return self.$refs.mygrid.instance;
    },
  },unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
        },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.DrawerSelected()
   // this.Granty();
    this.Sklady();
    //this.TableData();
   // window.localStorage.setItem("refresh-page", 'Sklad');
    setTimeout(() => {
                    this.$nextTick(() => {
                        this.myEventHandler();
            });}, 1000);
  },
  components: {
    ListTexts,
    DxExport,
    DxStateStoring,
    DxLoadPanel,
    DxToolbar,
    DxHeaderFilter,
    DxFilterRow,
    DxFilterPanel,
    DxFilterBuilderPopup,
    PrintVariants,
    HelpLink,
    DxSorting
  },
  methods: {
    onContentReady(e) {
        this.$log.debug(' volam cont ready')
    if (this.needFocusToSearchPanel) {
      // Find the search panel input
      const searchPanelInput = e.component.element().querySelector('.dx-datagrid-search-panel input');
      if (searchPanelInput) {
        // Set focus to the input
        searchPanelInput.focus();
        // Move cursor to the end of the text
        const length = searchPanelInput.value.length;
        searchPanelInput.setSelectionRange(length, length);
      }
      // Reset the flag
      this.needFocusToSearchPanel = false;
    }
  },
  getTitle(datasource,item) {
        this.$log.debug('getTitle(item)');
        this.$log.debug('item',item,'datasource',datasource);
        var ItemContent = item;
        var LookupArray = datasource;
        this.$log.debug('item.DataSource._items',LookupArray,'ItemContent',ItemContent);
        if ((Array.isArray(LookupArray))  && (ItemContent>0))  {
          this.$log.debug('ITEM NALEZEN',LookupArray.find(data => data.id == ItemContent))
          var foundItem = LookupArray.find(data => data.id == ItemContent)
          return foundItem ? foundItem.content : undefined;
          } else{
            this.$log.debug('item.DataSource._items.length',LookupArray.length)
            return ''
          }
        }, 
    myEventHandler(e){
            this.$log.debug(e);
            this.handleMainViewChange()
          },
    handleMainViewChange(){
         //   console.log("handleMainViewChange");
                let self = this;
                var element = document.getElementById("ScrollViewAPP");
                    if (element) {
           //             console.log(element);
                        var height = element.clientHeight;
                        if ((height<500) && (height>300))  {height=550} 
                        if (height<=300) {height=450} 
                        self.vyskaZobrazitelna = height;
                        
        
         //   console.log("handleMainViewChange height:", height);
}
            
        },
    onExporting(e) {
      window.this.$log.debug('test uvnitr onexporting')
    if (e.format==="xlsx") {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Companies");
        exportDataGridToExcel({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
          customizeCell: function(options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                } 
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            const datum= new Date();
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              this.msg+" "+datum.toISOString('cs-CZ').substring(5, 10)+" "+datum.toTimeString('cs-CZ').substring(0, 9)+".xlsx"
            );
          });
        });
        e.cancel = true;
      }
    
    
    
    },
    GridLayoutReset(e) {
      this.$log.debug(e)
      this.$log.debug(this.dataGrid)
      this.dataGrid.state(null);
    },
    DrawerSelected() {
      var e = window.Drawer
      if (window.prevElement != undefined && window.prevPosition != undefined)
        window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
      this.$log.debug(window.Drawer)
      this.$log.debug(this.$route.meta.id)
      this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length)
      for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
        // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
        if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {
          e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = '#FF9900'
          window.prevElement = e
          window.prevPosition = x
        }
      }
    },
    onCellPrepared(e) {
      if (e.rowType === "header" && (e.column.dataField == 'mincena' || e.column.dataField == 'maxcena')) {
       // window.this.$log.debug(e)
        e.cellElement.addEventListener('mouseover', function () {
       //   window.this.$log.debug(arg)
          const headerTitle = e.cellElement.querySelector('.dx-datagrid-text-content');
        //  const headerTitle = e.cellElement.querySelector('dx-datagrid-action');
          if (e.column.dataField == 'mincena')
            headerTitle.setAttribute("title", "Minimální cena za kterou se dá zboží objednat, záleží na aktuální nabídce dodavatele.");
          if (e.column.dataField == 'maxcena')
            headerTitle.setAttribute("title", "Nejvyšší cena za kterou se dá zboží objednat.");
        /*  if (headerTitle.scrollWidth > headerTitle.clientWidth) {
            headerTitle.setAttribute("title", "Minimální cena");
          }  */
        }); 
      }

    },   
    Nacist() {
      this.page++;
      this.TableData();
    },
    NacistVse() {
      this.page=0;
      this.TableData();
      this.disable=true;
    },
    SearchDelay(search) {
      let self = this;
      clearTimeout(this.timer)
      // window.this.$log.debug(search)
      this.timer = setTimeout(() => {
        self.page = 1
        this.search=search
        self.TableData();
      }, 1000)

    },
    SearchInput: function (e) {
     // window.this.$log.debug(e)

      if (e.name === "searchPanel") {
        this.searched = false
        window.this.$log.debug(e.value)
        if (e.fullName != 'searchPanel.placeholder')
          this.SearchDelay(e.value)
      }
      else {

        // this.Detail(params.row.id_vydejky);

      }
    },
    setSelectedValue(e) {
      window.this.$log.debug(e);
      switch (e.element.id) {
        case '1':
          this.sklad = e.value
          break;
    /*
        case '2':
          this.zamestnanec = e.value
          break;
        case '3':
          this.typpozadavek = e.value
          break;
        case '4':
          this.stavpozadavek = e.value
          break;
        
    case '5':
        this.stavpozadavek = e.value
        break;
    case '6':
        this.stavpozadavek = e.value
        break;
    case '7':
        this.stavpozadavek = e.value
        break;
        */
      }

      // this.SkipDiacritics(this.typpozadavek2.content)
      //  this.pozadavek=e.value
      // this.selectedValue = e.value;
    },
    Sklady() {
      let self = this;
      self.sklady.length = 0
      var wh = new window.General(window.channel, window.message);
      wh.ListLookup("Sklady",":INTRANET",0,0,
        function (result) {
          window.this.$log.debug(result);
            
          for (var x = 0; x < result.List.value.items.length; x++) {
            //  window.this.$log.debug(result.List.value.items[x]);
            //var i = self.meny.length;
            self.sklady[x] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
            
          }
          self.sklad=self.sklady[0].id;
        }, window.this.ApiError());
    },
    ShowDetail(e) {
      window.this.$log.debug(e)
        var asset = new window.Asset(window.channel, window.message);
        var self = this;
          asset.GetAssetDetail(e.data.internalid,
            function (result) {
              self.majetekNazev = result.Name.value
              self.zam = result.OwnerName.value,
                self.invC = result.Id.value
              self.vyrC = result.SerialNumber.value,
                self.porizeno = result.InEvidenceFrom.value
                self.porCena = result.Value.value.toFixed(2)
             // self.zarazeno= 
          window.this.$log.debug(result)
      }, window.this.ApiError());
        this.popup=true;
    },
    RefreshDataGrid() {
    this.dataGrid.refresh()
        .then(function () {
        // ...
        })
        .catch(function (error) {
        window.this.$log.debug(error)
        // ...
        });
    },
    open() {
        window.loader = this.$loading.show({ loader: 'dots' });
    },
    hide() {
        window.loader.hide()
    },
    TableData() {
    var wh = new window.Warehouse(window.channel, window.message);
      let self = this;
      self.disable = false;
   // var EmpID= JSON.parse(window.localStorage.getItem("user")).EmployeeID.value 
      
      if (this.page <= 1) {
        window.this.$log.debug('nulovani')
        self.GridRows.length = 0;
      }
    var whID=this.sklad
      window.this.$log.debug(this.search)
      window.this.$log.debug(whID)
      window.this.$log.debug(this.checked)
      var prefix;
      if (this.checked) prefix=''; else prefix=':NULLAMOUNT'
    wh.ListItem(whID,prefix+this.search, this.page,15,
        function (result) {
        window.this.$log.debug(result);
          if (result.List.value.items.length == 0 || result.List.value.items.length < 15) {
            self.disable = true;
          }
          for (var x = 0; x < result.List.value.items.length; x++) {    
            var i = self.GridRows.length;   
          self.GridRows[i] = {             
                id: result.List.value.items[x].Id.value,
                name: result.List.value.items[x].Name.value,
                mincena: result.List.value.items[x].PriceFrom.value,
                maxcena: result.List.value.items[x].PriceTo.value, 
                jednotka: result.List.value.items[x].MeasureUnit.value,
                pocet: result.List.value.items[x].Amount.value,                                                    
            };
            
        
        }
       self.RefreshDataGrid()
       self.needFocusToSearchPanel = true; 
        //self.hide();
        }, window.this.ApiError());

    },
  },

}
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .inlineflex{
    display: block !important;
    flex-direction: row !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: baseline;
    align-content: normal;
 }
  .container {
    flex-wrap: wrap; /* Povolit zalamování */
  }

  .childdivHorniLista {
    width: 100%; /* Roztažení prvku na celou šířku */
    margin-bottom: 10px;
  }

  .selectbox {
    width: calc(100% - 20px); /* Dynamická šířka se zarovnáním */
  }
}
#checkboxNenulove{
  padding-top: 10px;
}
  #DataGrid{
    margin-right:100px;
  }
  #obaleniDataGridu{
    border-style: ridge;
    border-color: rgba(128, 128, 128, 0.226);
    border-radius: 10px;
    padding: 10px;
  }
  .SelectBoxACheckBox{}/**align-items: center; width: max-content;display: flex;
  margin: 1px;
  padding: 5px;
  background: #aaa; */
  #nadpisSklad{
width:100%;
order:0;
  }
  #zahlaviSklad{ 
    width: 100%;
    
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 0px;
}
  #checkboxNenulove{align-self: center;padding: 0px 5px; order: 2;}
  #SelectBoxContent{order:2}
  #button4,#buttonVse{padding:0px;margin: 5px;}


 
  .clDivCheckbox{
  width:fit-content;
  padding-right: 1%;
}
.childdivHorniLista:nth-child(1){
    order: 1;
  }
  .childdivHorniLista:nth-child(2){
    order: 2;
    /*align-self: flex-end;*/
    flex-grow: 0;
  }
  .childdivHorniLista:nth-child(3){
    order: 3;
  }
#divHorniLista{
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    align-items: baseline;
  }
.inlineflex{
    display: inline-flex;
    flex-direction: row !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: baseline;
    align-content: normal;
 }
 .checkPouzePlatne{
  text-overflow: ellipsis;
   white-space: normal;
}
.inlineflex.filterBar{
  padding-left: 10px;
}
:deep(.dx-checkbox-text){
  white-space: nowrap;
  padding-right: 3px;
  padding-bottom: 5px;
}
:deep(.dx-checkbox-icon){
  top: -4%;
}
.clCheckBoxForma{
  margin-top: 0px !important;
}
.clCheckBoxForma{
    background-color: #80808045;
    border: 1px solid #808080;
    padding: 0 0 0 1%;
    border-radius: 5px;
    Top: 5%;
 } 
</style>