<template>
    <!DOCTYPE html>
    <div v-show="dataReady">
        <div :pozadi="pozadi" class="app">
            <hr v-if="Mode == 'Developer'" style="border:rgb(255, 0, 0) solid;" id="hr">
            <hr v-if="otherUser" style="border:rgb(253, 200, 23) solid;" id="hr">
            <DxToolbar v-if="authenticated && !pozadi" class="HlavniToolbar" id="HlavnitoolbarID">
                <DxItem :options="toolbarContent" location="before" widget="dxButton" id="btnOtevreniBocnihoPanelu"
                    @click="this.LogoClick()" class="prevent-select">
                    <label v-bind:title="titOtevritZavritMenu">
                        <svg id="iconMenu" class="svg-icon" viewBox="-2 -2 24 24">
                            <path id="svgPath"
                                d="M16.803,18.615h-4.535c-1,0-1.814-0.812-1.814-1.812v-4.535c0-1.002,0.814-1.814,1.814-1.814h4.535c1.001,0,1.813,0.812,1.813,1.814v4.535C18.616,17.803,17.804,18.615,16.803,18.615zM17.71,12.268c0-0.502-0.405-0.906-0.907-0.906h-4.535c-0.501,0-0.906,0.404-0.906,0.906v4.535c0,0.502,0.405,0.906,0.906,0.906h4.535c0.502,0,0.907-0.404,0.907-0.906V12.268z M16.803,9.546h-4.535c-1,0-1.814-0.812-1.814-1.814V3.198c0-1.002,0.814-1.814,1.814-1.814h4.535c1.001,0,1.813,0.812,1.813,1.814v4.534C18.616,8.734,17.804,9.546,16.803,9.546zM17.71,3.198c0-0.501-0.405-0.907-0.907-0.907h-4.535c-0.501,0-0.906,0.406-0.906,0.907v4.534c0,0.501,0.405,0.908,0.906,0.908h4.535c0.502,0,0.907-0.406,0.907-0.908V3.198z M7.733,18.615H3.198c-1.002,0-1.814-0.812-1.814-1.812v-4.535c0-1.002,0.812-1.814,1.814-1.814h4.535c1.002,0,1.814,0.812,1.814,1.814v4.535C9.547,17.803,8.735,18.615,7.733,18.615zM8.64,12.268c0-0.502-0.406-0.906-0.907-0.906H3.198c-0.501,0-0.907,0.404-0.907,0.906v4.535c0,0.502,0.406,0.906,0.907,0.906h4.535c0.501,0,0.907-0.404,0.907-0.906V12.268z M7.733,9.546H3.198c-1.002,0-1.814-0.812-1.814-1.814V3.198c0-1.002,0.812-1.814,1.814-1.814h4.535c1.002,0,1.814,0.812,1.814,1.814v4.534C9.547,8.734,8.735,9.546,7.733,9.546z M8.64,3.198c0-0.501-0.406-0.907-0.907-0.907H3.198c-0.501,0-0.907,0.406-0.907,0.907v4.534c0,0.501,0.406,0.908,0.907,0.908h4.535c0.501,0,0.907-0.406,0.907-0.908V3.198z">
                            </path>
                        </svg>
                    </label>
                </DxItem>
                <!--<svg   xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16"> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/> </svg>
                -->
                <DxItem location="before" locate-in-menu="never">
                    <label class="Delitko prevent-select" id="DelitkoUMenu">|</label>
                </DxItem>

                <DxItem id="logo" :options="toolbarContent" location="before" widget="dxButton">
                    <div id="logoAlbinaDIV" :ohraniceniStavu="ohraniceniStavu" width="auto">
                        <div id="logoAlbinaDIVLEFT">
                            <img class="prevent-select" id="logoIMG" @click="Home()"
                                src="@/assets/images/logo_UOCHB.png" alt="">

                        </div>
                        <div class="logoAlbinaDIVRIGHT" :ikonaStavu="ikonaStavu" width="auto">
                            <label id="AlbinaText" @click="Home()" class="prevent-select" @mouseleave="hideTooltip"
                                @mouseover="showTooltip" @mouseout="hideTooltip">Albína</label>
                            <!--<img class="prevent-select" id="imgStav2" src="@/assets/images/black_error.png" alt="">    -->
                        </div>
                        <DxTooltip v-if="ukazUpozorneniStavAlbina" id="ToolTipStavAlbina" :hide-on-outside-click="true"
                            target="#logoAlbinaDIV" show-event="mouseenter" hide-event="mouseleave" :position="'top'">
                            <div v-if="ukazUpozorneniStavAlbina" v-html="obsahStavSdeleni"
                                class="ToolTipStavAlbinaText">
                            </div>
                        </DxTooltip>
                    </div>
                </DxItem>

                <DxItem location="before" locate-in-menu="never" id="dxitemOdkazyLabel">
                    <label id="labelOdkazy" class="prevent-select">{{ $t('odkazy') }}</label>
                </DxItem>

                <DxItem location="before" locate-in-menu="never" id="dxitemOkBaseOdkaz">
                    <DxButton text="OKBase" styling-mode="outlined" type="default" id="OKBASEMENU"
                        @click="LinkOut('OKBASEMENU')">
                    </DxButton>

                </DxItem>
                <DxItem location="before" locate-in-menu="never" id="dxitemIntraWebOdkaz">
                    <DxButton text="Intraweb" styling-mode="outlined" type="default" id="INTRAWEBMENU"
                        @click="LinkOut('INTRAWEBMENU')">
                    </DxButton>
                </DxItem>

                <DxItem location="before" locate-in-menu="never">
                    <b><label v-if="Mode == 'Developer'" icon='user' id="developer"
                            class="prevent-select">DEVELOPER</label></b>
                </DxItem>
                <DxItem location="before" locate-in-menu="never">
                </DxItem>
                <DxItem location="before" locate-in-menu="never"> <!--v-if="Mode != 'Developer'"-->
                    <NewVersionWarning v-if="NewAlbinaVersionAvailable" TextPadding="none" Border="solid"
                        TextColor="black" Cursor="pointer" BackgroundColor="rgb(255 136 0 / 20%)" Position="inherit"
                        WidthMessage="195px" ColorRGBWarningBox="rgb(0, 0, 0)" PopisChybyKEY="central.NovaVerzeAlbiny"
                        HeightIcon="40px" WidthIcon="40px" @click="NewVersionReload()" />
                    <!-- v-if="NewAlbinaVersionAvailable"-->
                    <!--  <b><label v-if="NewAlbinaVersionAvailable" icon='user' id="developer"
                            class="prevent-select" @click="NewVersionReload()">Nová verze Albíny k dispozici</label></b>-->
                </DxItem>
                <!--<DxItem location="after" locate-in-menu="never" id="dxitemLoggedUSER" >
                    <label id="lblLoggedUser" class="prevent-select">{{$t('logged_user')}} <b>{{ uzivatel }}</b></label>
                </DxItem>-->

                <DxItem location="after" locate-in-menu="never">
                    <div v-if="Xmas">
                        <button v-if="UserXmas" id="banXmasButton" :title="$t('Vanoce.VypnoutPadaniHint')" @click="handleBanXmasClick()" style="background-color: transparent; border: none; cursor: pointer; padding: 0;">
                            <img src="./assets/images/BanSnowflake.png" alt="Ban Snowflakes" style="width: 50px; height: auto; margin-right: 5px; margin-top: 2px;" />
                        </button>
                        <button v-else id="banXmasButton" :title="$t('Vanoce.ZobrazitPadaniHint')" @click="handleBanXmasClick()" style="background-color: transparent; border: none; cursor: pointer; padding: 0;">
                            <img src="./assets/images/3dsnowflake.png" alt="Approve Snowflakes" style="width: 50px; height: auto; margin-right: 5px; margin-top: 2px;" />
                        </button>
                    </div>
                </DxItem>
                <DxItem location="after" locate-in-menu="never" class="localisationDXITEM" id="LokalizaceJazykyDXITEM">
                    <div id="LokalizaceJazyky">
                        <div :notActive="this.jazyk != 'en'" class="LabelEN" @click="changelocale('en')"
                            :title="$t('central.prepnoutDoAJ')"></div>
                        <div :notActive="this.jazyk != 'cs'" class="LabelCZ" @click="changelocale('cs')"
                            :title="$t('central.prepnoutDoCZ')"></div>
                    </div>
                </DxItem>

                <DxItem location="after" locate-in-menu="never">
                    <label class="Delitko prevent-select">|</label>
                </DxItem>

                <DxItem id="menubutton" :options="toolbarContent" location="after" widget="dxButton">
                    <!-- <img v-if="!stavNastaveni" id="iconMenu" @click="zobrazNastaveni()" src="@/assets/images/down-arrow.png" alt="" class="prevent-select">-->
                    <!--<img v-if="stavNastaveni" id="iconMenu" @click="zobrazNastaveni()" src="@/assets/images/up-arrow.png" alt="" class="prevent-select">-->
                    <label id="iconMenuUSER" @click="zobrazNastaveni()" alt="" class="prevent-select"
                        :title="$t('menu_nastaveni')">{{ uzivatelInicialy }}</label>
                </DxItem>


            </DxToolbar>

            <hr v-if="authenticated" id="hr" class="hrModuly">
            <DxDrawer v-if="!pozadi" :opened-state-mode="selectedOpenMode" position="left" reveal-mode="slide"
                v-model:opened="openState" height="calc(100% - 68px)" :close-on-outside-click="false"
                template="listMenu" class="PostranniMenu" :animationDuration="500" :animationEnabled="true">
                <!--pro ostrou verzi height 68px= 66px horni lista + 2 px horizontal line-->

                <template #listMenu>
                    <Snowflakes v-if="Xmas && this.UserXmas" />
                    <NavigationList />

                </template>

                <DxScrollView ref="mainScrollApp" :openState="openState" height="inherit" direction="vertical" showScrollbar="always"
                    id="ScrollViewAPP" class="dx-theme-background-color">
                    <div :opened="openState" class="content" id="hlavniScrollViewAPP"> <!-- -->
                        <router-view :key="$route.fullPath" @authenticated="setAuthenticated" />
                    </div>
                </DxScrollView>
            </DxDrawer>

            <div v-else>
                <div v-if="error">
                    <ErrorView @authenticated="setAuthenticated"></ErrorView>
                </div>
                <div v-else>
                    <LoginApp @authenticated="setAuthenticated"></LoginApp>
                </div>
            </div>

            <DxPopup id="PopupSettingsToolbar" v-model:visible="stavNastaveni" :drag-enabled="false"
                @Hiding="hiding = true; hidden = false" @Hidden="hiding = false; hidden = true"
                :hide-on-outside-click="true" :show-close-button="false" :showTitle="false" :width="350" :height="auto"
                :focusStateEnabled="true" :shading="false" :animation="animationSettingsPopup">
                <DxPosition at="right bottom" my="right top" collision="fit" of="#iconMenuUSER"
                    :offset="{ y: 10, x: 9 }" />

                <div id="idMenuUSER">
                    <div class="clMenuRadekUSER" id="idMenuUzivatel">
                        <img class="clIkonyMenu" id="idikonamenuuser" src="@/assets/images/orange_user.png" />
                        <label id="uzivatel">{{ uzivatel }}</label>
                        <!-- <img class="clIkonyMenu" id="idikonamenuclose" src="@/assets/images/black_close.png"/>-->
                    </div>
                    <div v-if="false" class="clMenuRadekUSER">
                        <DxButton styling-mode="outlined" class="idMenuButtonNovy">
                            <label id="lblMenuNastaveni">{{ $t('menu_nastaveni') }}</label>
                        </DxButton>
                    </div>

                    <div v-if="true" class="clMenuRadekUSER">
                        <DxButton styling-mode="outlined" class="idMenuButtonNovy" @click="openOsobniInformace()">
                            <label id="lblMenuInfo">{{ $t('osobni_informace') }}</label>
                        </DxButton>
                    </div>
                    <div class="clMenuRadekUSER" id="zmenavzhled">
                        <DxButton styling-mode="outlined" class="idMenuButtonNovy" @click="openPopup('ZMENIT_VZHLED')">
                            <label id="lblMenuVzhledy">{{ $t('zmenit_vzhled') }}</label>
                        </DxButton>
                    </div>
                    <div class="clMenuRadekUSER">
                        <DxButton styling-mode="outlined" class="idMenuButtonNovy" @click="LinkOut('ZMENA_HESLA')">
                            <label id="lblzmenit">{{ $t('zmenit_heslo') }}</label>
                            <img class="clIkonyMenu" id="idikonamenulink" src="@/assets/images/orange_link.png" />
                        </DxButton>

                    </div>
                    <div v-if="pravoAdministrator" class="clMenuRadekUSER">
                        <DxButton styling-mode="outlined" class="idMenuButtonNovy" @click="openPopupStavAlbina()">
                            <!--<img src="./assets/images/logout_1.png" id="obrazekLogout" @click="logout();">-->
                            <label>{{ $t('admin_ZmenaStavAlbiny') }}</label>
                        </DxButton>
                    </div>
                    <div v-if="pravoImpersonate || pravoAdministrator" class="clMenuRadekUSER">
                        <DxButton styling-mode="outlined" class="idMenuButtonNovy"
                            @click="popupZmenaUzivatele = !popupZmenaUzivatele">
                            <!--<img src="./assets/images/logout_1.png" id="obrazekLogout" @click="logout();">-->
                            <label>{{ $t('admin_ZmenaUzivatele') }}</label>
                        </DxButton>
                    </div>
                    <div class="clMenuRadekUSER" id="idMenuOdhlasit">
                        <DxButton text="aaa" styling-mode="outlined" class="idMenuButtonNovy" @click="logout();">
                            <label id="lblMenuLink">{{ $t('odhlasit_se') }}</label>
                        </DxButton>
                    </div>
                    <span id="spanMenuSessionID">
                        <label id="lblMenuSessionID">{{ this.loggedAPPVersion }}</label>
                        <label id="lblMenuSessionID">{{ this.loggedAPIFromStorage }}</label>
                    </span>

                </div>

                <!-- <div v-if="true> Should be for ADMINS ONLY 
                            <DxButton  styling-mode="outlined" type="default" id="btnStavAlbina"
                                        @click="openPopupStavAlbina()">
                                        <img src="./assets/images/logout_1.png" id="obrazekLogout" @click="logout();">
                                        <p>STAV ALBINA</p>
                                    </DxButton>
                            </div>
                                <div style="padding-left:5px; ">
                                   
                                    <DxButton  styling-mode="outlined" type="default" id="odhlasit"
                                        @click="logout();">
                                        <img src="./assets/images/logout_1.png" id="obrazekLogout" @click="logout();">
                                        <p id="obrazekLogoutTEXT">{{$t('odhlasit_se')}}</p>
                                    </DxButton>
                                </div>-->

            </DxPopup>

            <DxPopup v-model:visible="popupZmenyVzhled" :width="auto" :height="auto" :show-title="true"
                :drag-enabled="false" :hide-on-outside-click="true" :show-close-button="true"
                :title="$t('zmenit_vzhled')" :focusStateEnabled="true" :shading="true" titleTemplate="title"
                @onHiding="popupZmenyVzhled = false">
                <DxPosition at="left center" my="right center" collision="fit" of="#zmenavzhled"
                    :offset="{ y: -22, x: -5 }" />
                <div>
                    <!-- <label  color="grey" id="lblVzhled" class="prevent-select"> {{$t('vzhled')}} </label>-->
                    <DxSelectBox class="prevent-select" hint="Změna vzhledu Intranetu" width="200px" :height="40"
                        :data-source="themesList" :grouped="true" :key="themesList.Group" display-Expr="title"
                        placeholder="" @value-changed="changeTheme($event);" value-expr="name" :value="currentTheme"
                        id="btnzmenaVzhledu" />
                </div>
            </DxPopup>
            <DxPopup v-model:visible="popupStavAlbina" width="75vw" height="75vh" :show-title="true"
                :drag-enabled="false" :hide-on-outside-click="false" :show-close-button="false"
                :title="$t('central_ZmenaStavuAlbiny')" :focusStateEnabled="true" :shading="true" titleTemplate="title"
                @onHiding="popupStavAlbina = false">

                <DxScrollView direction="both" showScrollbar="always" height="100%" width="100%">
                    <DxSelectBox class="clZmenaStavAlbinaItem" id="selectBoxStavID" :data-source="stavAlbinyTypes"
                        :key="stavAlbinyTypes.text" :value="stavAlbinyTypes.value" value-expr="value"
                        @value-changed="onSelectChange" display-Expr="text" placeholder="" ref="selectBoxStavRef" />
                    <!--<DxTextBox class="clZmenaStavAlbinaItem" id="btnZmenaStavAlbinaTextBoxCZ" v-model="textStavAlbinyCZ" :placeholder="$t('central_stavalbinyCZ')"></DxTextBox>-->
                    <label>{{ $t('stavAlbinyCZ') }}</label>
                    <div class="DxHtmlEditorStav">
                        <DxHtmlEditor v-model="textStavAlbinyCZ" height="150px" width="100%">
                            <DxMediaResizing :enabled="true" />
                            <DxHtmlToolbar :multiline="false">
                                <DxHtmlItem name="undo" />
                                <DxHtmlItem name="redo" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem :accepted-values="sizeValues" name="size" />
                                <DxHtmlItem :accepted-values="fontValues" name="font" />
                                <DxHtmlItem name="header" :accepted-values="acceptedValuesHeader" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="bold" />
                                <DxHtmlItem name="italic" />
                                <DxHtmlItem name="strike" />
                                <DxHtmlItem name="underline" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="alignLeft" />
                                <DxHtmlItem name="alignCenter" />
                                <DxHtmlItem name="alignRight" />
                                <DxHtmlItem name="alignJustify" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="orderedList" />
                                <DxHtmlItem name="bulletList" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="color" />
                                <DxHtmlItem name="background" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="link" />
                                <DxHtmlItem name="image" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="clear" />
                                <DxHtmlItem name="codeBlock" />
                                <DxHtmlItem name="blockquote" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="insertTable" />
                                <DxHtmlItem name="deleteTable" />
                                <DxHtmlItem name="insertRowAbove" />
                                <DxHtmlItem name="insertRowBelow" />
                                <DxHtmlItem name="deleteRow" />
                                <DxHtmlItem name="insertColumnLeft" />
                                <DxHtmlItem name="insertColumnRight" />
                                <DxHtmlItem name="deleteColumn" />
                            </DxHtmlToolbar>
                        </DxHtmlEditor>
                    </div>
                    <label>{{ $t('stavAlbinyEN') }}</label><button id='aOdkazPrekladac' style="margin-left: 25px;"
                        target="_blank" @click="LinkOut('PREKLAD')"> Přeložit do Anglického jazyka</button>
                    <div class="DxHtmlEditorStav">
                        <DxHtmlEditor v-model="textStavAlbinyEN" height="150px" width="100%">
                            <DxMediaResizing :enabled="true" />
                            <DxHtmlToolbar :multiline="false">
                                <DxHtmlItem name="undo" />
                                <DxHtmlItem name="redo" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem :accepted-values="sizeValues" name="size" />
                                <DxHtmlItem :accepted-values="fontValues" name="font" />
                                <DxHtmlItem name="header" :accepted-values="acceptedValuesHeader" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="bold" />
                                <DxHtmlItem name="italic" />
                                <DxHtmlItem name="strike" />
                                <DxHtmlItem name="underline" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="alignLeft" />
                                <DxHtmlItem name="alignCenter" />
                                <DxHtmlItem name="alignRight" />
                                <DxHtmlItem name="alignJustify" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="orderedList" />
                                <DxHtmlItem name="bulletList" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="color" />
                                <DxHtmlItem name="background" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="link" />
                                <DxHtmlItem name="image" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="clear" />
                                <DxHtmlItem name="codeBlock" />
                                <DxHtmlItem name="blockquote" />
                                <DxHtmlItem name="separator" />
                                <DxHtmlItem name="insertTable" />
                                <DxHtmlItem name="deleteTable" />
                                <DxHtmlItem name="insertRowAbove" />
                                <DxHtmlItem name="insertRowBelow" />
                                <DxHtmlItem name="deleteRow" />
                                <DxHtmlItem name="insertColumnLeft" />
                                <DxHtmlItem name="insertColumnRight" />
                                <DxHtmlItem name="deleteColumn" />
                            </DxHtmlToolbar>
                        </DxHtmlEditor>
                    </div>
                    <!--<DxTextBox class="clZmenaStavAlbinaItem" id="btnZmenaStavAlbinaTextBoxEN" v-model="textStavAlbinyEN" :placeholder="$t('central_stavalbinyEN')"></DxTextBox>-->
                    <div class="cldialogButtons">
                        <DxButton id="StavAlbinyBezZmen" text="Beze změn" @click="StavAlbinyBezZmen" type="normal">
                        </DxButton>
                        <DxButton id="StavAlbinyZrusitOmezeni" text="Zrušit aktuální Omezení"
                            @click="StavAlbinyZrusitOmezeni" type="normal"></DxButton>
                        <DxButton id="StavAlbinyAno" text="Zapsat změny" @click="StavAlbinyZapsatZmeny" type="normal">
                        </DxButton>
                    </div>
                    <DxButton styling-mode="outlined" type="default" id="btnStavAlbina" text="Převod souborů"
                        @click="TransferFiles()">
                    </DxButton>
                </DxScrollView>
            </DxPopup>
            <DxPopup v-model:visible="popupZmenaUzivatele" :show-title="true" :drag-enabled="false" height="auto"
                width="450px" :hide-on-outside-click="false" :show-close-button="true"
                :title="$t('central_ZmenaUzivateleAlbiny')" :focusStateEnabled="true" :shading="true"
                titleTemplate="title">
                <div>
                    <Employees style="width: 340px; margin-left: 10px" :Disabled="false" :Validation="false"
                        :ClearOnClick="true" :EmpId="userID" :Label="$t('pozadavky-Zamestnanec')"
                        @value-changed="SetSelectedValue" Height="40px" />
                    <div style="display: inline-flex;padding-top: 10px; margin-left: 10px">
                        <DxButton style="" styling-mode="contained" text="Změnit uživatele" width="340px" type="default"
                            icon="check" @click="SetUser()"></DxButton>
                        <DxButton style="margin-left: 10px;" width="50px" styling-mode="outlined"
                            hint="Obnovit původního uživatele" type="default" icon="revert" @click="SetOriginalUser()">
                        </DxButton>
                    </div>
                </div>
            </DxPopup>


        </div>
    </div>
</template>

<script>


const buttonRefKey = 'my-button';
import data from '@/data/MenuData.js';
import NavigationList from '@/router/NavigationList.vue';
import LoginApp from '@/views/Login.vue';
import NewVersionWarning from '@/components/NewVersionWarning.vue';
import ErrorView from '@/views/errors/Error.vue';
import themes from "devextreme/ui/themes";
import themesData from '@/data/themes.js';
import { locale } from "devextreme/localization";
import DxScrollView from 'devextreme-vue/scroll-view';
import Employees from '@/components/Employees.vue';
import { isUndefined, isNull } from 'lodash';
import Snowflakes from '@/components/XmasSnowflakes.vue';
import { DxSelectBox, DxButton/*,DxTextBox */, DxTooltip } from 'devextreme-vue';
import {
    DxHtmlEditor,
    DxMediaResizing,
    DxToolbar as DxHtmlToolbar, DxItem as DxHtmlItem
} from 'devextreme-vue/html-editor';
import packageInfo from '@/packageInfo';


/*import {  DxForm,DxGroupItem,DxSimpleItem,
    DxLabel} from 'devextreme-vue/form';*/



//import notify from 'devextreme/ui/notify';
//import 'devextreme-intl';
//import csMessages from 'devextreme/localization/messages/cs.json';

export default {
    name: 'App',
    // Hlavní API - https://sydney.uochb.cas.cz:8099/js/RemObjectsSDK.js , https://sydney.uochb.cas.cz:8099/include/eis.js
    // localhost API - http://192.168.150.100:8099/js/RemObjectsSDK.js , http://192.168.150.100:8099/include/eis.js
    // localhost HONZA API - http://192.168.150.97:8099/js/RemObjectsSDK.js , http://192.168.150.97:8099/include/eis.js
    // localhost HONZA API - http://192.168.150.98:8099/js/RemObjectsSDK.js , http://192.168.150.98:8099/include/eis.js

    beforeCreate() {
        var URLS = {
            "192.168.150.80": { "APIURL": "https://developer.ekois.cz:8099", "Mode": "Developer" },
            "172.20.40.28": { "APIURL": "http://172.20.40.28:8099", "Mode": "Developer" },
            "172.20.40.27": { "APIURL": "http://172.20.40.27:8099", "Mode": "Developer" },
            "192.168.150.98": { "APIURL": "http://192.168.150.98:8099", "Mode": "Developer" },
            "192.168.150.100": { "APIURL": "https://developer.ekois.cz:8099", "Mode": "Developer" },
            "192.168.150.101": { "APIURL": "https://developer.ekois.cz:8099", "Mode": "Developer" },
            "developer.ekois.cz": { "APIURL": "https://developer.ekois.cz:8099", "Mode": "Developer" },
            "albina.uochb.cas.cz": { "APIURL": "https://sydney.uochb.cas.cz", "Mode": "Production" },
            "ursula.uochb.cas.cz": { "APIURL": "https://sydney.uochb.cas.cz:8099", "Mode": "Test" },
        }
        if (URLS[window.location.hostname] == undefined || URLS[window.location.hostname] == null) window.Mode = 'Developer'
        else window.Mode = URLS[window.location.hostname]["Mode"];

        if (window.localStorage.getItem("API") != undefined || window.localStorage.getItem("API") != null) {
            // console.log(window.localStorage.getItem("API"))
            window.URL1 = window.localStorage.getItem("API")
        }
        else {
            if (URLS[window.location.hostname] == undefined || URLS[window.location.hostname] == null) window.URL1 = 'http://172.20.40.28:8099'
            else window.URL1 = URLS[window.location.hostname]["APIURL"];
            window.localStorage.setItem("API", window.URL1);
        }


        return new Promise(resolve => {
            setTimeout(() => {
                //    this.$log.debug('created log after new Promise(resolve)')
                resolve()
            })
        })

    },

    components: {
        NavigationList,
        DxScrollView,
        LoginApp,
        ErrorView,
        DxSelectBox,
        DxButton,
        //DxTextBox,
        DxTooltip,
        DxHtmlEditor,
        DxMediaResizing,
        DxHtmlItem, DxHtmlToolbar,
        NewVersionWarning,
        Employees,
        Snowflakes
        // DxToolbar,DxItem




        /* DxForm,
         DxGroupItem,
         DxSimpleItem,*/
        // DxLabel

    },

    computed: {
        button: function () {
            return this.$refs[buttonRefKey].instance;
        },
        titOtevritZavritMenu: function () {
            if (this.openState == true) {
                return this.$t('central.zavritPostranniMenu')
            }
            else {
                return this.$t('central.otevritPostranniMenu')
            }

        }
        /*
        drawerWidth() {
            var Element = document.querySelector('dx-drawer-panel-content');
            this.$log.debug(Element)
            if(Element != null || Element != undefined)
            Element.style.width = (11 * window.innerWidth / 100) + 'px';
            return 'auto';
        }
        */
    },

    setup() {

    },

    data() {

        const navigation = data.getNavigation();
        const themesList = themesData.getThemes();
        return {
            pravoImpersonate: false,
            userID: window.localStorage.getItem('user') !== null ? JSON.parse(window.localStorage.getItem('user')).EmployeeID.value : '',
            otherUser: false,
            popupZmenaUzivatele: false,
            hidden: true,
            hiding: false,
            animationConfig: {
                show: {
                    type: 'slide',
                    from: {
                        top: -100,
                        opacity: 0,
                    },
                    to: {
                        top: 0,
                        opacity: 1,
                    },
                },
                hide: {
                    type: 'pop',
                    from: {
                        scale: 1,
                        opacity: 1,
                    },
                    to: {
                        scale: 0.1,
                        opacity: 0,
                    },
                },
            },
            animationSettingsPopup: {
                show: {
                    type: 'pop',
                    duration: 300,
                    from: {
                        scale: 0.55
                    }
                },
                hide: {
                    type: 'pop',
                    duration: 300,
                    to: {
                        opacity: 0,
                        scale: 0.55
                    },
                    from: {
                        opacity: 1,
                        scale: 1
                    }
                }
            },
            apis: {
                "Production": ["https://sydney.uochb.cas.cz:8098", "https://sydney.uochb.cas.cz:8097", "https://sydney.uochb.cas.cz:8096", "https://sydney.uochb.cas.cz:8095"],
                "Developer": ["https://developer.ekois.cz:8098", "https://developer.ekois.cz:8097", "https://developer.ekois.cz:8096", "https://developer.ekois.cz:8095",],
                "Test": ["https://sydney.uochb.cas.cz:8099"],
            },
            status: 2,
            Mode: "Production",
            testVerzeStyly: true,
            podporaMail: false,
            popupPodpora: false,
            stavNastaveni: false,
            dataReady: true,
            error: false,
            pozadi: false,
            openState: false,
            showModal: false,
            authenticated: false,
            //--//
            x: 0, // group id
            y: 0, // item id
            //--//
            jazyk: '',
            jazykLabel: '',
            currentTheme: '',
            uzivatel: "",
            uzivatelInicialy: "",
            auto: 'auto',
            buttonRefKey,
            //--//
            themesList,
            navigation,
            //--//
            selectedOpenMode: 'shrink', // push, shrink, overlap
            selectedPosition: 'left', // left, right
            selectedRevealMode: 'slide', // slide, expand
            //--//
            toolbarContent: {
                onClick: () => { this.openState = !this.openState; },
            },
            popupStavAlbina: false,
            popupZmenyVzhled: false,
            dialogStavAlbinyVisible: false,
            textStavAlbinyCZ: '',
            textStavAlbinyEN: '',
            selectedStavAlbin: 0,
            myButtonRef: null,
            hintMessage: 'Toto je Albína',
            tooltipVisible: false,
            tooltipTarget: '',
            tooltipPosition: 'top',
            sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
            fontValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
            acceptedValuesHeader: [false, 1, 2, 3, 4, 5],
            isMultiline: true,
            stavAlbinyTypes: [
                //{ value: 0, text: '[ZELENÁ] Vše funguje správně' },
                { value: 1, text: '[ORANŽOVÁ] Upozornění' },
                { value: 2, text: '[ČERVENÁ] Fatální chyba' }
            ],
            selectedStavAlbinyType: 0,
            AktualniStavAlbiny: 1,
            obsahStavSdeleni: null,
            ikonaStavu: 0,
            ohraniceniStavu: 0,
            pravoAdministrator: false,
            zobrazitPopupStavAlbina: true,
            ukazUpozorneniStavAlbina: true,
            LoginNeededQue: [],
            loggedAPPVersion: '',
            loggedAPIFromStorage: '',
            NewAlbinaVersionAvailable: false,
            Xmas: false,
            UserXmas: window.localStorage.getItem('UserXmas') !== null ? JSON.parse(window.localStorage.getItem('UserXmas'))  : true,
        }
    },

    created() {
        
        this.$log.debug(this.apis)
        this.$log.debug(window.Mode)
        if (window.localStorage.getItem("jazyk") != undefined) { this.$i18n.locale = window.localStorage.getItem("jazyk") }
        window.this = this;
        locale(navigator.language);
        window.channel = new window.RemObjects.SDK.HTTPClientChannel(window.URL1 + "/json");
        window.message = new window.RemObjects.SDK.JSONMessage();
        window.auth = new window.Auth(window.channel, window.message);
        window.channel.onLoginNeeded = function (aCallback) {
            //            console.log('Login needed');
            window.this.LoginNeededQue.push(aCallback);
            if (window.this.authenticated) {
                window.this.authenticated = false;
                let token = window.localStorage.getItem("session-token")
                if (!isNull(token) && !isUndefined(token))
                    window.auth.LoginByToken(token, function (result) {
                        //                   console.log(result.ErrorNum.value);
                        if (result.ErrorNum.value == 0) {
                            while (window.this.LoginNeededQue.length > 0) {
                                window.this.LoginNeededQue.shift()();
                            }
                            window.this.authenticated = true;
                        } else {
                            window.this.logout();
                        }
                    }, window.this.ApiError());
                else {
                    window.this.logout();
                }

            }
        }
        this.LoginBySessionToken();
    },
    beforeMount() {
    },
    mounted() {
        this.XmasCheck()
        this.ApplyTheme()
        this.$log.debug(window.Mode)
        this.$log.debug(window.URL1)
        this.Mode = window.Mode
        this.$log.debug(window.location)
        if (window.location.pathname != '/Login') window.localStorage.setItem("redirecturl", window.location.href)

        this.IntervalAuth();
        this.RefreshLoginToken();
        setTimeout(() => {
            this.$nextTick(() => {
                this.LoadBarvyTheme();
            });
        }, 1000);
        const dataAPI = localStorage.getItem('API');
        this.loggedAPIFromStorage = dataAPI ? dataAPI : '';
        this.$log.debug('Package Information:', packageInfo.version);
        this.loggedAPPVersion = packageInfo.version;
        if (window.localStorage.getItem("jazyk") === 'en') locale('en');
        else locale('cs');
    },

    methods: {
        handleBanXmasClick(){
            this.UserXmas=!this.UserXmas
            window.localStorage.setItem("UserXmas", this.UserXmas)
            const event = new CustomEvent('UserXmasChanged', { detail: this.UserXmas });
            window.dispatchEvent(event);
        },
        XmasCheck() {
            const today = new Date();
            const month = today.getMonth() + 1;
            const day = today.getDate();
            const year = today.getFullYear();
            //  číslo ve tvaru "MMDD" (např.  1. března => 301, 30. listopadu => 1130)
            const mmdd = month * 100 + day;
            this.$log.debug(mmdd+' mmdd')
            this.Xmas = ((mmdd < 301 && year>2025) || mmdd > 1130) ; // && year>2025 omezeni bylo pridano, protoze se zacatkem roku vypnuly vlocky, tak uz bylo hloupe je znovu zapinat jeste do brezna
        },
        ApplyTheme() {
            let theme = window.localStorage.getItem("theme");
            if (theme && !theme.includes("generic")) {
                theme = "generic." + theme;
                window.localStorage.setItem("theme", theme);
            }
            const availableThemes = this.themesList.flatMap(item => item.items.map(theme => theme.name));
            if (!availableThemes.includes(theme)) {
                theme = "generic.optimal";
            }
            this.currentTheme = theme
            themes.current(theme);
        },
        SetOriginalUser() {
            if (window.localStorage.getItem('OriginalUser') == null) this.userID = JSON.parse(window.localStorage.getItem('user')).EmployeeID.value
            else this.userID = window.localStorage.getItem('OriginalUser')
            var g = new window.General(window.channel, window.message);
            let self = this;
            g.Impersonate(this.userID,
                function (result) {
                    if (result.Error.value.ErrorNum.value == 0) {
                        self.$log.debug(result);
                        self.otherUser = false,
                            self.popupZmenaUzivatele = false;
                        window.localStorage.removeItem("ImpersonatedUser");
                        window.localStorage.removeItem("OriginalUser");
                        self.nacteniPrav()
                        if (self.$route.name == 'Pozadavky') self.$router.push('/Aktualne')
                        // else self.$router.push('/Pozadavky')
                        //  window.location.reload();
                    }
                    self.getZam()
                }, window.this.ApiError());

        },
        SetSelectedValue(e) {
            this.$log.debug(e)
            this.userID = e.value
        },
        SetUser() {
            var originalUser
            if (window.localStorage.getItem('OriginalUser') == null) originalUser = JSON.parse(window.localStorage.getItem('user')).EmployeeID.value
            else originalUser = window.localStorage.getItem('OriginalUser')
            var g = new window.General(window.channel, window.message);
            let self = this;
            self.$log.debug(this.userID)
            g.Impersonate(this.userID,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value == 0) {
                        self.otherUser = self.userID != null && self.userID != originalUser
                        self.popupZmenaUzivatele = false;
                        window.localStorage.setItem('ImpersonatedUser', self.userID)
                        window.localStorage.setItem('OriginalUser', originalUser)
                        // window.location.reload();
                        self.$log.debug(self.$route)
                        self.nacteniPrav()
                        if (self.$route.params.Niceurl == undefined) {
                            if (self.$route.name == 'Pozadavky') self.$router.push('/Aktualne')
                            //  else self.$router.push('/Pozadavky')
                        }


                    }
                    self.$log.debug('volam tadz')
                    self.getZam()

                }, window.this.ApiError());
        },
        TransferFiles() {
            var g = new window.General(window.channel, window.message);
            let self = this;
            g.TransferFilesFromDatabase(
                function (result) {
                    self.$log.debug(result);
                }, window.this.ApiError());
        },
        NewVersionReload() {
            // window.location.reload();
            var a = window.location.href
            window.location.href = a
        },
        openOsobniInformace() {
            this.$router.push('/OsobniInformace/Informace');
            this.stavNastaveni = false;

        },
        LoadBarvyTheme() {
            this.$log.debug(document.getElementsByClassName('dx-theme-accent-as-background-color'))
            if (document.getElementsByClassName('dx-theme-accent-as-background-color').length > 0) {
                var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
                var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
                var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
                var lightercolortheme = this.lightenColor(FillColorBarvaTheme, 100, 1);
                var lightercolortheme50 = this.lightenColor(lightercolortheme, 0.5, 2);
                var lightercolortheme35 = this.lightenColor(lightercolortheme, 0.35, 2);
                var lightercolorthemeHeader = this.lightenColor(lightercolortheme, 0.25, 2);
                //console.log('Background Color:', FillColor);
                document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
                document.documentElement.style.setProperty('--colorByThemeLighter', lightercolortheme);
                document.documentElement.style.setProperty('--colorByThemeLighter50', lightercolortheme50);
                document.documentElement.style.setProperty('--colorByThemeLighter35', lightercolortheme35);
                document.documentElement.style.setProperty('--colorByThemeLighterHeaders', lightercolorthemeHeader);
                document.documentElement.style.setProperty('--colorZelenaAgree', '#5cb83c');
                document.documentElement.style.setProperty('--colorOranzovaWarning', '#ffcc33');
                document.documentElement.style.setProperty('--colorCervenaIssue', '#ffcc33');
            }
        },
        lightenColor(rgbColor, factor, type) {
            const match = rgbColor.match(/^rgb\((\d+), (\d+), (\d+)\)$/);
            if (!match) {
                throw new Error("Invalid RGB color format");
            }
            // Extrakce stavajicich hodnot
            const red = parseInt(match[1]);
            const green = parseInt(match[2]);
            const blue = parseInt(match[3]);
            if (type == 1) {
                // Kalkulace svetlejsi barvy podle zadaneho faktoru
                const newRed = Math.min(255, red + factor);
                const newGreen = Math.min(255, green + factor);
                const newBlue = Math.min(255, blue + factor);

                return `rgb(${newRed}, ${newGreen}, ${newBlue})`;
            } else if (type == 2) {
                return `rgba(${red}, ${green}, ${blue},${factor})`;

            }
        },

        ImpersonatePermission() {
            this.$log.debug('Nacteni prav impersonate');
            var g = new window.General(window.channel, window.message);
            let self = this;
            g.Validate('abvcddsf',
                function (result) {
                    self.$log.debug(result);
                    if (result.ErrorNum.value == 0) {
                        self.pravoImpersonate = true;
                    } else { self.pravoImpersonate = false; }
                }, window.this.ApiError());

        },
        nacteniPrav() {
            this.$log.debug('Nacteni prav');
            var g = new window.General(window.channel, window.message);
            let self = this;
            g.Validate('PravoAdministrator',
                function (result) {
                    self.$log.debug(result);
                    if (result.ErrorNum.value == 0) {
                        self.pravoAdministrator = true;
                        self.pravoImpersonate = true;
                    } else { self.pravoAdministrator = false; }
                }, window.this.ApiError());

        },
        stavAlbinaReturn() {
            var self = this; // Make sure 'this' refers to the Vue component instance
            var gen = new window.General(window.channel, window.message);
            gen.GetAlbinaStatus(function (result) {
                self.$log.debug(result);
                if (window.localStorage.getItem("jazyk") == 'cs') {
                    self.obsahStavSdeleni = result.Message.value;
                } else {
                    self.obsahStavSdeleni = result.MessageEN.value;
                }
                self.ikonaStavu = result.Status.value;
                self.ohraniceniStavu = result.Status.value;
                self.textStavAlbinyCZ = result.Message.value;
                self.textStavAlbinyEN = result.MessageEN.value;
                if (result.Status.value == 0) {
                    self.ukazUpozorneniStavAlbina = false;
                } else { self.ukazUpozorneniStavAlbina = true; }
                if (self.loggedAPPVersion < result.AlbinaVersion.value) self.NewAlbinaVersionAvailable = true
                else self.NewAlbinaVersionAvailable = false
            }, window.this.ApiError());
        },

        selectBoxStavInitialized(e) {
            console.$log(e);
        },
        logoAlbinaReturn() {

            var resultStavAlbiny = 0;
            var gen = new window.General(window.channel, window.message);
            gen.GetApiStatus(function (result) {
                self.$log.debug(result);
                resultStavAlbiny = result.Status.value;
            }, window.this.ApiError());
            switch (resultStavAlbiny) {
                case '0':
                    return 'logoAlbinaDIV';
                case '1':
                    return 'logoAlbinaDIV_Orange';
                case '2':
                    return 'logoAlbinaDIV_Red';
                default:
                    return 'logoAlbinaDIV';

            }
        }, logoAlbinaDIVRIGHTReturn() {

            var resultStavAlbiny = 0;
            var gen = new window.General(window.channel, window.message);
            gen.GetApiStatus(function (result) {
                self.$log.debug(result);
                resultStavAlbiny = result.Status.value;
            }, window.this.ApiError());
            switch (resultStavAlbiny) {
                case '0':
                    return 'logoAlbinaDIVRIGHT';
                case '1':
                    return 'logoAlbinaDIVRIGHT_Orange';
                case '2':
                    return 'logoAlbinaDIVRIGHT_Red';
                default:
                    return 'logoAlbinaDIVRIGHT';

            }
        },
        onSelectChange(e) {
            this.selectedStavAlbinyType = e.value;
            // console.log(e);
            // console.log('Selected item:', this.selectedStavAlbinyType);
        },
        openPopup(vstup) {
            switch (vstup) {

                case 'ZMENIT_VZHLED':
                    //window.location.href = 'https://intraweb.uochb.cas.cz'
                    this.popupZmenyVzhled = true;
                    break;
            }
        },
        showTooltip() {
            this.tooltipVisible = true;
        },
        hideTooltip() {
            this.tooltipVisible = false;
        },
        StavAlbinyBezZmen() {
            // close the popup
            this.popupStavAlbina = false;
        },
        StavAlbinyZrusitOmezeni() {
            var self = this;
            // var auth = new window.Auth(window.channel, window.message);
            self.nacteniPrav();
            if (self.pravoAdministrator == true) {
                var gen = new window.General(window.channel, window.message);
                gen.SetApiStatus(0, '', '', function (result) {
                    self.$log.debug(result);
                    gen.GetApiStatus(function (result) {
                        self.$log.debug(result);
                        self.popupStavAlbina = false;
                        //  window.location.reload();
                        self.stavAlbinaReturn()
                    }, window.this.ApiError());
                }, window.this.ApiError());
            }
            // else { window.location.reload(); }
            // Cancel actual state of albina
            //console.log("State change canceled");
            /* Volání API Na změnu stavu na default takže zpráva cj aj ='' + stav 1(Zelená) */

        },
        StavAlbinyZapsatZmeny() {
            var self = this;
            self.nacteniPrav();
            if (self.pravoAdministrator == true) {
                // var auth = new window.Auth(window.channel, window.message);
                var gen = new window.General(window.channel, window.message);
                gen.SetApiStatus(self.selectedStavAlbinyType, this.textStavAlbinyCZ, this.textStavAlbinyEN, function (result) {
                    self.$log.debug(result);
                    gen.GetApiStatus(function (result) {
                        self.$log.debug(result);
                        self.popupStavAlbina = false;
                        self.stavAlbinaReturn()
                        //window.location.reload();
                    }, window.this.ApiError());
                }, window.this.ApiError());
            }
            //else { window.location.reload(); }
            /* Volání API Na změnu stavu na zvolený stav */
            /*this.textStavAlbinyCZ this.textStavAlbinyEN this.selectedStavAlbiny*/

        },
        clearStavAlbiny() {
            var self = this;
            self.textStavAlbinyCZ = '';
            self.textStavAlbinyEN = '';
        },
        openPopupStavAlbina() {
            var self = this;
            setTimeout(() => {
                self.nacteniPrav();
            }, 1000);

            if (self.pravoAdministrator == true) {
                var selectBoxInstance = this.$refs.selectBoxStavRef.instance;

                var gen = new window.General(window.channel, window.message);
                gen.GetApiStatus(function (result) {
                    selectBoxInstance.option('value', result.Status.value);
                    if ((result.Status.value) == 0) {
                        self.textStavAlbinyCZ = '';
                        self.textStavAlbinyEN = '';
                    }
                }, window.this.ApiError());
                this.popupStavAlbina = true;
            }
            else { window.location.reload(); }
        },
        ToolbarInit(e) {
            this.$log.debug(e)
            e.component.repaint()
        },
        LoadApi(url) {
            this.$log.debug('LOAD API')
            var self = this;
            self.axios.get(url)
                .then(response => {
                    self.$log.debug(response)
                    this.info = response
                },
                    (error) => {
                        self.$log.debug(error)
                        if (error.code == 'ERR_NETWORK') {
                            self.$log.debug(error.code)
                        }
                        else {
                            if (isUndefined(window.URL1) && !window.loaded) {
                                window.URL1 = url
                                window.loaded = true
                            }
                        }
                    });


        },

        ApiError() {
            // this.$log.debug('VOLANI API ERROR FCE')
            var self = this;
            self.axios.get(window.URL1)
                .then(response => {
                    //   self.$log.debug(response)
                    this.info = response
                },
                    (error) => {
                        self.$log.debug(error)
                        console.log(error)
                        if (error.code == 'ERR_NETWORK' || error.code == "ERR_BAD_REQUEST" || error.code != undefined || error.code != null) {
                            //  window.location.reload()
                            var route = this.$route
                            this.$router.push('/Error')
                            this.error = true;
                            this.pozadi = true;
                            // Zkousi pripojeni na vice api soucasne a zprvniho co dostane response tak to ulozi a nacte

                            self.apis[window.Mode].forEach(function (APIURL) {
                                self.axios.get(APIURL)
                                    .then(response => {

                                        self.$log.debug(response)
                                        self.$log.debug('volam developera')
                                        self.info = response
                                        if (response.status != 200) {
                                            self.$log.debug(response)
                                        }
                                        else {
                                            self.$log.debug('Developer bezi')
                                            window.URL1 = APIURL
                                            window.localStorage.setItem("API", APIURL)
                                            self.$router.push(route.href)
                                            setTimeout(() => {
                                               window.location.reload(); 
                                            }, 100);
                                        }
                                    },
                                        (error) => {
                                            self.$log.debug(error)

                                        });
                            });
                        }

                    });

        },
        Home() {
            this.$router.push("/Aktualne");
            this.openState = true
        },

        changelocale(locale) {
            var self = this
            this.$log.debug(locale);
            var auth = new window.Auth(window.channel, window.message);
            auth.SetEmployeeInfo(locale,
                function (result) {
                    self.$log.debug(result)
                    window.localStorage.setItem("jazyk", locale)
                    window.thisNavigation.setDataSourceDrawer()
                    window.location.reload(); // jde to i bez reloadu, ale neprenactou se data, ktera naplnila selectboxy v api

                }, window.this.ApiError());


        },
        zobrazNastaveni() {
            if (this.hidden || !this.hiding) {
                this.stavNastaveni = !this.stavNastaveni;
                this.hidden = true
            }
        },
        LinkOut(e) {
            // window.location.href = 'https://intraweb.uochb.cas.cz'
            //  window.location.href = 'https://uochb.okbase.cz'
            switch (e) {
                case 'PREKLAD':
                    //window.location.href = 'https://intraweb.uochb.cas.cz'
                    //var adresa = encodeURIComponent(this.textStavAlbinyCZ)
                    var html = this.textStavAlbinyCZ
                    var htmlstring = html.replace(/<[^>]*>/g, ' ');
                    this.$log.debug(htmlstring);
                    var adresa = encodeURIComponent(htmlstring);
                    this.$log.debug(adresa);

                    setTimeout(() => {
                        window.open(
                            'https://translate.google.com/?hl=cs&sl=cs&tl=en&text=' + adresa + '&op=translate',
                            '_blank' // <- This is what makes it open in a new window.
                        );
                    }, 150);
                    setTimeout(() => {
                        window.open(
                            'https://www.deepl.com/translator#cs/en/' + adresa,
                            '_blank' // <- This is what makes it open in a new window.
                        );
                    }, 150);

                    break;
                case 'INTRAWEBMENU':
                    //window.location.href = 'https://intraweb.uochb.cas.cz'
                    window.open(
                        'https://intraweb.uochb.cas.cz',
                        '_blank' // <- This is what makes it open in a new window.
                    );
                    break;
                case 'OKBASEMENU':
                    window.open(
                        'https://uochb.okbase.cz',
                        '_blank' // <- This is what makes it open in a new window.
                    );
                    // window.location.href = 'https://uochb.okbase.cz'
                    break;
                case 'ZMENA_HESLA':
                    window.open(
                        'https://albina.uochb.cas.cz/heslo/index.php',
                        '_blank' // <- This is what makes it open in a new window.
                    );
                    // window.location.href = 'https://albina.uochb.cas.cz/heslo/index.php'
                    break;

            }
        },
        LogoClick() {
            this.openState = !this.openState;
            //this.$log.debug(this.openState) 
            //this.$log.debug("Záznam !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")

            /*  setTimeout(function(){
                                      $("#iconMenu").prop("disabled", true).val("Wait...");
                                      }, 1000);
              setTimeout(function(){this.getElementById("iconMenu").visible = true;},5000);
              */
        },
        GetThemeColor(e) {
            this.$log.debug(e)
        },
        IntervalAuth() {
            this.$log.debug("volam AUTH")
            let self = this;
            if (this.authenticated) {
                self.stavAlbinaReturn()
            }
            setTimeout(this.IntervalAuth, 600000);

        },
        RefreshLoginToken() {
            this.$log.debug("volam TOKEN REFRESH")
            // this.$log.debug(this.authenticated)
            var auth = new window.Auth(window.channel, window.message);
            let self = this;
            if (this.authenticated) {
                auth.RefreshToken(function (result) {
                    self.$log.debug(result);
                }, window.this.ApiError());
            }
            setTimeout(this.RefreshLoginToken, 7200000);
        },
        open() {
            window.loader = this.$loading.show({
                loader: 'dots'
            });
        },
        hide() {
            window.loader.hide()
        },
        LoginBySessionToken() {
            let token = window.localStorage.getItem("session-token")
            let self = this
            self.$log.debug(this.$route)
            self.$log.debug('session token login')
            self.$log.debug(token)
            if (!isNull(token) && !isUndefined(token))
                window.auth.LoginByToken(token, function (result) {
                    self.$log.debug(result)
                    self.dataReady = true
                    if (result.ErrorNum.value == 0) {
                        self.ImpersonatePermission()
                        if (window.localStorage.getItem("ImpersonatedUser") !== null) {
                            self.userID = window.localStorage.getItem("ImpersonatedUser")
                            self.$log.debug(self.userID, 'USERID')
                            self.SetUser()
                        } else self.getZam();
                        self.authenticated = true
                        self.stavAlbinaReturn();
                        self.openState = true;
                        self.$log.debug('i18n');
                        self.$log.debug(self.$i18n);
                        self.nacteniPrav();
                        self.SetStyle(window.localStorage.getItem("theme"));
                    }
                    else {
                        self.$log.debug('do loginu')
                        self.$router.replace({ name: "Login" });
                        self.pozadi = true
                    }
                    self.status = 1
                }, window.this.ApiError());
            else {
                self.$router.push('Login');
                this.pozadi = true
            }

        },
        changeTheme(args) {
            this.$log.debug('CHANGE THEMEEE')
            this.$log.debug(args)
            var themeName = args.value
            window.localStorage.setItem("theme", themeName);
            this.SetStyle(themeName)
            themes.current(themeName);
            this.popupZmenyVzhled = false
            // this.stavNastaveni = false
            //window.location.reload();
            setTimeout(() => {
                this.$nextTick(() => {
                    this.LoadBarvyTheme();
                });
            }, 1000);

        },
        setAuthenticated(status) {
            this.$log.debug('set auth')

            if (status) {
                this.pozadi = false
                this.nacteniPrav();
            }

            this.openState = status;
            this.authenticated = status;
            if (this.authenticated) this.stavAlbinaReturn();
            this.getZam();
            //  this.GetStyle()

        },
        logout() {
            this.stavNastaveni = false;
            // window.auth.Logout()
            var self = this
            self.$router.push('/Login');
            self.pozadi = true

            window.auth.Logout(
                function () {
                    self.authenticated = false;
                    window.logout = false
                    self.openState = false
                    self.otherUser = false
                    window.localStorage.setItem("redirecturl", '/Aktualne')
                    window.localStorage.setItem("session-token", undefined)
                    window.localStorage.removeItem("ImpersonatedUser");
                    window.localStorage.removeItem("OriginalUser");
                    // window.prevElement = undefined
                    // window.prevPosition = undefined

                    //  self.hide()
                }, window.this.ApiError());
        },
        SetLang(locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale
            }
            this.jazyk = locale;
            var auth = new window.Auth(window.channel, window.message);
            let self = this;

            auth.SetEmployeeInfo(this.jazyk,
                function (result) {
                    self.$log.debug(result)
                    window.location.reload(true);
                }, window.this.ApiError());

        },
        SetStyle(value) {
            this.$log.debug(value)
            var gen = new window.General(window.channel, window.message);
            let self = this;
            if (this.authenticated)
                gen.SetUserVariable('theme', value,
                    function (result) {
                        self.$log.debug(result);
                    }, window.this.ApiError());
        },
        GetStyle() {

            let self = this;
            var gen = new window.General(window.channel, window.message);

            gen.GetUserVariable('groupid',
                function (result) {
                    self.$log.debug('groupid')
                    self.$log.debug(result)
                    // self.x = result.Value.value
                    // if(isUndefined(self.x)) self.x=0
                }, window.this.ApiError());

            gen.GetUserVariable('itemid',
                function (result) {
                    self.$log.debug('itemid')
                    self.$log.debug(result)
                    // self.y = result.Value.value
                    // if(isUndefined(self.y)) self.y=0
                }, window.this.ApiError());

        },
        getZam() {
            this.$log.debug('volam get zam')
            let self = this;
            window.auth.GetEmployeeInfo(0, function (result) {
                window.localStorage.setItem("user", JSON.stringify(result))
                setTimeout(() => {
                    window.thisNavigation.ValidateInventoryCommission()
                }, 200);
                self.uzivatel = result.Firstname.value + ' ' + result.Surname.value;
                var ZAMfirstname = result.Firstname.value;
                var ZAMsurname = result.Surname.value;
                self.uzivatelInicialy = ZAMfirstname[0].toUpperCase() + ZAMsurname[0].toUpperCase();
                window.uzivatel = result.Firstname.value + ' ' + result.Surname.value;
                window.uzivatelInicialy = ZAMfirstname[0].toUpperCase() + ZAMsurname[0].toUpperCase();
                self.jazyk = result.Lang.value
                window.jazyk = result.Lang.value
                window.localStorage.setItem("jazyk", result.Lang.value)


                self.$log.debug('getZam');
                self.$log.debug(result);

                if (typeof self.$i18n != "undefined") {
                    self.$i18n.locale = result.Lang.value;
                }
                else {
                    self.$log.debug("Undefined $i18");
                }

                self.$log.debug(result.Lang.value);
                self.$log.debug(self.$i18n);
            }, window.this.ApiError());

        },

    }
}

</script>

<style scoped>
/* Styly CLASS */

/*
přepsani dx classy syntaxe
:deep(.dx-list-item) {  
    background-color: green;  
}  
:deep(.dx-toolbar-center) {  
    margin: 0px auto;
    float: none;
    display: initial;
}
*/
:deep(.dx-drawer-panel-content) {
    width: 11vw !important;
    background: var(--colorByTheme);
}

:deep(.dx-scrollable-content) {
    padding-bottom: 4px !important;
}

#svgPath {
    fill: var(--colorByTheme);

}

#lblzmenit {
    margin-left: 24px;
}

#uzivatel {
    margin-left: -19px;
}

#spanMenuSessionID {
    margin: 0px -5px -10px 0px;
    z-index: -1;
    display: flex;
    justify-content: space-between;
}

#lblMenuSessionID {
    text-align: right;
    font-size: x-small;
    color: #9d9d9d;
    ;
    font-style: italic;
}

:deep(.dx-treeview-item.dx-state-hover) {
    color: rgb(72, 255, 0) !important;
    background-color: rgba(179, 179, 179, 0.1) !important;
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standardní syntax */
}

.dx-toolbar-text-auto-hide .dx-button .dx-button-content {
    background-color: red !important;
}

.dx-icon .dx-icon-overflow {
    background-color: red;
}

.dx-field-label {
    float: left;
    width: 20%;
}

.toolbar-label {
    vertical-align: middle;
}

.dx-toolbar-items-container {
    height: 100% !important;
}

/* NAVIGATION STYLE */
.dx-toolbar .dx-toolbar-after {
    padding-left: 15px;
    margin-right: 100px;
}

.dx-toolbar-item {
    vertical-align: middle;
}

.dx-toolbar {
    padding: 5px 10px;
    margin-right: 100px;
    height: 66px !important;
}

.dx-list {
    width: 200px;
}

.dx-drawer-content {
    height: 100%;

    overflow: hidden;
    top: 0;
}

.dx-scrollable {
    display: block;

    height: 20%;
    /*min-height: 200px;*/
}

.dx-button-mode-contained .dx-button-default {
    color: #ffffff !important;
}

.dx-list-item-icon-container,
.dx-toolbar-before {
    width: 36px;

    background-color: #ffffff !important;

    padding-right: 0 !important;
    text-align: center;
}

.dx-list-item-content {
    padding-left: 10px !important;
}

.panel-list {
    height: 400px;
}

.panel-list .dx-list-item {
    color: #fff;
    border-top: 1px solid rgba(221, 221, 221, 0.2);
}

.panel-list .dx-list-item .dx-icon {
    color: #fff !important;
}

.options {
    padding: 20px;

    background-color: rgba(191, 191, 191, 0.15);
}

.options-container {
    display: flex;
    align-items: center;
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    display: inline-block;

    margin-top: 10px;
    margin-right: 50px;
}

.content {
    width: 100%;
    height: 100%;

    padding: 0px 0px 5px 10px;
}

.content h2 {
    font-size: 26px;
}

.app {
    width: 100%;
    height: 100%;

    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    background-repeat: no-repeat;
    background-size: cover;

}

.Delitko {
    display: flex;
    width: 10px;
    height: 56px;

    color: var(--colorByTheme);

    font-size: 56px;
    text-shadow: none;
    font-weight: 100;

    margin-top: -20px;
    margin-bottom: 20px;
    margin-right: 0px;
    margin-left: -10px;
}

.LabelEN,
.LabelCZ {
    position: absolute;
    width: 42.5px;
    height: 21.25px;

    transform: translate3d(0, 0, 0);
    transition: all 150ms;

}

.LabelEN:hover,
.LabelCZ:hover {
    box-shadow: 0 10px 20px rgba(0, 32, 91, 0.1), 0 3px 6px rgba(0, 32, 91, 0.25);
}

.LabelCZ {
    top: 0;
    margin-top: 5px;
}

.LabelEN {
    bottom: 0;
    margin-bottom: 5px;
}

.LabelEN:before {
    display: inline-block;
    width: 42.5px;
    height: 21.25px;

    align-self: center;
    content: '';
    background-image: url('./assets/images/flag_en.png');
    background-size: cover;
    background-repeat: no-repeat;

    cursor: pointer;
}

.LabelCZ:before {
    display: inline-block;
    width: 42.5px;
    height: 21.25px;

    align-self: center;
    content: '';
    background-image: url('./assets/images/flag_cz.png');
    background-size: 100%;
    background-repeat: no-repeat;

    cursor: pointer;
}

.dx-item.dx-treeview-item {
    border-style: ridge;
    border-color: black;
}

.LabelEN:hover .tooltiptextEN {
    visibility: visible;
}

.LabelCZ:hover .tooltiptextCZ {
    visibility: visible;
}

.clZmenaStavAlbinaItem {
    padding: 5px;
    margin-bottom: 5px;
}

.cldialogButtons {
    padding-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: baseline;
}

.clMenuRadekUSER {
    text-align: center;
    height: 40px;
    width: 100%;
    border: 0.5px rgba(90, 85, 85, 0.25) solid;
    border-top: 0;
    border-bottom: 1;
    border-left: 0;
    border-right: 0;

    font-family: Arial, Helvetica, sans-serif;
}

#idMenuOdhlasit {
    border-bottom: 0 !important;
}

.clMenuRadekUSER:hover {
    background-color: rgba(250, 121, 0, 0.10);
}

#idMenuUzivatel:hover {
    background-color: transparent !important;
}

.clIkonyMenu {
    height: 16px;
    width: 16px;
    margin-right: 2px;
}



/* Styly ID */
#idikonamenuuser {
    float: left;
}

#idikonamenuclose {
    float: right;
    margin-top: 4px;
}

#idikonamenulink {
    margin-left: 5px;
}

#idMenuUzivatel {
    text-align: center;
    height: 25px !important;
    color: orange
}

#idMenuUSER {
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
}

#btnZmenaStavAlbinaTextBox {
    margin-top: 15px;
}

#btnZmenaStavAlbinaOdeslat {
    float: right;

    padding-right: 0px;
}

#semafor {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2px;
    height: auto;
    width: max-content;
    border: 0.05px rgb(180, 180, 180) solid;
    border-radius: 25px;
}

#hr {

    /**/
    margin-block-start: 0;
    margin-block-end: 0;
    /**/
    background-color: #fff;
}

#btnStavAlbina {
    width: max-content;
    border-color: rgba(0, 32, 91, 1);
    border-radius: 8px;
    border-width: 0.05px;
    background-color: initial;

    color: inherit;
    cursor: pointer;

    transition: all 150ms;
    float: left;

    margin-bottom: 5px;
}

#odhlasit {
    width: max-content;
    border-color: rgba(0, 32, 91, 1);
    border-radius: 8px;
    border-width: 0.05px;
    background-color: initial;

    color: inherit;
    cursor: pointer;

    transition: all 150ms;
    float: right;

    margin-bottom: 5px;
}

.idMenuButtonNovy {
    height: 100%;
    width: 100%;
    border: 0px none;
    text-align: center;
    cursor: pointer;
}

.idMenuButtonNovy img {
    cursor: pointer;
}

.idMenuButtonNovy label {
    cursor: pointer;
}

#obrazekLogoutTEXTNovy {
    width: max-content;
    height: max-content;
    top: 50%;
    left: 50%;

    color: inherit;
    font-size: 15px;
    font-weight: 300;
}

#iconMenu,
#iconMenuUSER {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    /* text-align: center; */
    /* vertical-align: middle; */
    vertical-align: center;
    height: 38.5px;
    width: 38.5px;

    border-style: ridge;
    border-color: var(--colorByTheme);
    border-radius: 8px;
    border-width: 1px;

    padding-left: 3px;
    padding-top: 2px;
    padding-bottom: 3px;
    padding-right: 2px;

    margin-right: 10px;

    cursor: pointer;
    background-color: none;

    transform: translate3d(0, 0, 0);
    transition: all 150ms;
}

#iconMenuUSER {
    padding: 7.5px;
}

#iconMenu:hover,
#iconMenuUSER:hover {
    background-color: rgba(128, 128, 128, 0.2);
}



#odhlasit:hover,
#btnStavAlbina:hover {
    background-color: rgba(128, 128, 128, 0.1);
}


#labelOdkazy {
    margin-left: 15px;
}

#toolbar {
    background-color: #ffffff !important;

    margin-right: 30px;

    padding: 7.5px 15px;
}

#nav {
    font-size: 120%;
}

#uziv {
    color: rgb(255, 0, 0);
}

#OKBASEMENU,
#INTRAWEBMENU {
    height: 52px;
    align-items: center;

    background-color: initial;
    background-image: linear-gradient(rgba(0, 32, 91, 0.2), rgba(0, 32, 91, 1));

    border-radius: 8px;
    border-width: 0;

    box-sizing: border-box;
    color: rgba(255, 255, 255, 0);
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    font-size: 18px;

    justify-content: center;
    line-height: 1;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: 0 5px;
    text-align: center;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    transition: all 250ms;
    vertical-align: baseline;

    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    background-size: 35px;
    padding: 7.5px !important;
}

#OKBASEMENU {
    align-self: left;
    height: 40px;
    width: 50px;

    background-image: linear-gradient(rgba(207, 159, 1, 0.2), rgba(207, 159, 1, 1));
    background-image: url('./assets/images/logo_OKBASE.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 35px;
    background-position: center;

    margin-right: 5px;
    margin-left: 5px;

    border-style: ridge;
    border-width: 0.05px;
    border-color: rgba(207, 159, 1, 1);
    padding: 7.5px !important;
}

#ALBINASTAV {
    order: 2;
    height: 6px;
    width: 50px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 35px;
    background-position: center;
    border-style: ridge;
    border-width: 0.01px;
    border-color: rgb(141, 141, 141);
}

#ALBINASTAV:hover {
    order: 2;
    height: 12px;
    width: 50px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
    border-style: ridge;
    border-width: 0.01px;
    border-color: rgb(141, 141, 141);
    cursor: help;
}

#INTRAWEBMENU {
    align-self: right;
    height: 40px;
    width: 50px;

    background-image: linear-gradient(rgba(85, 85, 85, 0.2), rgba(85, 85, 85, 1));
    background-image: url('./assets/images/logo_UOCHB.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 35px;
    background-position: center;

    margin-right: 5px;
    margin-left: 5px;

    border-style: ridge;
    border-width: 0.05px;
    border-color: rgb(0, 32, 91);
}

#OKBASEMENU:hover {
    width: 95px;

    background-image: url('./assets/images/logo_OKBASE.png');
    background-image: linear-gradient(rgba(207, 159, 1, 1), rgba(207, 159, 1, 0.2));
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 40px;
    background-size: auto;
    background-position: center;

    color: black;
    color: rgba(255, 255, 255, 100);

    margin-left: 5px;
    margin-right: 5px;

    box-shadow: 0 10px 20px rgba(0, 32, 91, 0.1), 0 3px 6px rgba(0, 32, 91, 0.25);

}

#INTRAWEBMENU:hover {
    width: 95px;

    background-image: url('./assets/images/logo_UOCHB.png');
    background-image: linear-gradient(rgba(85, 85, 85, 1), rgb(0, 32, 91));
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 40px;
    background-size: auto;
    background-position: center;

    color: rgba(255, 255, 255, 100);

    margin-left: 5px;
    margin-right: 5px;

    box-shadow: 0 10px 20px rgba(0, 32, 91, 0.1), 0 3px 6px rgba(0, 32, 91, 0.25);
}

.DxHtmlEditorStav {
    padding: 10px;
}

#logoAlbinaDIVLEFT {
    order: 0;
    float: left;
    width: 56px;
    height: 100%;
}

.logoAlbinaDIVRIGHT {
    order: 1;
    height: 100%;
    /* Set an appropriate height for the parent container */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    text-align: right;
    display: flex;
    justify-content: flex-start;
}

#logoAlbinaDIVRIGHT {
    /**/
    order: 0;
}

#logoAlbinaDIVRIGHT_Orange {
    background-image: url("@/assets/images/black_error2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 28px;
    background-position-x: 175px;
    padding: 0 40px 0 0;
    background-position-y: 24.5px;
}

#logoAlbinaDIVRIGHT_Red {
    background-image: url("@/assets/images/red_error.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 28px;
    background-position-x: 175px;
    padding: 0 40px 0 0;
    background-position-y: 24.5px;
}

#logoAlbinaDIV {
    width: max-content;
    height: 55px;
}


#logoAlbinaDIV_Orange {
    background-color: rgba(255, 153, 0, 0.788);
    border-radius: 5px;
}

#logoAlbinaDIV_Red {
    background-color: rgba(255, 0, 0, 0.788);
    border-radius: 5px;
}

#logoIMG {
    height: 56px;
    width: 56px;

    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-right: 5px;

    cursor: pointer;
}

#imgStav2 {
    background-image: url("@/assets/images/black_error.png");
    background-color: #cccccc;
    height: 35px;
    width: 35px;

}

#logoStatus {
    height: 16px;
    width: 16px;
    margin-right: 2px;
    margin-left: 2px;
    cursor: help;
}

#AlbinaText {
    height: 20px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    vertical-align: center;
    top: 45%;


}

#LokalizaceJazyky {
    width: 50px;
    height: 25px;
}

#obrazekLogout,
#obrazekSettings {
    float: left;
    height: 25px;
    width: 25px;

    margin-top: 0px;
}

#obrazekSettings {
    margin-right: 20px;
}

#obrazekLogoutTEXT {
    width: max-content;
    height: max-content;
    top: 50%;
    left: 50%;

    color: inherit;
    font-size: 15px;
    font-weight: 300;
}

#btnzmenaVzhledu {
    margin-right: 20px
}

#dxitemLoggedUSER {
    margin: 0px auto !important;
    float: none !important;
}

#BottomPodporaLabel {
    float: bottom;
    height: 50px;
    width: 100%;

    border-style: ridge;
    border-color: #ffffff;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    margin-top: 900px;
    margin-left: 0px;
    margin-bottom: 10px;
    margin-right: 0px;

    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;

    color: #ffffff;

    cursor: pointer;
}

#lblLoggedUser,
#lblVzhled,
#labelOdkazy,
#greeting {
    max-height: 56px;

    font-size: 15px;
    font-optical-sizing: auto;
    font-weight: 400;

    cursor: text;
}

#lblLoggedUser,
#labelOdkazy {
    cursor: default;
}

#lblVzhled,
#btnzmenaVzhledu {
    margin: 5px
}

#lblLoggedUser,
#lblLoggedUserJmeno {
    padding: 5px;
}

#lblLoggedUserJmeno {
    font-size: 16px;
    /*
    border-style:dashed;
    border-radius: 5px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.5);
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 2px;
    box-shadow: 0 3px 6px rgba(0, 63, 180, 0.5),0 3px 5px rgba(0, 32, 91,0.1);
    font-size: 20px;
    cursor:text;
    max-height: 56px;
    */
}

#btnOtevreniBocnihoPanelu {
    size: 100px;
    box-shadow: 0 10px 20px rgba(0, 32, 91, 0.1), 0 3px 6px rgba(0, 32, 91, 0.25);
}

#DelitkoUMenu {
    display: flex;
    width: 10px;
    height: 56px;

    color: var(--colorByTheme);
    font-size: 28px;
    text-shadow: none;
    font-weight: 100;

    padding-top: 25px;
}

#lblLoggedUserJmenoArrow {
    height: 10px;
    width: 10px;
}

#greeting {
    margin: 7px;
    padding: 5px;
}

#stavNastaveni {
    float: right;
}

#StavAlbinyZrusitOmezeni {
    font-weight: 500;
    background-color: rgba(9, 255, 0, 0.945);
    margin: 4px;
    color: white;
}

#StavAlbinyAno {
    margin-right: 0px;
    font-weight: 1500;
    background-color: rgb(255, 60, 0);
    margin: 4px;
    color: white;
}

.clStavAlbina1Radek {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
}

#StavAlbinyBezZmen {
    font-weight: 500;
    background-color: rgba(128, 128, 128, 0.75);
    margin: 4px;
    color: white;

}

#StavAlbinyBezZmen:hover,
#StavAlbinyAno:hover,
#StavAlbinyZrusitOmezeni:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: black;
    font-weight: bold;
    font-size: 14px;
}

.tooltip-container {
    position: relative;
}

.custom-button {
    width: 120px;
}


.tooltip-container {
    position: relative;
}

.custom-button {
    width: 120px;
}

.ToolTipStavAlbinaText {

    margin: -5px;
    padding: 5px;
    border-radius: 5px;
    border: 1px black;
    text-align: center;

}

/* Styly OTHERS */
.dx-overlay-content .dx-popup-normal .dx-resizable {
    top: 75px !important;
}

html {
    height: 100%;
}

nav {
    display: flex;
    width: 960px;
    justify-content: space-between;

    margin: 0 auto;
}

body {
    height: 100%;

    margin: 0px;
}

:checked {
    float: left;
}

.DxHtmlEditor {
    margin: 50x
}


/* Podmínky */

[pozadi="true"] {
    background-image: url("@/assets/images/uochb_login.png") !important;
    background-position: center;
    background-attachment: auto;
    background-color: currentColor;
}

[pozadi="false"] {
    background-image: none !important;
    background-color: transparent;
}

[iconactive="true"] {
    opacity: 1;
}


[ikonaStavu='1'] {
    background-image: url("@/assets/images/black_error2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 28px;
    background-position-x: 175px;
    padding: 0 40px 0 0;
    background-position-y: 18px;
}

[ikonaStavu='2'] {
    background-image: url("@/assets/images/black_error2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 28px;
    background-position-x: 175px;
    padding: 0 40px 0 0;
    background-position-y: 18px;
}

/*logoAlbinaDIVRIGHT_Orange';
        case '2':
          return 'logoAlbinaDIVRIGHT_Red'; */

[ohraniceniStavu='1'] {
    background-color: rgba(255, 153, 0, 0.788);
    border-radius: 5px;
}

[ohraniceniStavu='2'] {
    background-color: rgba(255, 0, 0, 0.788);
    border-radius: 5px;

}

/* return 'logoAlbinaDIV';
        case '1':
          return 'logoAlbinaDIV_Orange';
        case '2':
          return 'logoAlbinaDIV_Red'; */
[iconactive="false"] {
    opacity: 0.1;
}

#developer {
    color: red;
}

[notActive="true"] {
    opacity: 0.25
}


/* Pravidla, RULES

*/


@media print {
    .HlavniToolbar {
        visibility: hidden;
    }
}

@media (min-width: 950px) {
    #obrazekSettings {
        visibility: hidden;
    }
}

@media screen and (max-width: 320px) {
    #HlavnitoolbarID {
        height: 58px;
    }
}

@media screen and (max-width:700px) {
    #lblLoggedUser {
        visibility: hidden;
        width: 0px
    }
}

@media screen and (max-width: 550px) {

    #OKBASEMENU,
    #INTRAWEBMENU,
    #labelOdkazy {
        visibility: hidden;
        width: 0px;
        display: none;
    }

    #obrazekLogout {
        visibility: hidden;
        width: 00cm
    }

    #obrazekSettings {
        visibility: show;
    }

    #logoIMG {
        margin-right: 0px;
    }
}

@media screen and (min-height: 1000px) {}
</style>