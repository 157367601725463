<template>
    <div>
        <DxButton icon="edit" v-if="!Disabled" :text="LabelButton" height="32px"  :hint="defaultHint"
        type="default" styling-mode="contained" @click="popupEditFieldName = true;" />
                <DxPopup id="popup" v-model:visible="popupEditFieldName" :show-title="true" :drag-enabled="false" :hide-on-outside-click="false"
                            :show-close-button="true" :title="defaultTitle" width="500px" height="auto">
                            <div id="pop" style="display: flex; align-items: center;">
                                <DxTextBox
                                :id="Type"
                                :width="450"
                                height="45px"
                                :label="Label"
                                :maxLength="60"
                                placeholder=""
                                :value="DefaultName"
                                v-model:value="NewFieldName"
                                />
                                <DxButton
                                type="success"
                                icon="check"
                                :hint="Hint"
                                @click="setSelectedValue(Type)"
                                style="margin-left: 8px;"
                                />
                            </div>
                </DxPopup>
    </div>
  </template>
    
  <script>
  import notify from 'devextreme/ui/notify';
  export default {
    name: 'EditFieldName',
      components: {

    },
    props: {
        Disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      Type: {
        type: Boolean,
        required: true
      },
      FieldID: {
        type: Number,
        required: true,
      },
      DefaultName: {
        type: String,
        required: true,
      },
      Label: {
        type: String,
        required: false,
      },
      LabelButton: {
        type: String,
        required: false,
        default: ''
      },
      Hint:{
        type: String,
        required: false, 
      },
      Title: {
        type: String,
        required: false,
  
      },
    },
    watch: {
    },
    computed: {
        computedWidth() {
      // Nastaví minimální šířku na 100px a pro každý znak přidá 8px – hodnotu si můžete doladit podle vlastního fontu a stylu
      const minWidth = 200;
      const charWidth = 8;
      return Math.max(minWidth, this.DefaultName.length * charWidth) + 'px';
    },
      defaultHint() {
        if (this.Hint == 'none') {return '';}
        else {return this.Hint || ''}
      },
      
      defaultLabel() {
        if (this.Label == 'none') {return '';}
        else {return this.Label || ''}
      },
      defaultTitle() {
        if (this.Title == 'none') {return '';}
        else {return this.Title || ''}
      },

  
    },
    data() {
      return {
        popupEditFieldName: false,
        NewFieldName: '',
        heightButtonu:'100%',
        widthButtonu:'100%',
      };
  
    },
    mounted() {
      this.$log.debug('EditFieldName MOUNT')
    },
   
    methods: {
      setSelectedValue(Typ) {
        window.this.$log.debug(Typ);
        switch (Typ) {
            case '1': case 1:
            this.SetPersonalAssetName();
            //this.zverejnit = e.value
            break;
            case '2':
          //  this.SetPersonalAssetName();
            break;
        }
      }, 
      SetPersonalAssetName() {
        var asset = new window.Asset(window.channel, window.message);
        let self = this;
        this.$log.debug('volam setpersonalassetname')
        asset.SetPersonalAssetName(this.FieldID, this.NewFieldName,
          function (result) {
            self.$log.debug(result);
            if (result.ErrorNum.value != 0) {
              notify({
                message: result.ErrorTextCZ.value,
                position: {
                  my: 'center top',
                  at: 'center top',
  
                },
              }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
            }
            else {
              notify({
                message: self.$t('central.ZmenaNazvuMajetku'),
                position: {
                  my: 'center top',
                  at: 'center top',
  
                },
              }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
              
              var valueObject = {
                value: self.NewFieldName,
                error: result.ErrorNum.value,
                element: {
                  id: 'assetnamechanged'
                }
              };
              self.$emit('assetName-changed', valueObject);
            }
            self.popupEditFieldName = false
          }, window.this.ApiError());
      },
     
    },
  
  };
  </script>
  <style scoped>

  
  
  </style>
    