export default {
  "requestVyvojoveDilny-Oprava": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repair"])},
  "requestVyvojoveDilny-Popis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "requestVyvojoveDilny-InvC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory number"])},
  "requestVyvojoveDilny-Vytvoreni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new order"])},
  "requestVyvojoveDilny-PopisMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Description is required."])},
  "requestVyvojoveDilny-InvCMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Inventory number is required."])},
  "requestVyvojoveDilny": {
    "Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "OmezeniNa50Znaku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name length is a maximum of 50 characters. Please write the description in the Comment section"])}
  },
  "DochazkaNazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
  "Otocit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotate"])},
  "Predchozi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "Dalsi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "Nahled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview: "])},
  "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
  "granty-Zdroj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "granty-Rozliseni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
  "granty-Popis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "granty-Vznik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "granty-Castka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "granty-Faktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
  "granty-Promenna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "granty-KartaVydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery card with PIN"])},
  "granty-Pokyny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funding instructions"])},
  "central-GrantNevybran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not selected"])},
  "granty-NascanovanyDne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanned on"])},
  "granty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grants"])},
  "granty-Vykaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant statement"])},
  "granty-Rozpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed"])},
  "granty-DatumVykazu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement date"])},
  "granty-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "granty-DetailPolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "granty-PrilozeneFaktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached documents"])},
  "konec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end"])},
  "menuAktualne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently"])},
  "menuAktualne-KmemuVyjadreni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required my action"])},
  "menuAktualne-PozadavkyZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee requests"])},
  "menuAktualne-SchvalenePozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved requests"])},
  "menuDokumenty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "menuGranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grants"])},
  "menuMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property/Assets"])},
  "menuSklad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
  "menuTelefony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phones"])},
  "menuTelefony-DuleziteKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important contacts"])},
  "menuTelefony-DuleziteKontakty-PodporaEIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support of EIS"])},
  "menuTelefony-OblibeneKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite contacts"])},
  "menuTelefony-OsobniKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal contacts"])},
  "menuVydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue slips"])},
  "pozadavky-Sledujici": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request watcher:"])},
  "pozadavky-VyplnteKomentarKeSchvalovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill the reason of return or deny."])},
  "pozadavky-PozadavekSchovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request is now hidden in News tab."])},
  "pozadavky-NemateOpravneniObjednavat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permission to create orders."])},
  "pozadavky-NelzeUvestPozadavekKeSchvaleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request couldn´t be set to state 'for approve', while having no items."])},
  "pozadavky-PozadavekSchvalen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request approved."])},
  "pozadavky-PozadavekVracen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request returned."])},
  "pozadavky-PozadavekZamitnut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request denied."])},
  "pozadavky-ObjednavkaPridanaPolozka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added/edited order item."])},
  "pozadavky-Grid-Sloupec-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "pozadavky-Grid-Sloupec-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "pozadavky-Grid-Sloupec-Typ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "pozadavky-Grid-Sloupec-Stav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "pozadavky-Grid-Sloupec-PoslZmena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last change"])},
  "pozadavky-Grid-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "pozadavky-Pozadavek-KmemuVyjadreni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required my action"])},
  "pozadavky-Pozadavek-PozadavkyZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee requests"])},
  "pozadavky-Pozadavek-SchvalenePozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved requests by me"])},
  "pozadavky-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "pozadavky-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "pozadavky-IDPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request ID"])},
  "pozadavky-Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "pozadavky-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more records"])},
  "pozadavky-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "pozadavky-NenalezenyZadneZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<No records found>"])},
  "pozadavky-ObjednatOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier"])},
  "pozadavky-ObjednavkaPresActivu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an Order from ACTIVA.CZ"])},
  "pozadavky-PosledniZmena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last change"])},
  "pozadavky-Pozadavek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
  "pozadavky-Pres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via"])},
  "pozadavky-PretahneteSemZahlavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag a column header here for a summary."])},
  "pozadavky-Prilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments:"])},
  "pozadavky-Stav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "pozadavky-StavPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request state"])},
  "pozadavky-Typ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "pozadavky-TypPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request type"])},
  "pozadavky-VybratSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a files"])},
  "pozadavky-VytvoreniNovehoPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new request"])},
  "pozadavky-VytvoreniObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an order"])},
  "pozadavky-ZalozitVyvojoveDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a production order"])},
  "pozadavky-ZalozeniObjednavkyZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new order (supply)"])},
  "pozadavky-ObjednavkaLibovolnyDodavatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any supplier"])},
  "pozadavky-ZalozitNovyPozadavek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "pozadavky-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "pozadavky-ZobrazeniPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View of requests"])},
  "pozadavky-ZpetNaPozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "pozadavky-DetailObjednavkyZasob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order detail (supply)"])},
  "pozadavky-Nazev:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content: "])},
  "pozadavky-Zamestnanec:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee: "])},
  "pozadavky-Stav:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State: "])},
  "pozadavky-Grant:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant: "])},
  "pozadavky-Dodavatel:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier: "])},
  "pozadavky-Popis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "pozadavky-KatalogCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog number"])},
  "pozadavky-TypObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order type"])},
  "pozadavky-Specifikace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
  "pozadavky-Schvalovani:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval: "])},
  "pozadavky-KomentarKeSchvalovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment to approval:"])},
  "pozadavky-SchvalProces:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval process: "])},
  "pozadavky-ObjednavkaZalozena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order created. "])},
  "pozadavky-PresmerovaniActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting to e-shop ACTIVA."])},
  "pozadavky-ObjednavkaZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order changes saved."])},
  "pozadavky-UvedenDoStavu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request state set to \""])},
  "pozadavky-StavZrusen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "pozadavky-StavKeSchvaleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For approve"])},
  "pozadavky-StavUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
  "pozadavky-VyrazeniMajetkuZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset dispose changes saved."])},
  "pozadavky-PrevodMajetkuZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset transfer changes saved."])},
  "pozadavky-PrevodMajetkuPridanMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset added to transfer."])},
  "pozadavky-PrevodMajetkuOdebranMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset removed from transfer."])},
  "pozadavky-VyvojoveDilnyZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved."])},
  "pozadavky-PrevodMajetkuZalozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset transfer created"])},
  "pozadavky-PrevodMajetkuUpraven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset changes saved"])},
  "pozadavky-MajetekPreveden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset transfered succesfully"])},
  "pozadavky-VyrazeniMajetkuZalozeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset dispose created"])},
  "pozadavky-Importovat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file"])},
  "pozadavky-Import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import items from Excel"])},
  "pozadavky-SablonaHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to download template."])},
  "pozadavky-Sablona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
  "pozadavky-SablonaNazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template_order_items_albina.xlsx"])},
  "pozadavky-VybratSoubor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file"])},
  "pozadavky-Zarazeni:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclusion: "])},
  "pozadavky-DatumSplatnosti:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date: "])},
  "pozadavky-DatumVystaveni:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of issue: "])},
  "pozadavky-DatumZaplaceni:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of payment: "])},
  "pozadavky-DatumPrijeti:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of acceptance: "])},
  "pozadavky-Cislo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number: "])},
  "pozadavky-Objednavka:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order: "])},
  "pozadavky-CenaV:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price in "])},
  "pozadavky-CenaCelkemCZK:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price (CZK): "])},
  "pozadavky-CisloUctu:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number: "])},
  "pozadavky-TypPlatby:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of payment: "])},
  "pozadavky-Mena:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency: "])},
  "pozadavky-DetailPozadavkuDoVyvojDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request detail for development workshops"])},
  "pozadavky-Popis:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description: "])},
  "pozadavky-Majetek:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset: "])},
  "pozadavky-DetailPrevoduMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail of asset transfer"])},
  "pozadavky-PrevodNa:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to:"])},
  "pozadavky-PrevadenyMajetek:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset transferred: "])},
  "pozadavky-InvC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inv. number"])},
  "pozadavky-Zarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
  "pozadavky-PorizovaciCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price"])},
  "pozadavky-DetailVyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail of asset disposal "])},
  "pozadavky-Duvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
  "pozadavky-Zpusob:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form of disposal: "])},
  "pozadavky-VyrazovanyMajetek:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposed asset: "])},
  "pozadavky-TypID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TypeID"])},
  "pozadavky-StavID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateID"])},
  "pozadavky-ZalozeniPozadavkuNaVyvojoveDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating production order"])},
  "pozadavky-ObjednavkaPresVyvojoveDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order (via Production):"])},
  "pozadavky-Uroven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". level"])},
  "pozadavky-Schvalovatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". approver"])},
  "pozadavky-ZalozitNovyPozadavekPodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create copy of this request"])},
  "pozadavky-Popisek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To search all records, click Load All"])},
  "pozadavky-HodnotaMezi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value not in range"])},
  "telefony-EditovatDuleziteKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Important contacts"])},
  "telefony-Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "telefony-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search the entire phone book"])},
  "telefony-IdZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID employee"])},
  "telefony-Pritomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present"])},
  "telefony-Jmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "telefony-KategorieZobrazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display category"])},
  "telefony-Misto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
  "telefony-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more records"])},
  "telefony-NenalezenyZadneZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<No records found>"])},
  "telefony-PretahneteSemZahlavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag a column header here for a summary."])},
  "telefony-Telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "telefony-TelefonniSeznam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone list"])},
  "telefony-DuleziteKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important contacts"])},
  "telefony-Ostatni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
  "telefony-MeOddeleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My co-workers"])},
  "telefony-OblibeneKontakty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite contacts"])},
  "telefony-SkupinaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group needs to be filled."])},
  "telefony-Skupina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "telefony-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "telefony-ZamestnanecMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee needs to be filled."])},
  "telefony-PridatDoSkupiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to group"])},
  "telefony": {
    "OdebratZOblibenych": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím odeberete z oblíbených"])},
    "PridatDoOblibenych": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím přidáte do oblíbených"])},
    "Aktivni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přítomen"])},
    "NeAktivni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřítomen"])}
  },
  "topToolBar-Jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "topToolBar-Jazyk-CS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech (CZ)"])},
  "topToolBar-Jazyk-EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (EN)"])},
  "topToolBar-Odhlasit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "topToolBar-PrihlasenyUzivatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently logged in:"])},
  "topToolBar-Vzhled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])},
  "topToolBar": {
    "connectedAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API URL:"])}
  },
  "majetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
  "majetek-PrilozenePrilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments in the asset register"])},
  "majetek-VlastniPrilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal attachments"])},
  "majetek-PrilohyMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset attachments"])},
  "majetek-Filtr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total assets of all subordinates"])},
  "majetek-Pohyb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset movement"])},
  "majetek-PohybDatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "majetek-PohybDoklad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
  "majetek-PohybCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "majetek-PohybPopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "majetek-PohybPuvodniZamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous employee"])},
  "majetek-PohybNaZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New employee"])},
  "majetek-MajetekPohyby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset movements"])},
  "majetek-DatumZarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placed"])},
  "majetek-Mistnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
  "majetek-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "majetek-DokladZarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doklad zařazení"])},
  "majetek-PrevodMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset transfer"])},
  "majetek-VyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset disposal"])},
  "majetek-MajetekSoucastiPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset is currently present in other active request."])},
  "majetek-MajetekNelzeVyradit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset can't be disposed of, because it is present in other active request."])},
  "majetek-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "majetek-PretahneteSemZahlavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag a column header here for a summary."])},
  "majetek-InvCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory number"])},
  "majetek-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "majetek-PresmerovaniDoVyrazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset for disposal:"])},
  "majetek-Vyrazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposal"])},
  "majetek-Zarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of enlistment"])},
  "majetek-PorizovaciCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price"])},
  "majetek-Blokovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked*"])},
  "majetek-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more records"])},
  "majetek-DetailMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset detail: "])},
  "majetek-EvidovanoNaZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered on employee:"])},
  "majetek-InventarniCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory number"])},
  "majetek-VyrobniCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number"])},
  "majetek-NachaziSe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Located"])},
  "majetek-DatumPorizeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase date"])},
  "majetek-Obor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
  "majetek-Skupina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "majetek-Stredisko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "majetek-Zarazeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of enlistment"])},
  "majetek-ZustatkovaCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance price"])},
  "majetek-Umisteni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location place"])},
  "majetek-Poznamka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "majetek-Popisek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For asset transfer/dispose go to requests."])},
  "majetek-VlastniNazevHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit custom asset name"])},
  "majetek-VlastniNazevLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal asset name"])},
  "majetek-VlastniNazevEditace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing of a personal asset name"])},
  "sklad-Sklad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
  "sklad-PouzeNenulove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Only available"])},
  "sklad-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "sklad-PretahneteSemZahlavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag a column header here for a summary."])},
  "sklad-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more records"])},
  "sklad-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "sklad-Pocet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
  "sklad-Jednotka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "sklad-CenaOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price From*"])},
  "sklad-CenaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price To*"])},
  "sklad": {
    "nadpisSelectBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
    "TypPohybu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movement Type"])}
  },
  "majetekPrevod-PrevodMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset transfer: "])},
  "majetekPrevod-VratitSeZpet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "majetekPrevod-Pres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via"])},
  "majetekPrevod-PresMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Via must be filled."])},
  "majetekPrevod-NaZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To employee"])},
  "majetekPrevod-ZamestnanecMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Employee must be filled."])},
  "majetekPrevod-VybratSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose files"])},
  "majetekPrevod-Nahrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "majetekPrevod-NeboPretahneteZde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag here"])},
  "majetekPrevod-SouborNahran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File uploaded"])},
  "majetekPrevod-SouborSeNepodariloNahrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File was not uploaded."])},
  "majetekPrevod-Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "majetekPrevod-VytvoreniPrevoduMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating asset transfer"])},
  "majetekPrevod-ZalozeniPrevoduMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create asset transfer"])},
  "majetekPrevod-ZapsatZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write changes"])},
  "majetekPrevod-PrevadenyMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfered assets"])},
  "majetekPrevod-NenalezenyZadneZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<No records founds>"])},
  "majetekPrevod-InvCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory number"])},
  "majetekPrevod-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "majetekPrevod-Zarazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of enlistment"])},
  "majetekPrevod-PorizovaciCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price"])},
  "majetekPrevod-InterniId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal ID"])},
  "majetekPrevod-Vybran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
  "majetekPrevod-Blokovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked*"])},
  "majetekPrevod-Upravit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "majetekPrevod-Potvrdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "majetekPrevod-Zrusit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "majetekPrevod-Smazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "majetekPrevod-Varovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "majetekPrevod-OpravduSiPrejeteSmazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this request?"])},
  "majetekPrevod-PretahneteSemProSouhrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag header here for summary"])},
  "majetekPrevod-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "majetekVyrazeni-VyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset disposal: "])},
  "majetekVyrazeni-VratitSeZpet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "majetekVyrazeni-Pres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via"])},
  "majetekVyrazeni-Ano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "majetekVyrazeni-Ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "majetekVyrazeni-OpravduSiPrejeteSmazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this request?"])},
  "majetekVyrazeni-ZalozeniVyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create asset disposal"])},
  "majetekVyrazeni-PresMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Via must be filled."])},
  "majetekVyrazeni-VyrazovanyMajetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposed asset"])},
  "majetekVyrazeni-VyrazMajetekMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Disposed asset must be filled."])},
  "majetekVyrazeni-ZpusobVyrazeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposal method"])},
  "majetekVyrazeni-ZpusobVyrazeniMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposal method must be filled."])},
  "majetekVyrazeni-Duvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
  "majetekVyrazeni-DuvodMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Reason must be filled."])},
  "majetekVyrazeni-VybratSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose files"])},
  "majetekVyrazeni-Nahrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "majetekVyrazeni-NeboPretahneteZde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag here"])},
  "majetekVyrazeni-SouborNahran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File uploaded."])},
  "majetekVyrazeni-SouborSeNenahral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File was not uploaded."])},
  "majetekVyrazeni-Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "majetekVyrazeni-VytvoreniVyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create asset disposal"])},
  "majetekVyrazeni-ZapsatZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write changes"])},
  "Smlouvy-Cjednaci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference number (IOCB)"])},
  "Smlouvy-VytvoreniSmluv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create contract cover letter"])},
  "Smlouvy-PruvodniList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract cover letter: "])},
  "Smlouvy-NazevSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract name"])},
  "Smlouvy-Protistrana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counterparty"])},
  "Smlouvy-PredmetSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract subject"])},
  "Smlouvy-FinancniPlneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract value (CZK)"])},
  "Smlouvy-TypSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract type"])},
  "Smlouvy-Oblast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
  "Smlouvy-Referent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "Smlouvy-Podpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I undertake to send the signed contract to the counterparty or to arrange the counterparty's signature myself."])},
  "Smlouvy-SmlouvyZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract changes saved."])},
  "Smlouvy-SmlouvaZaložena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract cover letter created."])},
  "Smlouvy-ZalozitPruvodniList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create contract cover letter"])},
  "Smlouvy-VyplnenNazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract name must be filled."])},
  "Smlouvy-VyplnenPredmet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract subject must be filled."])},
  "Smlouvy-VyplnenTypSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract type must be filled."])},
  "Smlouvy-VyplnenaOblast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract region must be filled."])},
  "Smlouvy-VyplnenoFinancniPlneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract value must be filled."])},
  "Smlouvy-FyzickyPodpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contract will be physically signed by the Director - deliver the printed contract form to the Legal Department"])},
  "Smlouvy": {
    "TextPodpisReditelANO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver the printed contract to the Legal Department."])},
    "TextPodpisReditelNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Director affix an e-signature to the attached document."])},
    "ElektronickyPodpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Director will sign the attached contract electronically"])},
    "MusiteZvolitTypPodpisu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must choose how to sign the contract"])},
    "ZpusobPodpisu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method of signing the contract"])},
    "PrilohyKPodpisu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments for signature:"])},
    "PrilohyBezPodpisu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other attachments:"])}
  },
  "objednavkaPresKnihovnu-OpravduSiPrejeteSmazatPozadavek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this request?"])},
  "objednavkaPresKnihovnu-ZalozeniObjPresKnihovnu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating library order"])},
  "objednavkaPresKnihovnu-ObjPresKnihovnu:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order (via library):"])},
  "sklad-vydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue slips"])},
  "sklad-PouzeMeVydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only personal"])},
  "sklad-Id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "sklad-Datum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "sklad-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "sklad-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "sklad-Misto:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place: "])},
  "sklad-Zamestnanec:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee: "])},
  "sklad-Grant:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant: "])},
  "sklad-Informace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "sklad-Cislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "dotazovac-ParametryDotazu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report parameters: "])},
  "dotazovac-Zobraz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "dotazovac": {
    "dotazovac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "Dotazu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of Report"])},
    "Dotaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
  },
  "central-OdebratSledujiciho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove request watcher"])},
  "central-SledujiciZmenen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request watcher added."])},
  "central-EditovatSledujiciho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request watcher editing"])},
  "central-OtevreniEkoisSigneru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the validate button to be redirected to the EkoIS Signer application."])},
  "central-OznacitVse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "central-Prilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
  "central-PoleMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field must be filled."])},
  "central-NepodariloSeStahnout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download error."])},
  "central-Skryt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "central-GrantZmenen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant change saved."])},
  "central-EditovatGrantObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant editing"])},
  "central-EditovatVlastnika": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner editing"])},
  "central-VlastnikZmenen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request owner changed."])},
  "central-ZmenaGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change grant"])},
  "central-Dodani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent delivery: "])},
  "central-TerminDodani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of days"])},
  "central-UrgentniDodani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Delivery time required (optional)"])},
  "central-UrgentniDodaniTypy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "central-KontaktNaPodporu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support contacts:"])},
  "central-ZkusitZnovu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
  "central-StrankuSeNepodariloNacist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page could not be reached"])},
  "central-Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start typing..."])},
  "central-Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "central-ZrusitFiltr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filter"])},
  "central-VytvoritFiltr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create filter"])},
  "central-FiltrVysvetlivka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable the filter"])},
  "central-PolozkyObjednavky:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order items:"])},
  "central-ExportVseExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export all data to Excel"])},
  "central-ExportPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to PDF"])},
  "central-Vsechny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "central-PretahneteSemProSouhrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag a column header here for a summary."])},
  "central-Hledat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "central-NacistDalsiZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more records"])},
  "central-NacistVse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load all"])},
  "central-NenalezenyZadneZaznamy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<No records found>"])},
  "central-NenalezenyZadnePolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<No items found>"])},
  "central-KatalogNeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Catalog is not available>"])},
  "central-Nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "central-Pocet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "central-PocetMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Amount must be a number."])},
  "central-PocetMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Amount must be filled."])},
  "central-Jednotka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure unit"])},
  "central-Cena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "central-Potvrdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "central-Zrusit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "central-ZrusitUpravy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel changes"])},
  "central-Upravit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "central-Ulozit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "central-Smazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "central-Pridat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add item"])},
  "central-Varovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "central-Pres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via"])},
  "central-Ano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "central-Ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "central-VratitSeZpet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "central-VybratSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose files"])},
  "central-Nahrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "central-NeboPretahneteZde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag here"])},
  "central-SouborNahran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File uploaded."])},
  "central-SouborSeNenahral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File was not uploaded."])},
  "central-OpravduSiPrejeteSmazat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this request?"])},
  "central-OpravduSiPrejeteSmazatZaznam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this entry?"])},
  "central-DatumPosledniZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated"])},
  "central-PresMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Via must be filled."])},
  "central-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "central-GrantPovinny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant*"])},
  "central-GrantMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Grant must be filled."])},
  "central-Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "central-ObjednatOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order from*"])},
  "central-ZapsatZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "central-NenalezenyZadneSoubory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No files found"])},
  "central-Popis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "central-PopisMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ The description must be filled."])},
  "central-TypMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ The document type must be filled."])},
  "central-KatC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cat. n."])},
  "central-KatalogoveCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog number"])},
  "central-Zakazka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "central-KMajetkuC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To asset inv. no."])},
  "central-KMajetkuCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To asset inv. number"])},
  "central-Specifikace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifications"])},
  "central-SpecifikaceMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ The specification must be filled"])},
  "central-JednotkaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ The unit must be filled"])},
  "central-Cena/Jednotka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price/Unit"])},
  "central-CenaMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Price must be a number."])},
  "central-FormaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ The form must be filled."])},
  "central-CenaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ The price must be filled."])},
  "central-Mena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "central-MenaMusiBytVyplnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ The currency must be filled."])},
  "central-Celkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In total CZK"])},
  "central-CZK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CZK"])},
  "central-KonecnaCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final price CZK"])},
  "central-CelkemMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ In total must be a number."])},
  "central-CenaZaJednotku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per unit"])},
  "central-OdhadCenaZaJednotku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated price per unit"])},
  "central-Jednotka2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "central-TypObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Type"])},
  "central-Zamitnout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
  "central-Vratit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
  "central-Schvalit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
  "central-Editovat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "central-ElPodpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic signature"])},
  "central-DeponovanoU:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposited at"])},
  "central-Titul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "central-TitulMusiBytVyplnen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Title must be filled."])},
  "central-Vydavatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
  "central-Autori": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authors"])},
  "central-Vydani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition"])},
  "central-VydaniMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Edition must be a number."])},
  "central-VydaniMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Edition must be filled."])},
  "central-Rok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "central-RokMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Year must be a number."])},
  "central-RokMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["☼ Year must be filled."])},
  "central-Forma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
  "central-ISBN/ISSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN/ISSN/DOI"])},
  "central-DetailFaktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice detail"])},
  "central-DetailSmlouvy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract cover letter detail"])},
  "central-ZalozeniObjPresZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating order to supply"])},
  "central-ObjPresZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order (via supply):"])},
  "central-SumaCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price in CZK:"])},
  "central-KonecnaCenaCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final price in CZK"])},
  "central-PolozekCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items in total:"])},
  "central-Napoveda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hint"])},
  "central-Tisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
  "central-TypDokumentu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "central-funkcni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website is functioning properly :)"])},
  "central_stavalbinyCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of Albina in Czech"])},
  "central_stavalbinyEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of Albina in English"])},
  "central_ZmenaStavuAlbiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of the actual state of Albina"])},
  "central_ImportDat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing data..."])},
  "central-Texts-A-saveRowChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "central-Texts-A-cancelRowChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "central-Texts-A-deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "central-Texts-A-confirmDeleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "central-Texts-A-confirmDeleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this entry?"])},
  "central-Vyberte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
  "central-VyberSloupce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose column"])},
  "central-PretahneteSemSloupecProZmizeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag a column here to hide it"])},
  "central-Upozorneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
  "central-PridatZaznam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add item"])},
  "central-ObnovitPuvodniRozlozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset grid layout"])},
  "central-PridatClanek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add article"])},
  "central-DetailPozadavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request detail"])},
  "central-UpravitNovinku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit article"])},
  "central-ZobrazitArchivniPozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" incl. archive"])},
  "central-ZacnetePsat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start typing..."])},
  "central": {
    "Aktualizace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albína version update"])},
    "ZmenaNazvuMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal asset name changed."])},
    "CenaOdhad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original estimate"])},
    "CenaOdhadJednotka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "CenaVKc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price in CZK"])},
    "Mena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "VyplnteDuvodDoKomentare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill the reason into comment area"])},
    "Faktura": {
      "ZobrazitPolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Items"])},
      "SkrytPolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Items"])}
    },
    "pagingInfoStrana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
    "pagingInfoCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "pagingInfoPolozky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["items"])},
    "Ano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "Ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsuccessful"])},
    "KomentarEditace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment could be edited only in editing of the current request."])},
    "EditaceGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant editing"])},
    "Skoleni": {
      "Skoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
      "NazevSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Name"])},
      "Uspesnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "DatumSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Date"])},
      "DatumExpirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date"])},
      "Faktura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
      "Odkaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "BezFaktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without Invoice"])},
      "MusiBytVyplnenPopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training must be selected"])},
      "MusiBytVyplnenDatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training date must be filled in"])},
      "MusiBytVyplnenDatumExpirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date must be filled in"])},
      "PridatSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Training"])},
      "PouzeAktualni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only valid"])},
      "Filtr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "UzivatelskyVytvorene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User created"])},
      "Globalni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided by suppliers"])},
      "TypSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Type"])},
      "Expirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
      "BezExpirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not expiring"])},
      "PrilohyKeSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Attachments"])},
      "Prilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
      "ZobrazitPrilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Attachments"])},
      "PridatPrilohy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Attachments"])}
    },
    "Grouping": {
      "groupByThisColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group by This Column"])},
      "groupContinuedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continued from the previous page"])},
      "groupContinuesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continues on the next page"])},
      "ungroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungroup"])},
      "ungroupAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungroup All"])}
    },
    "Sorting": {
      "ascendingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort Ascending"])},
      "clearText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Sorting"])},
      "descendingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort Descending"])}
    },
    "ColumnFixing": {
      "fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix"])},
      "leftPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the left"])},
      "rightPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the right"])},
      "unfix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfix"])}
    },
    "Napovedy": {
      "FormatHTTPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the link in the format as follows: see example: https://intraweb.uochb.cas.cz/"])}
    },
    "textZkopirovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text copied to clipboard:"])},
    "NovaVerzeAlbiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new version of Albina is available, click here to update."])},
    "prepnoutDoCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit na češtinu."])},
    "prepnoutDoAJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to English"])},
    "otevritPostranniMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open main menu"])},
    "zavritPostranniMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close main menu"])},
    "otevritNastaveni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open settings"])},
    "zavritNastaveni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close settings"])}
  },
  "clanky": {
    "Manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual:"])},
    "seznamFciAlbiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of features and where to find them in new Albina"])},
    "objednavkaZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating Order"])},
    "objednavkaKnihovna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating Order (library)"])},
    "objednavkaVyvojovky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating Order (production)"])},
    "vyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset disposal"])},
    "prevodMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset transfer"])},
    "pracCestaSoukrVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage of a private vehicle during a business trip"])},
    "pruvodniListSmlouva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract cover letter"])},
    "platebniKarta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail of card payment"])},
    "osobniInformace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
    "granty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grants"])},
    "organizaceResitele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation (groups and inventory committees setup)"])},
    "sklady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
    "dotazovac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working with tables"])},
    "Pozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "hostKarta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for a guest access card"])},
    "pracSetkani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a lunch/dinner announcement"])}
  },
  "admin_ZmenaStavAlbiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMIN - Change of Albina State"])},
  "menu_nastaveni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "osobni_informace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
  "zmenit_vzhled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change theme"])},
  "zmenit_heslo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password change"])},
  "odhlasit_se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "language_EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English is your preffered language"])},
  "language_CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepněte do češtiny"])},
  "logged_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logged:"])},
  "vzhled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance:"])},
  "odkazy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Links:"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANGLICKY"])},
  "stavAlbinyCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[CZ] State in CZECH"])},
  "stavAlbinyEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[EN] State in ENGLISH"])},
  "login-OsobniCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal number"])},
  "login-Zapamatovat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember personal number"])},
  "login-NeboBysteRadeji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or would you rather?"])},
  "login-Heslo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "login-Prihlasit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
  "login-Rozcestnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guidepost"])},
  "login-OKBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKbase"])},
  "login-ZapamatovatSi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember personal number"])},
  "login-OsobniCisloMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal number is required."])},
  "login-OsobniCisloMusiBytCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal number can't contain letters or other characters."])},
  "login-HesloMusiBytVyplneno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required."])},
  "polozky-zalozeni-ZalozeniObjednavkyPresZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create request through supply"])},
  "polozky-zalozeni-VytvoritObjednavku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create order"])},
  "polozky-zalozeni-PrejitNaActivaCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the ACTIVA.cz e-shop"])},
  "polozky-zalozeni-ObjednavkaActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order from ACTIVA.cz (Office Supplies)"])},
  "pozadavky-ObjednavkaPresZasobovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order (via supply):"])},
  "pozadavky-Objednavka-PolozkyObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order items"])},
  "pozadavky-PozadavekZkopirovan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request has been copied successfully."])},
  "organizace-Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "organizace-Oddeleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "organizace-Vedouci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant leader"])},
  "organizace-Zastupce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deputy leader of grant"])},
  "organizace-Pritomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At work"])},
  "organizace-Nepritomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not at work"])},
  "organizace-PlatnostOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])},
  "organizace-PlatnostDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
  "organizace-RozbalitVse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand all"])},
  "organizace-SbalitVse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse all"])},
  "organizace-ResitelskeSkupiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups setup"])},
  "organizace-NastaveniResitelskychTymu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups setup"])},
  "organizace-Skupina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "organizace-Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "organizace-PridatDoSkupiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to group"])},
  "organizace-ZamestnanecPridanDoResitelskeSkupiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee added to Group"])},
  "organizace-Moje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My units/grants"])},
  "organizace-Cela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisational structure"])},
  "organizace-O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order "])},
  "organizace-P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View "])},
  "organizace-PravoObjednavat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the privilege of ordering"])},
  "organizace-PravoProhlizet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the privilege of viewing"])},
  "organizace": {
    "VlastniNazevZmenen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal funding name changed."])},
    "PopisGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal funding name"])},
    "VyplntePopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in personal funding name"])},
    "ZmenaPopisuGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm personal funding name change."])},
    "ZavritEditaciPopisuGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close personal funding name editing."])},
    "OtevritEditaciPopisuGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open personal funding name editing."])},
    "NekompletniKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing members of the inventory committee."])},
    "PridatDoKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a person to the inventory committee"])},
    "NastaveniInventarniKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up an inventory committee"])},
    "InventarniKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory committee"])},
    "OrganizacniStruktura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname and first name (ID)"])},
    "Odebrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "OtevritKopirovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the transfer of people between groups."])},
    "ZavritKopirovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the transfer of people between groups."])},
    "Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "Do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To  "])},
    "NakopirovatZDoVybranehoGrantu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy all employees to this grant"])},
    "ZTymu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from group."])},
    "ZInvKomise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from inventory committee."])},
    "ChybaKopirovani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the grants was not selected. Select the Default Grant and the Final Grant and repeat the action"])},
    "UspesnePrevedeniDoTymu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees were successfully copied into chosen team"])},
    "ZamestnanecJeJizPrirazen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This employee is already assigned"])}
  },
  "aktualne": {
    "Nastenka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "Aktualne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently"])},
    "DatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date From"])},
    "DatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date To"])},
    "DatumPotvrzeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date to confirm"])},
    "Komu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients"])},
    "IdClanku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article ID"])},
    "ClanekVCj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text in Czech"])},
    "ClanekVAj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text in English"])},
    "ZverejnitClanek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish article"])},
    "SmazatClanek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete article"])},
    "TypGlobalni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
    "TypOsobni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
    "PlaceholderCZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the content of your news in Czech here..."])},
    "PlaceholderEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the content of your news in English here..."])},
    "Typ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News type"])},
    "PridatNovinku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News editor"])},
    "Global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central"])},
    "Personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "Vyberte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select..."])},
    "PrijemceOdebran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient removed."])},
    "PrijemcePridan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient added."])},
    "NovinkaZverejnena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article published."])},
    "NovinkaSmazana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article deleted."])},
    "NovinkaPrectena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article marked as read."])},
    "NovinkaSkryta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article hidden."])},
    "NovinkaVracena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article returned."])},
    "Prectena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as read"])},
    "ZpravaPotvrdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining days to confirm reading of the article: "])},
    "ZpravaPromeskana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation of \"Mark as read\" missed! "])},
    "historieNovinek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News history"])},
    "pridatNovinku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add article"])}
  },
  "errory-Universal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong."])},
  "errory-Aktualne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page News something went wrong"])},
  "errory-Dotazovac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Reports something went wrong"])},
  "errory-Granty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Grants something went wrong"])},
  "errory-Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Login something went wrong"])},
  "errory-Majetek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Assets something went wrong"])},
  "errory-Objednavka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Order to Supply something went wrong"])},
  "errory-ObjednavkaKnihovna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Order to Library something went wrong"])},
  "errory-Organizace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Organisation something went wrong"])},
  "errory-Pozadavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Requests something went wrong"])},
  "errory-PrevodMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Asset transfer something went wrong"])},
  "errory-Sklad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Warehouse something went wrong"])},
  "errory-TelSeznam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Phone list something went wrong"])},
  "errory-Vydejky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Issue slips something went wrong"])},
  "errory-VyrazeniMajetku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Asset disposal something went wrong"])},
  "errory-VyvojoveDilny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page Production order something went wrong"])},
  "errory-ZkusitZnovu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
  "podpora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "podpora-NadpisFormular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support - mail form"])},
  "podpora-PopisProblemu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly describe the question or issue"])},
  "pracCesta": {
    "TiskSouhlasu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Usage Consent Form"])},
    "VytvoreniNadpis1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the trip and the driver - Creating a statement for the use of a private motor vehicle during a business trip."])},
    "VytvoreniNadpis2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecting a car - Creating a statement for the use of a private motor vehicle during a business trip."])},
    "Nadpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement for the use of a private motor vehicle during a business trip"])},
    "Zalozit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a statement for the use of a private motor vehicle during a business trip"])},
    "Zamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee (driver)"])},
    "InformaceCesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip information"])},
    "RidicPrukazCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver's license number"])},
    "SkoleniRidicuDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory driver training valid until"])},
    "LekarskaProhlidkaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical check (over 65 years old for use of private motor vehicle on a business trip)"])},
    "Cesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Trip"])},
    "Cil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
    "Ucel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose of the trip"])},
    "OdhadKm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated distance (round trip)"])},
    "KonaniOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration from"])},
    "KonaniDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "MotoroveVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor Vehicle"])},
    "SPZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Plate Number"])},
    "JinyVlastnikVozidla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other vehicle owner (if the employee or their spouse is not the owner)"])},
    "JinyVlastnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and surname of the other vehicle owner"])},
    "JinyVlastnikJmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the other vehicle owner"])},
    "JinyVlastnikPrijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname of the other vehicle owner"])},
    "VyrobniZnacka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturer's Brand"])},
    "ObjemValcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine Displacement"])},
    "DruhPohonneHmoty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Fuel"])},
    "Spotreba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel Consumption"])},
    "PlatnostTechnickeDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity of the technical inspection until"])},
    "Pojistovna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Company"])},
    "CisloPojistky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Policy Number"])},
    "PojistenoOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insured from"])},
    "PojistenoDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "PojisteniPovinne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory Liability Insurance"])},
    "PojisteniHavarijni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive Insurance"])},
    "VyplnRidicskyPrukaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the driver's license number"])},
    "VyplnSkoleniRidicuDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the validity of driver training"])},
    "VyplnteLekarskouProhlidkuDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the validity of the medical examination"])},
    "VyplnteCil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Destination"])},
    "VyplnteUcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Purpose of the trip"])},
    "VyplnteOdhadKm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Estimated number of kilometers (round trip)"])},
    "VyplnteKonani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the duration of the trip"])},
    "VyplnteSPZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the License Plate Number"])},
    "VyplnteVyrobniZnacku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Manufacturer's Brand"])},
    "VyplnteObjemValcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Engine Displacement"])},
    "VyplnteDruhPHM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the type of fuel"])},
    "VyplnteSpotrebu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in Fuel Consumption"])},
    "DatumPocatekPracCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date of the Business Trip"])},
    "DatumKonecPracCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date of the Business Trip"])},
    "VyplntePojistovnu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Insurance Company"])},
    "VyplnteCisloPojistky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Insurance Policy Number"])},
    "VyplntePojistenoOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insured from"])},
    "VyplntePojistenoDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "VyplntePojisteniPovinne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory Liability Insurance"])},
    "VyplntePojisteniHavarijni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive Insurance"])},
    "VPripadeZmenyAktualizujte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- verify the correctness and validity of this information"])},
    "UcelPouziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to § 157 of Act No. 262/2006 Coll. (Labor Code), the private motor vehicle is used for:"])},
    "UcelPouzitiNazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The private motor vehicle is used for"])},
    "VyplnteUcelPouziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the reason for using the private vehicle"])},
    "pracCestaZalozena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of a private vehicle for a business trip created"])},
    "pracCestaZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved for the business trip"])},
    "DuvodPotreby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for using a private vehicle"])},
    "zkontrolujteAktualnostDat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The validity of one of dates is not fulfilled. Please make sure that the entered data is in the future. Today's date cannot be entered."])},
    "JinyDuvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of other reasons"])},
    "VyplnteJinyDuvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill description of other reasons"])},
    "ulozeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request saved"])},
    "Ulozit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "ZkontrolujtePlatnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date is not valid at the time of the trip"])},
    "VyplnteDuvodPotreby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the reason for using a private vehicle"])}
  },
  "osobniInformace": {
    "osobniCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal number"])},
    "Nadpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
    "jmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "prijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "osobni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
    "skoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
    "osVozidla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Vehicles"])},
    "SkoleniRidicu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training of occasional drivers"])},
    "expirace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
    "datumSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training date"])},
    "datumSkoleniVyplnte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the training of occasional drivers date"])},
    "expiraceLekarskaProhlidka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical check (over 65 years old for use of private motor vehicle on a business trip)"])},
    "expiraceLekarskaProhlidkaVyplnte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in medical check (over 65 years old for use of private motor vehicle on a business trip)"])},
    "datumNarozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "ridicskyPrukaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver's license"])},
    "ridicskyPrukazVyplnte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the driver's license"])},
    "upravit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "zapsatzmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "zrusit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "skoleniObecne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Training"])},
    "skoleniIndividualni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual Training"])},
    "nelzeZadatDatumMinule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date you entered has already expired"])},
    "poleKEditaciOsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change: [Driver's License], [Medical Examination]"])},
    "poleKEditaciSkoleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change: [Date of driver training], [Expiry date of driver training]"])},
    "Odevy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workwear"])},
    "pripravujeme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["under construction"])},
    "tituly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titles"])},
    "ZadostVydeje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workwear claim request"])},
    "ZadostVydejeOznameni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification email of claim request were sent to the warehouse."])},
    "NelzeEditovat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item can´t be edited (automatically generated)."])}
  },
  "Odevy": {
    "nazev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "cetnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency of claim in months"])},
    "posledni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last claim"])},
    "dalsi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim possible from"])},
    "mamNarok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "pocet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of pieces"])},
    "prvni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
    "vyzvednete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pickup"])},
    "pockejte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Await"])}
  },
  "Vozidla": {
    "NoveVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New vehicle"])},
    "UlozitZmeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "SmazatVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete vehicle"])},
    "VozidloSmazano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle successfully deleted."])},
    "MotoroveVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor vehicle"])},
    "SPZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plate"])},
    "JinyVlastnikVozidla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner (if the owner is not the employee or his wife / her husband)"])},
    "JinyVlastnikJmenoPrijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other vehicle owner (Name and surname)"])},
    "JinyVlastnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner (if the owner is not the employee or his wife / her husband)"])},
    "JinyVlastnikJmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "JinyVlastnikPrijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "VyrobniZnacka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturer's brand"])},
    "ObjemValcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylinder volume"])},
    "DruhPohonneHmoty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of fuel"])},
    "Spotreba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel consumption"])},
    "PlatnostTechnickeDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity of the technical check"])},
    "Pojistovna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance company"])},
    "VyplntePojistovnu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the insurance company"])},
    "CisloPojistky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance policy number"])},
    "PojistenoOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insured from"])},
    "PojistenoDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "PojisteniPovinne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance for responsibility of operating a vehicle (third-party-only insurance of damage liability)"])},
    "PojisteniHavarijni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance for accident insurance"])},
    "ZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle changes successfully saved."])},
    "Zakladani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATING A NEW VEHICLE"])},
    "VozidloInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle information"])},
    "ZvolteAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a car"])},
    "UpravitInformaceOVozidle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit vehicle information"])},
    "ZalozitVozidlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add vehicle"])},
    "ZalozitZruseni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "VozidloPridano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle added"])},
    "PotvrditZvoleniAuta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm car selection"])},
    "PridanoDoCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car selected"])},
    "VyplnteSPZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the license plate"])},
    "VyplnteVyrobniZnacku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the manufacturer's brand"])},
    "VyplnteObjemValcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the cylinder volume"])},
    "VyplnteDruhPHM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the type of fuel"])},
    "VyplnteSpotrebu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in fuel consumption"])},
    "DatumPocatekPracCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date of the journey"])},
    "DatumKonecPracCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date of the journey"])},
    "VyplnteCisloPojistky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the insurance policy number"])},
    "VyplntePojistenoOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in insured from"])},
    "VyplntePojistenoDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in to"])},
    "VyplntePojisteniPovinne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in mandatory liability insurance"])},
    "VyplntePojisteniHavarijni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in collision insurance"])},
    "VyplnteSpotrebuZTechnickehoPrukazu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arithmetic average consumption from the data listed in the technical logbook of vehicle"])},
    "VyplntePojisteniOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in date from"])},
    "VyplntePojisteniDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in date to"])},
    "VyplnteDobuPojisteni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the insured interval"])},
    "VyplnteSTKDatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the validity of the technical check"])}
  },
  "pracSetkani": {
    "Nadpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request business lunch/dinner"])},
    "Podnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
    "DatumKonani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of the meeting"])},
    "Hoste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
    "HosteEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests (separate each person with the enter key)"])},
    "Zamestnanci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
    "TypPlatby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment type"])},
    "Cena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price (CZK)"])},
    "Spropitne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip (CZK)"])},
    "CenaCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price (CZK)"])},
    "VyplntePodnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant must be filled in."])},
    "VyplnteDatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date must be filled in."])},
    "VyplnteHosty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests must be filled in."])},
    "VyplnteZamestnance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees must be filled in."])},
    "VyplnteTypPlatby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment type must be filled in."])},
    "VyplnteCenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price must be filled in."])},
    "VyplnteCenuCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price must be filled in."])},
    "ValidaceCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price does not match the sum of the price and gratuity."])},
    "Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
    "Zadatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
    "zaUOCHB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For ÚOCHB"])},
    "CenaObedVecere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch/dinner Price"])},
    "FormaPlaceni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "SchvalProces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval process"])},
    "Ano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "Ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "Zalozit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "Zalozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of a request for a business lunch/dinner notification to the accounting department"])},
    "Zalozeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request business lunch/dinner created."])},
    "Ucel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose"])},
    "ZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes Saved."])},
    "DetailPracSetkani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business lunch/dinner detail"])},
    "VyplnteUcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose must be filled in."])},
    "SpropitneText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tip"])}
  },
  "Pozadavky": {
    "DoplnenaPole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields to fill."])},
    "DoplnenaPoleVPrubehu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields filled during the approval process "])},
    "CenaKZaplaceni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price to pay (in CZK)"])},
    "FinalniCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price (in CZK)"])},
    "SchvalovanaCastka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved Amount (in CZK)"])},
    "Platkarta": {
      "Dodavatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier"])},
      "NadpisGrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
      "OstatniGranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Grants"])},
      "VyplnteZemi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country must be filled in."])},
      "VyplnteTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip must be filled in."])},
      "VyplnteDatumKonani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip date must be filled in."])},
      "VyplnteDatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date from must be filled in."])},
      "VyplnteAdresu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address must be filled in."])},
      "VyplnteDatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date to must be filled in."])},
      "VyplnteZam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee must be filled in."])},
      "VyplnteMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency must be filled in."])},
      "VyplnteUplatneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim type must be filled in."])},
      "VyplnteDrzitele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder must be filled in."])},
      "VyplnteUcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment purpose must be filled in."])},
      "DetailPlatKarty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Card Notification Detail"])},
      "JmenoAPrijmeniDrzitele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder Name"])},
      "UcelPlatby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose"])},
      "PresnyNazevFirmy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact Company Name"])},
      "PlnaAdresaFirmy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Company Address"])},
      "Cena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "Celkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
      "Mena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
      "PlacenoDne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid On"])},
      "UhradaJeNaPracovniCeste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment is related to a business trip"])},
      "UhradaNeníNaPracovniCeste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment is not related to a business trip"])},
      "TypUhrady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Type"])},
      "Zeme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "DatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip from"])},
      "DatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip to"])},
      "PolID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item ID"])},
      "Grant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financed by"])},
      "TripID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Number"])},
      "TripTXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip"])},
      "ZamID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee ID"])},
      "ZamTXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
      "Zalozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of a request for a card payment notification to the accounting department"])},
      "Zalozena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for card payment notification created"])},
      "Nadpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Payment Notification"])},
      "Drzitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder"])},
      "Ucel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose"])},
      "Adresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Address"])},
      "VyplnteAdresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in Supplier's Address"])},
      "DatumKonani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Date"])},
      "TypPlatby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Type"])},
      "Datum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
      "VyplnteDatum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date must be filled in."])},
      "VyplnteCenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price must be filled in."])},
      "Komentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "SloupecCislo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Number"])},
      "SloupecZeme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "SloupecPopis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "SloupecKonaniOdDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event from - to"])},
      "SloupecZamestnanec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
      "SloupecCastka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "SloupecGrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
      "Uplatneni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim Type"])},
      "ZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes Saved"])},
      "SouhlasObsah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I declare that the reimbursement not related to the foreign trip has been properly discussed and approved according to Directive 3/2008."])},
      "Souhlas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent"])},
      "ValidaceCelkem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price is not equal to sum price of items"])},
      "KontrolaUlozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check that the record has actually been saved in the item table."])},
      "ValidaceSumyZakladani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total pricemust be higher than 0.00"])},
      "CelkemCena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "ValidaceDatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip to should be higher or equal to Trip from"])},
      "ValidaceDatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip from should be lower or equal to Trip to"])},
      "NeplatnaCestaOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip From is not valid"])},
      "NeplatnaCestaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip To is not valid"])},
      "CestaOdZaCestaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip from is further than Trip to"])},
      "okCestaDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip to is okay"])},
      "okCestaOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip from is okay"])},
      "CestaTrvani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip duration from-to"])},
      "FormaVlastnenehoDokladu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The original document are in electronic form."])},
      "vysvetlivkyKDokladu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>If you have the original documents <strong>in electronic form</strong>, please attach them here as attachments.</p><p>If you have the original documents <strong>in paper form</strong>, submit the request for approval without attachments. Further instructions will be provided via email after approval.</p>"])},
      "PrilohyNahrajiVDalsimKroku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original documents please upload as attachments in the following step."])},
      "ZdeNahrajteElektroDokument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here upload the original documents."])},
      "MojeCesty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned Business Trip"])}
    },
    "HostKarta": {
      "Zalozeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating an application for a guest access card (up to 3 months validity)"])},
      "Zalozena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application for a guest access card (up to 3 months validity) was created."])},
      "Nadpis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application for a guest access card (up to 3 months validity)"])},
      "Host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
      "Prijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
      "Jmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "Predcisli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix"])},
      "Telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "Adresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact address"])},
      "PlatnostOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required validity from"])},
      "PlatnostDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to (max. 3 months)"])},
      "Platnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity"])},
      "PocatekPlatnosti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
      "KonecPlatnosti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
      "Oddeleni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "Vedouci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader name"])},
      "Duvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose of stay (visit)"])},
      "Zalozit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create request"])},
      "VyplnteEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail must be filled in."])},
      "ZadejtePlatnyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid E-Mail."])},
      "VyplntePrijmeni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname must be filled in."])},
      "VyplnteJmeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name must be filled in."])},
      "VyplntePredcisli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix must be filled in."])},
      "VyplnteTelefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number must be filled in."])},
      "VyplnteAdresu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact address must be filled in."])},
      "VyplntePlatnost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity must be filled in."])},
      "VyplnteDuvod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose of stay (visit) must be filled in."])},
      "ZmenyUlozeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])},
      "JmenoHosta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
      "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request details for access card application for a long-term (max. 3 months) guests"])},
      "Zalozeno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for access card application for a long-term (max. 3 months) guests has been created."])},
      "ChybaPlatnostDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid validity"])},
      "UdajeHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest details"])},
      "UdajePobyt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay details"])}
    },
    "CisloObjednavky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order (processed by)"])},
    "CisloDodaci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery number"])},
    "CisloFaktury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
    "Skladem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Stock"])},
    "Odkaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "InventuryKPotvrzeni": {
      "PopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory list for confirmation"])},
      "Vlastnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
      "PrimarniSchvalovatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary approver"])},
      "SekundarniSchvalovatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary approver"])},
      "IfProblemContactPartA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of discrepancies in the inventory list of assets, please contact "])},
      "IfProblemContactPartB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the Assets Registering Department."])},
      "NalezenoSkenerem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found with scanner"])},
      "Majitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
      "Umisteni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "SchvalenimPotvrzujiText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By approving, I confirm that the physical inventory took place in my presence and that I have not concealed any assets."])},
      "Schvaleno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved by owner"])}
    }
  },
  "Validator": {
    "CenaMusiBytKladna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price must be a non-negative"])},
    "SpropitneMusiBytKladne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip must be a non-negative"])},
    "CenaMusiBytVyssiNezNula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price must be higher than 0."])},
    "VzdalenostMusiBytKladna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length estimation must be a non-negative"])}
  },
  "Prilohy": {
    "VelikostSouboru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum size of one attachment is 50 MB."])},
    "PovolenePripony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed attachment extensions: "])},
    "MaximalniPocetSouboru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum number of attachments is 10."])},
    "PrekrocenaMaxVelikost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload the following attachments due to exceeding the maximum size: "])},
    "NespravnaPripona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload these attachments due to incorrect extension: "])}
  },
  "Dochazka": {
    "DenniPrehled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
    "TydenniPrehled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
    "MesicniPrehled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "CelkemTyden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly total: "])},
    "CelkemMesic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly total: "])},
    "ViditelneHodiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displayed hours"])},
    "Tyden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
    "Mesic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "Udalost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "UdalostPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose..."])},
    "Poznamka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "DatumOd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "DatumDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "UdalostValidace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event must be filled."])}
  },
  "Vanoce": {
    "StastneAVesele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merry Christmas"])},
    "ZobrazitPadaniHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable snowflakes"])},
    "VypnoutPadaniHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable snowflakes"])}
  }
}