
<template>
  <DxScrollView height="90%" direction="vertical" showScrollbar="always">
    <div id="all">
  
        <DxPopup id="popup" v-model:visible="showModal" :show-title="true" :drag-enabled="false" :hide-on-outside-click="true"
          :show-close-button="true" :title="$t('telefony-EditovatDuleziteKontakty')" width="auto" height="auto">
          <div id="pop"> 
            <form action="your-action" @submit="onFormSubmit($event)">
              <DxSelectBox ref="selectbox2" :id="2" width='330px' height="45px" :label="$t('telefony-Skupina')" :data-source="groups" 
                @customItemCreating="customItemCreating($event,1)" display-expr="content"  placeholder="" :accept-custom-value="true"
                @value-changed="setSelectedValue" >
                <DxValidator>
                  <DxRequiredRule :message="$t('telefony-SkupinaMusiBytVyplnena')" />
                </DxValidator>
              </DxSelectBox>
              <br> 
              <div class="soubory" v-for="item in zam" :key="item">
                <DxButton id="close" icon="remove" text="" @click="RemoveImportantFunction(item.id)" />
                <label id={{item.id}}>{{item.content}}</label>
              </div>
              <div id="inner">
                <br>
                <br> 
                <div class="child">
                  <DxSelectBox :id="3" height="45px" width='330px' :label="$t('telefony-Zamestnanec')" :data-source="zamestnanci"
                    v-model="zamestnanec" display-expr="content" placeholder="" @value-changed="setSelectedValue($event)"
                    :search-enabled="true" @input="SearchZam" @opened="ClearValue" noDataText="" >
                    <DxValidator>
                      <DxRequiredRule :message="$t('telefony-ZamestnanecMusiBytVyplnen')" />
                    </DxValidator>
                  </DxSelectBox>
                </div>
                <div class="child">
                  <DxButton class="button1" id="add" height="auto" width="auto" type="default" styling-mode="contained"
                    :text="$t('telefony-PridatDoSkupiny')" :use-submit-behavior="true" />
                    <br>
                    <br>
                    <br> 
                </div>
              </div>          
            </form>
            
            </div>
        </DxPopup>
        <div id="posDivHorniCastTEL">
      <div id="container">
        <div id="inner">
          <div class="clNadpisAktualniStranky" height="50px">
            <h2 id="nadpisTelefony" class="nadpisH2Moduly prevent-select">{{$t('telefony-TelefonniSeznam')}}</h2>
        </div>
          <div id="horniListaTelSeznam" style="margin-left: 20px;">
            <div class="child"  >         
              <DxSelectBox ref="selectbox1" :id="1" width='auto'  :label="$t('telefony-KategorieZobrazeni')" :data-source="dataSource" 
                display-expr="content" placeholder="" :grouped="true" :value="zobrazeni" class="heightbtnEditImportant"
                @value-changed="setSelectedValue" :disabled="searched" :drop-down-options="selectBoxDropDownCategory" styling-mode="outlined" type="default">
              </DxSelectBox>
            </div>
            <div v-if="editImportant" class="child" >
              <DxButton id="buttonEditovatDuleziteKontakty" icon="edit" class="button1 buttonsDataGridHorniLista dx-button-text heightbtnEditImportant"  
               styling-mode="outlined" type="default" :text="$t('telefony-EditovatDuleziteKontakty')" :hint="$t('telefony-EditovatDuleziteKontakty')"
                @click="showModal=true" />
            </div>
        </div>
        </div>
      </div>
      </div>
     
      <hr class="hrModuly">
        <div class="bordersDataGridPozadavkyModuly">
        <DxDataGrid :column-min-width="25" ref="mygrid" id="DataGridTelSeznam" :data-source="rows" :showScrollbar="true" :allow-column-resizing="true"  column-resizing-mode="widget" :allow-column-reordering="true" 
        :column-auto-width="true" :hoverStateEnabled="true" :rowAlternationEnabled="true" :show-borders="true" :height="(vyskaZobrazitelna/1.5)" @initialized="MyGridInit"
          key-expr="zamid" @option-changed="SearchInput" :noDataText="$t('central-NenalezenyZadneZaznamy')" @cell-click="OnCellClick($event); "  @editor-preparing="GridEditorPreparing" 
          @exporting="onExporting" width="100%" @row-expanded="OnMasterDataOpen" @content-ready="onContentReady">
          <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/> <!--@row-click="($event);isDetailRowExpanded($event)"-->
          <DxExport
            :enabled="true"
            :formats="['xlsx']"
            :allow-export-selected-data="false"
            :texts="{exportAll: $t('central-ExportVseExcel')}"
            />
            <DxToolbar >
                                          <DxItem name="exportButton" location="after" locate-in-menu="auto"/>
                                          <DxItem location="after" locate-in-menu="auto" name="searchPanel" :visible="true" />
                                          <DxItem location="before" locate-in-menu="auto" name="groupPanel" />
                                          <DxItem locate-in-menu="always" widget="dxButton"  @click="GridLayoutReset">
                                            <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                            type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined"  />      
                                          </DxItem>
                                          
              </DxToolbar>    
          <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="GridTelSeznam"
          />
          <!--<DxColumn width="auto" data-field="active" cell-template="template-infobutton" caption="" :sort-index="1"/>
          <template #template-infobutton="{data}">
            <img  id="detailiconInfo" icon="info" width="100%"
              height="100%" :nothing="data" styling-mode="text" />
           
        </template>-->
          <DxColumn width="31px" data-field="active" :caption="$t('telefony-Pritomen')" cell-template="grid-active" :sort-index="2" :fixed="true" fixedPosition="left"/>
          <template #grid-active="{data}" >
              <img v-if="data.data.active==false" id="activeicon" src="@/assets/images/lochome.gif" width="16"
              height="16" v-bind:title="$t('telefony.NeAktivni')">
              <img v-else id="activeicon" src="@/assets/images/locwork.gif" width="16" height="16" v-bind:title="$t('telefony.Aktivni')">

          </template>
          
          <DxColumn :width="50" data-field="zamid" caption="Os. č." :visible="true" :sort-index="3" alignment="right" css-class="columnPhonesOverflow" cell-template="grid-osCislo"  />
          <template #grid-osCislo="{data}">
          <div class="cursorPointer columnPhonesOverflow" v-bind:title="$t('telefony.KliknutimZobraziteDetaily')">{{data.data.zamid}} </div>
          </template>
          <DxColumn data-field="name" :caption="$t('telefony-Jmeno')" :sort-index="4" cell-template="grid-name" />
          <template #grid-name="{data}">
          <div class="cursorPointer columnPhonesOverflow" v-bind:title="$t('telefony.KliknutimZobraziteDetaily')">{{data.data.name}} <img id="detailiconInfo" src="@/assets/images/personal_info/info.png" width="16"
              height="16" :nothing="data"  /></div>
          </template>
          <DxColumn :caption="$t('telefony-Telefon')" data-field="phone" cell-template="grid-phones" :sort-index="5" css-class="columnPhonesOverflow"/> 
         <template #grid-phones="{data}" >
          <div class="inlineFlexBox">
            <div class="inlineFlexBox" v-for="(phone, index) in data.data.phone" :key="index" > 
          <a class="oneRowMultiplePhones" :href="'tel:'+this.FormatMainNumberToCall(MainPhoneFormat(phone, 0),0)">
            <div class="cursorText columnPhonesOverflow oneRowMultiplePhones" v-html="this.FormatMainNumberToCall(this.MainPhoneFormat(phone,0), 1)"></div>
          </a>
          <span ><div v-if="((data.data.phone.length - index-1)!=0)">{{','+String.fromCharCode(160)}}</div></span> 
        </div>
        </div>
          <!--<a v-for :href="'tel:'+this.SelectMainNumberToCall(this.MainPhoneFormat(data.data.phone,0))">
            <div class="cursorText columnPhonesOverflow" v-html="this.MainPhoneFormat(data.data.phone,0)"></div>
          </a>-->
        </template>
          <!--<template #grid-phones="{data}" > 
              <div>test + {{ data.data.phone }}</div>
          </template>-->
          <!--<DxColumn data-field="room" :caption="$t('telefony-Misto')" width="200px" />-->
          <DxColumn data-field="email" :caption="$t('telefony-Email')"  width="250px" :sort-index="6" cell-template="grid-email"/>
          <template #grid-email="{data}">
          <div class="columnPhonesOverflow" v-html="this.htmlEmailAdresa(data.data.email)"></div>
          </template>
          <DxColumn :width="30" data-field="favorite" caption="" cell-template="grid-favorite" :sort-index="7" :fixed="true" fixedPosition="right"/>
          <template #grid-favorite="{data}">
            <img v-if="data.data.favorite==false" id="favoriteicon" src="@/assets/images/grey-star-icon.png" width="16"
              height="16" v-bind:title="$t('telefony.PridatDoOblibenych')">
            <img v-else-if="data.data.favorite==true" id="favoriteicon" src="@/assets/images/gold-star-icon.png" width="16" height="16" v-bind:title="$t('telefony.OdebratZOblibenych')">
            <span v-else ></span>
          </template>
          <DxMasterDetail
          :sort-index="-1"
            :enabled="false"
            :auto-expand-all="false"
            template="masterDetailTemplate"
        />
          <DxPaging :enabled="false" />
          <DxGroupPanel :visible="true" :empty-panel-text="$t('telefony-PretahneteSemZahlavi')" :context-menu-enabled="true"/>
          <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
          <DxSearchPanel :width="300" :visible="true" :placeholder="$t('telefony-Hledat')" />
          
        <template #masterDetailTemplate="{ data}"> 
            <div id="flexOsobniInformaceDetailMAIN">
              <div id="flexFotkaTituly">
              <img id="imgProfilovaFotkaDetail" :src="getImageUrl(data.key)">
              <span id="detailtitles" v-if="false">{{ this.foundItem[0].titles }}</span>
              </div>
              <DxDataGrid column-resizing-mode="widget" :allow-column-resizing="true" :column-auto-width="true"  :rowAlternationEnabled="true"
                            id="DatagridDetailKontakt" :data-source="foundItem" :show-borders="true" :max-height="vyskaZobrazitelna" width="100%" 
                             :noDataText="$t('central-NenalezenyZadneZaznamy')" :show-column-headers="false" ref="datagridDetail" @initialized="InitGridDetail" >
                          <DxScrolling mode="standard" />
                          <DxPaging :enabled="false"/>
                          <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                              
                                <DxColumn  data-field="phone" :caption="$t('Odevy.nazev')" :visible="true" cell-template="grid-phonesdetail"  width="auto" /> <!---->
                                <template #grid-phonesdetail="{data}" >
                                  <div class="cursorText columnPhonesOverflow"> 
                                      <div  v-if="data.data.phone.includes(this.predvolbaLinkaUOCHB)">
                                        <a :href="'tel:'+this.MainPhoneFormat(data.data.phone,1)">
                                          <span >{{this.PhoneLineFormatStart(data.data.phone)}}</span><span class="bold-text">{{ this.PhoneLineFormatEnd(data.data.phone) }}</span>
                                        </a>
                                      </div>
                                      <div v-else>
                                        <a :href="'tel:'+this.MainPhoneFormat(data.data.phone,1)">
                                          <span >{{this.PhoneLineFormatStart(data.data.phone)}}</span><span>{{ this.PhoneLineFormatEnd(data.data.phone) }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </template>
                                  <DxColumn class="columnPhonesOverflow" data-field="room" :caption="$t('Odevy.pocet')" :visible="true" alignment="left" width="250px">
                                  </DxColumn>
                    </DxDataGrid>
            </div>
        </template>
        </DxDataGrid>
        
        <br>
        <DxButton icon="chevrondown" width="auto" height="auto" type="button" :text="$t('central-NacistDalsiZaznamy')" class="button4 buttonsDataGridSpodniLista"
          v-on:click="Button4Click()" :disabled="disable" />
        <DxButton icon="arrowdown" width="auto" height="auto" type="button" :text="$t('central-NacistVse')" class="button4 buttonsDataGridSpodniLista"
          v-on:click="ButtonAllClick()" :disabled="disable" />
      </div>
      
    </div>
  
  </DxScrollView>
  </template>
  
  <script>
  
  //import NavigationList from '@/router/NavigationList.vue';
  /*
  import { Form } from "vee-validate";
  import * as Yup from "yup";
  import ValidationSelect from "@/components/ValidationSelect.vue";
  */
  
  import { Workbook } from 'exceljs';
  import { saveAs } from 'file-saver';
  import { exportDataGrid as exportDataGridToExcel} from 'devextreme/excel_exporter';
  import {DxExport, DxStateStoring, DxLoadPanel, DxToolbar, DxMasterDetail,DxSorting} from 'devextreme-vue/data-grid';
  import notify from 'devextreme/ui/notify';
  import DataSource from 'devextreme/data/data_source';
  //import { groupsData } from '@/data.js';
  import { isNull } from 'lodash';
  const dataSource = new DataSource({
    group: 'category',
    // ...
  });
  const groupsDataSource = new DataSource({
    store: {
      data: '',//groupsData,
      type: 'array',
      key: 'id',
    },
  });
  export default {
    name: 'TelSeznam',
  
    components: {
      DxExport,
      DxStateStoring,
      DxLoadPanel,
      DxToolbar,
      DxMasterDetail,
      DxSorting
    },
    computed: {
      dataGrid: function () {
        let self = this;
        return self.$refs.mygrid.instance;
      },
      selectBox1: function () {
        let self = this;
        return self.$refs.selectbox1.instance;
      },
      selectBox2: function () {
        let self = this;
        return self.$refs.selectbox2.instance;
      },
      datagridDetail: function () {
        let self = this;
        return self.$refs.datagridDetail.instance;
      }
    },
    data() {
  
      return {
        searched: false, 
        dataSource,
        zobrazeni: [{ "content": this.$t('telefony-OblibeneKontakty'), "id": 1, "category": "Ostatní" }, { "content": this.$t('telefony-MeOddeleni'), "id": 0, "category": "Ostatní" }],
        zobraz: { "id": 1, "content": "Oblíbené kontakty", "category": "Ostatní" },
        item: {},
        disable: false,
        page: 1,
        label3Color: "",
        label5Color: "",
        label6Color: "red",
        groups: [],
        group: [],
        employee_id: 0,
        search: "",
        mysearch: "",
        validModal1: false,
        validModal2: false,
        showModal: false,
        showModal2: false,
        zamestnanci: [],
        zamestnanec: [], 
        zam: [],
        msg: this.$t("telefony-TelefonniSeznam"),
        registration: {
          nazev: null,
          prevsearch: '-96985',
          timer: undefined,
          zaminput: 'x',
        },
        foundItem:[],
        editImportant:false,
        vyskaZobrazitelna:250,
        predvolbaLinkaUOCHB:'220 183',
        MountingData: true,
        needFocusToSearchPanel: false,
        columns: [
          {
            label: 'Zam_id',
            field: 'zamid',
            type: 'number',
            // tdClass: 'vgt-center-align',
            //  thClass: 'vgt-center-align',
  
          },
          {
            label: 'Jméno',
            field: 'name',
            //   thClass: 'vgt-center-align',
          },
  
          {
            label: 'Telefon',
            field: 'phone',
            type: 'number',
            //   thClass: 'vgt-center-align',
  
          },
          {
            label: 'Email',
            field: 'email',
            //   thClass: 'vgt-center-align',    
          },
          {
            label: '',
            field: 'favorite',
            type: 'number',
            //     thClass: 'vgt-center-align',
            tdClass: 'vgt-center-align',
  
          },
  
        ],
  
        rows: [],
        rowsDetails: [],
        selectBoxDropDownCategory: {
                minWidth: 250,
                minHeight: 0
            },
  
      };
    },
    created() {
      this.$log.debug(window.localStorage.getItem("telseznam-param"))
      this.$log.debug(this.$route.params.search)
    },
    unmounted() {
          window.removeEventListener("resize", this.myResizeEventHandler);
          },
    mounted() {
      
      window.addEventListener("resize", this.myResizeEventHandler);
      this.DrawerSelected()
      //window.this.$log.debug(window.localStorage.getItem("refresh-page"))
      // window.this.$log.debug(JSON.parse(window.localStorage.getItem("user")).Firstname.value+' '+JSON.parse(window.localStorage.getItem("user")).Surname.value)
      var search = '';
      switch (window.localStorage.getItem("telseznam-param")) {
        case '1': 
        //  window.localStorage.setItem("refresh-page", 'TelSeznam1')
        //  search = JSON.parse(window.localStorage.getItem("user")).Firstname.value + ' ' + JSON.parse(window.localStorage.getItem("user")).Surname.value
          search = ":CO-WORKER"
          this.x = 1
          break;
        case '2':
       //   window.localStorage.setItem("refresh-page", 'TelSeznam2')
          // search = JSON.parse(window.localStorage.getItem("user")).Firstname.value + ' ' + JSON.parse(window.localStorage.getItem("user")).Surname.value
          search = ":CO-WORKER"
  
          this.x = 1
          break;
        case '3':
        //  window.localStorage.setItem("refresh-page", 'TelSeznam3')
          search = ":FAVORITE";
  
          this.x = 0
          break;
      }
      window.this.$log.debug(search)
      //this.TableDataTel(search);
      this.TestEditImportant();
      this.ListImportantFunction();
      setTimeout(() => {
                      this.$nextTick(() => {
                          this.myResizeEventHandler();
              });}, 1000);

    },
    methods: {
      onContentReady(e) {
        this.$log.debug(' volam cont ready')
    if (this.needFocusToSearchPanel) {
      // Find the search panel input
      const searchPanelInput = e.component.element().querySelector('.dx-datagrid-search-panel input');
      if (searchPanelInput) {
        // Set focus to the input
        searchPanelInput.focus();
        // Move cursor to the end of the text
        const length = searchPanelInput.value.length;
        searchPanelInput.setSelectionRange(length, length);
      }
      // Reset the flag
      this.needFocusToSearchPanel = false;
    }
  },
      MyGridInit(e) {
            this.$log.debug(e)
            this.$log.debug('mygridinit')
            this.$log.debug(e.component.option('searchPanel.text'))
             setTimeout(() => {
                e.component.option('searchPanel.text', '')   
                this.TableDataTel(':FAVORITE')      
            }, 200);
            setTimeout(() => {
                this.MountingData=false     
            }, 1000);     
        },
      InitGridDetail(e){
        this.$log.debug(e)
        //this.$log.debug('ININININIT')
        this.$refs.datagridDetail.instance.getDataSource().reload();
      },
        GridEditorPreparing(e) {
            if (e.parentType == 'searchPanel' && e.value != '') {
              this.$log.debug(e)
            //  this.$log.debug('focus')
              window.searchpanel= e.editorElement
              e.component.focus(e.editorElement)
              const searchPanelElement = document.querySelector(".dx-datagrid-search-panel");
              if (document.getElementsByClassName('dx-theme-accent-as-background-color').length > 0) {
               // var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
                //var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
               // var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
               // var lightercolortheme = this.lightenColor(FillColorBarvaTheme, 100,1);
              //  var lightercolorthemeHeader = this.lightenColor(lightercolortheme, 0.25,2);
              }
            if (searchPanelElement) {
              searchPanelElement.style.backgroundColor = 'var(--colorByThemeLighterHeaders)'; // Změňte barvu podle potřeby
            } 
            }
      },
      myResizeEventHandler(e){
              this.$log.debug(e);
              this.handleMainViewChange();
            },
      handleMainViewChange(){
          //    console.log("handleMainViewChange");
                  let self = this;
                  var wholeView = document.getElementById("ScrollViewAPP");
                  var divTop = document.getElementById('posDivHorniCastTEL');
                  if (wholeView) {
              //            console.log(element);
                          var heightWholeView = wholeView.clientHeight;
                          var heightdivTop = divTop.clientHeight+10;
                          var vyslednaVyska=heightWholeView-heightdivTop;
                          self.vyskaZobrazitelna = vyslednaVyska;
                     //     console.log("handleMainViewChange height:", vyslednaVyska,heightWholeView,heightdivTop);
  }
              
          },
      onExporting(e) {
        window.this.$log.debug('test uvnitr onexporting')
      if (e.format==="xlsx") {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet("Companies");
          exportDataGridToExcel({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            customizeCell: function(options) {
                      const excelCell = options;
                      excelCell.font = { name: 'Arial', size: 12 };
                      excelCell.alignment = { horizontal: 'left' };
                  } 
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              const datum= new Date();
              saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                this.msg+" "+datum.toISOString('cs-CZ').substring(5, 10)+" "+datum.toTimeString('cs-CZ').substring(0, 9)+".xlsx"
              );
            });
          });
          e.cancel = true;
        }
      
      
      
      },
      getImageUrl(id) {
        window.this.$log.debug('THIS WE NEED ') 
        window.this.$log.debug(id) 
  const imageUrl = `https://intraweb.uochb.cas.cz/albina/picture/index.php?id=${id}`;

  return imageUrl;
},
   
      DrawerSelected() {
        var e = window.Drawer
        if (window.prevElement != undefined && window.prevPosition != undefined) 
          window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
        this.$log.debug(window.Drawer)
        this.$log.debug(this.$route.meta.id)
        this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length)
        for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
         // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
          if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {              
            e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = '#FF9900'
            window.prevElement = e
            window.prevPosition = x
          }
      }
      }, GridLayoutReset(e) {
        this.$log.debug(e)
        this.$log.debug(this.dataGrid)
        this.dataGrid.state(null);
      },
      onFormSubmit(e) {
  
        window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
        window.this.$log.debug(e)
        var self=this
  
        if (e.submitter.attributes[0].ownerDocument.activeElement.id == "add") {
          notify({
            message: 'Zaměstnanec přidán do skupiny "'+self.group.content+'"',
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'success', 3000);
          this.AddImportantFunction()
        }
        else if (e.submitter.attributes[0].ownerDocument.activeElement.id == "remove") {
          notify({
            message: 'Změny objednávky uloženy.',
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'success', 3000);
         // this.NewOrder("upravit");
        }
        else if (e.submitter.attributes[0].ownerDocument.activeElement.id == "activa") {
          notify({
            message: 'Přesměrování do e-shopu ACTIVA.',
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'success', 3000);
         // this.NewRequestActiva();
        }
        e.preventDefault();
      },
      TestEditImportant() {
        this.$log.debug('TestEditImportant');
        var g = new window.General(window.channel, window.message);
        let self = this;     
        g.Validate('PhoneAddImportant',
          function (result) {
            self.$log.debug(result);
            self.editImportant=result.ErrorNum.value==0;
          }, window.this.ApiError());
  
      },
      EmployeesInGroups() {
        var ph = new window.Phones(window.channel, window.message);
        let self = this;     
          if (!isNull(self.group)) {    
            self.zam.length = 0;
        ph.GetPhoneList_v2(':IMPORTANT=' +self.group.content, 1, 30,
          function (result) {
            //window.this.$log.debug(result)
            var prev=0;
            var y=0;
            for (var x = 0; x < result.items.length; x++) { 
                if (prev!=result.items[x].Zam_id.value) {
                self.zam[y] = {   
                  id: result.items[x].Zam_id.value,
                  content: result.items[x].Name.value,
                  };
                  y=y+1;
                  prev=result.items[x].Zam_id.value;
                }
  
            }
  
  
          }, window.this.ApiError());
        }
      },
      customItemCreating(data, id) {
        window.this.$log.debug(data)
        window.this.$log.debug(data.text + ' text')
  
        if (!data.text) {
          data.customItem = null;
  
          return;
        }
  
  
        //  const itemIds = suppliersData.map((item) => item.ID);
        //  const incrementedId = Math.max.apply(null, itemIds) + 1;
        if (id == 1) {
          const newItem = {
            content: data.text,
            // id: incrementedId,
          };
          data.customItem = groupsDataSource
            .store()
            .insert(newItem)
            .then(() => groupsDataSource.load())
            .then(() => newItem)
            .catch((error) => {
              throw error;
            });
        }
  
      },
      SearchZam(e) {
        let self = this;
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          self.GetEmployees(e)
        }, 1000)
  
      },
      ClearValue(e) {
        if (e.component.option("text") != '')
          e.component.reset()
      },
      setSelectedValue(e) {
        window.this.$log.debug(e)
        if(!this.MountingData) {
          switch (e.element.id) {
            case '1':
              switch (e.value.id) {
                case 1:
                  this.zobraz=e.value
                  this.TableDataTel(':FAVORITE')
                  break;
                case 0:
                  this.TableDataTel(":CO-WORKER")
                  this.zobraz = e.value
                  break;
                
                default:
                  this.TableDataTel(':IMPORTANT=' + e.value.content)
                  this.zobraz = e.value
                break;
              }       
              break;
            case '2': 
              this.group = e.value
              this.EmployeesInGroups()
              break;
            case '3':
              this.zamestnanec = e.value
              
              break;
          }
        }
      },
      log(message) {
        window.this.$log.debug(message);
      },
      SearchInput: function (e) {
      //   window.this.$log.debug(e)
   //      window.this.$log.debug(this.zobraz)
        if (e.name === "searchPanel" && !this.MountingData) {
          this.searched = false
        //  window.this.$log.debug(e.value)
          if (e.value != '' && e.fullName != 'searchPanel.placeholder')
            this.SearchDelay(e.value)
          if (e.value == '') {
            switch (this.zobraz.id) {
              case 1:
                this.TableDataTel(':FAVORITE')
                break;
              case 0:
                this.TableDataTel(":CO-WORKER")
                break;
  
              default:
                this.TableDataTel(':IMPORTANT=' + this.zobraz.content)
                break;
            }
           }

        }
      },
      SearchDelay(search) {
        let self = this;
        window.this.$log.debug(search)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          self.searched = true;
          self.TableDataTel(search);
        }, 1000)
  
      },
      
      OnRowClickDetail(event){
        window.this.$log.debug('OnRowClickDetail');
        window.this.$log.debug(event);
        let self=this;
        //let ResultArray=
    // let maximum=this.rowsDetails.filter(item => item.zamid === event.key ).length;
        let result=this.rowsDetails.filter(item => item.zamid === event.key );
          //self.foundItem[0]= this.rowsDetails.find(item => item.zamid === event.key );
          self.foundItem=result;
        window.this.$log.debug(this.foundItem.id)
        window.this.$log.debug('clickedRowKey FOUNDITEM');
        window.this.$log.debug(this.foundItem);
        
        this.$log.debug(this.$refs)
        //this.isDetailRowExpanded(event);
         const clickedRowKey = event.key;
        window.this.$log.debug('clickedRowKey');
        /*window.this.$log.debug(event,'EVENT',event.component.getScrollable());
        const gridInstance = this.$refs.mygrid.instance;
        const scrollable = event.component.getScrollable();
        const scrollableFromGrid = gridInstance.getScrollable();
        window.this.$log.debug(event,'EVENT2',gridInstance,scrollable,scrollableFromGrid);
        window.this.$log.debug(event,'EVENT3',);
        scrollable.scrollTo(gridInstance.getRowElement(event.rowIndex))
        //window.this.$log.debug();*/
      if (this.dataGrid) {
       
        
if (this.dataGrid.isRowExpanded(clickedRowKey)){
        this.dataGrid.collapseAll(-1);
        this.dataGrid.collapseRow(clickedRowKey);
        } else {
          this.dataGrid.collapseAll(-1);
          this.dataGrid.expandRow(clickedRowKey);}
      }
        
        

        
      },
      isDetailRowExpanded: function() {
            this.dataGrid.isRowExpanded();
        },
      OnCellClick(params) {
        window.this.$log.debug("PARAMS");
        window.this.$log.debug(params)
          var self = this
        //  window.this.$log.debug(params)
      if(params.column!=undefined){
      if ((params.column.dataField == 'favorite')) {
  
          var ph = new window.Phones(window.channel, window.message);
          this.rows[params.rowIndex] = {
            favorite: params.data.favorite = 1 - params.data.favorite,
          };
          // window.this.$log.debug(params.column.field)
          if (params.data.favorite == 1) {
            ph.AddFavorite(params.data.zamid,
              function () {
                if (self.zobraz.id == 1) {
                  self.rows.length = 0
                  self.TableDataTel(':FAVORITE');
                }
                 
                // window.this2.ClickFavorite();
              }, window.this.ApiError());
          }
          else {
            ph.RemoveFavorite(params.data.zamid,
              function () {
                
                if (self.zobraz.id == 1) {
                  self.rows.length=0
                  self.TableDataTel(':FAVORITE');
                }
                 
                // window.this2.ClickFavorite();
              }, window.this.ApiError());
          }
        }
        else  {
          window.this.$log.debug("PARAMS");
          window.this.$log.debug(params.columnIndex);
          window.this.$log.debug(params.column);
          switch (params.column.dataField) {
            case 'active':
            case 'zamid':
            case 'name':
            
            this.OnRowClickDetail(params);
              break; 

            default:
              //                
          }
        
        }
      }
      },
      open() {
  
        window.loader = this.$loading.show({
          loader: 'dots'
        });
      },
      hide() {
         window.loader.hide()
      },
      Button4Click() {
        this.page++;
        this.TableDataTel(this.prevsearch);
  
      },
      ButtonAllClick() {
        this.page=0;
        this.disable=true;
        this.TableDataTel(this.prevsearch);
  
      },
      GetEmployees(employee) {
        if (employee.component != undefined) {
          var e = employee
          employee = e.component.option("text")
        }
        var gen = new window.General(window.channel, window.message);
        let self = this;
        self.zamestnanci.length = 0;
        //  window.this.$log.debug(employee)
        gen.ListEmp(employee,
          function (result) {
            //  window.this.$log.debug(result.fResponseObject.result.Result.List.length)
            //    window.this.$log.debug(result);
            for (var x = 0; x < result.List.value.items.length; x++) {
              self.zamestnanci[self.zamestnanci.length] = {
                content: result.List.value.items[x].Value.value,
                id: result.List.value.items[x].Id.value,
                };
              if (x > 20) break;
            }
            e.component.getDataSource().reload();
          }, window.this.ApiError());
      },
  
      ListImportantFunction() {
      
        var ph = new window.Phones(window.channel, window.message);
        //  let self = {};
        let self = this;
             var categoryPreklad= self.$t('telefony-DuleziteKontakty');
        const store = dataSource.store();
        store.clear()
        self.zobrazeni.length = 2;
        self.groups.length = 0;
        ph.ListImportant(
          function (result) {
            
            window.this.$log.debug(window.e)
            // window.this.$log.debug(result)
            // window.this.$log.debug(result.items.length)
            var x = 0
            for (; x < result.items.length; x++) {
              self.zobrazeni[self.zobrazeni.length] = {
                content: result.items[x].Value.value,
                id: result.items[x].Id.value,
              };
              self.groups[self.groups.length] = {
                content: result.items[x].Value.value,
                id: result.items[x].Id.value,
              };
              
              store.insert({
                id: result.items[x].Id.value,
                content: result.items[x].Value.value,
                category: categoryPreklad,
  
              })
                .then(
                  (dataObj) => {
                    window.this.$log.debug(dataObj)
                    dataSource.reload();
                  },
                  (error) => { window.this.$log.debug(error) /* ... */ }
                );
            }
           // [{ "content": "Oblíbené kontakty", "id": 1, "category": "Ostatní" }, { "content": "Mé oddělení", "id": 0, "category": "Ostatní" }],
            // dodelat sem self
            
            var contentInsert=self.$t('telefony-MeOddeleni');
            var categoryInsert=self.$t('telefony-Ostatni');
            store.insert(
              
              {
                
                id: 0,
                content: contentInsert,
                category: categoryInsert
  
              })
              .then(
                (dataObj) => {
                  window.this.$log.debug(dataObj)
                  dataSource.reload();
                },
                (error) => { window.this.$log.debug(error) /* ... */ }
            );
            
            var contentOblibeneKontakty=self.$t('telefony-OblibeneKontakty');
            var categoryOstatni=self.$t('telefony-Ostatni');
            store.insert({
              id: 1,
              content: contentOblibeneKontakty,
              category: categoryOstatni
  
            })
              .then(
                (dataObj) => {
                  window.this.$log.debug(dataObj)
                  dataSource.reload();
                },
                (error) => { window.this.$log.debug(error) /* ... */ }
              );
            self.selectBox1.getDataSource().reload();
            self.selectBox2.getDataSource().reload();
            if(self.x==1)
              self.zobrazeni = dataSource.items()[1].items[0]
            else 
              self.zobrazeni = dataSource.items()[1].items[1]
            window.this.$log.debug(dataSource.items()[1].items[0])
          }, window.this.ApiError());
  
      },
      AddImportantFunction() {
        let self = this;
        var ph = new window.Phones(window.channel, window.message);
          ph.AddImportant(this.group.content, this.zamestnanec.id,
            function () {
              window.this.$log.debug('do it')
              self.ListImportantFunction();
              self.EmployeesInGroups()
              //self.TableDataTel(':IMPORTANT=' + self.group.content);
            }, window.this.ApiError());
      },
  
      RemoveImportantFunction(zamid) {
        window.this.$log.debug(zamid)
        var self=this
        var ph = new window.Phones(window.channel, window.message);
          ph.RemoveImportant(this.group.content, zamid,
            function () {
              self.EmployeesInGroups()
              self.ListImportantFunction();
              notify({
                message: 'Zaměstnanec odebrán ze skupiny "' + self.group.content + '"',
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'success', 3000);
            }, window.this.ApiError());
  
      },
      htmlEmailAdresa(inputstring){
          return '<a href="mailto:'+inputstring+'">'+inputstring+'</a>';
      },
      FormatMainNumberToCall(inputstring, typ){ // if typ =1 return v-html formatted; if typ=0 return bland format of number like ### ### ###
        let digitsOnly = inputstring.replace(/\D/g, ''); //odebrani veskerych jinych znaku nez cisel 
        let formattedActualString =digitsOnly.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3'); // formatovani do ### ### ###
        if (typ==1) { // zobrazeni
          if (formattedActualString.includes(this.predvolbaLinkaUOCHB)) {
            const parts = formattedActualString.split(this.predvolbaLinkaUOCHB);
              // Format the phone value with "220 183" in normal text and bold for the rest
              formattedActualString = this.predvolbaLinkaUOCHB + "<b>"+parts.slice(1).join("")+ "</b>";
            } else {
            formattedActualString;

            }
        }
        this.$log.debug('first9Digits',formattedActualString)
        return formattedActualString;


      },
      MainPhoneFormat(inputArray,coherent){
       // window.this.$log.debug(inputArray)
        //const regex = /(\d{3} \d{3} \d{3})/g;
        let formattedString=''

        for (let i = 0; i < inputArray.length; i++) {
          const phoneNumber = inputArray[i];
          const textWithoutSpaces = phoneNumber.replace(/\s/g, '');
         // console.log(phoneNumber);
          let formattedActualString =textWithoutSpaces.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
          if (formattedActualString.includes(this.predvolbaLinkaUOCHB)) {
            const parts = formattedActualString.split(this.predvolbaLinkaUOCHB);
              // Format the phone value with "220 183" in normal text and bold for the rest
              formattedActualString = this.predvolbaLinkaUOCHB + "<b>"+parts.slice(1).join("")+ "</b>";
            } else {
            formattedActualString;

            }
          if (i>0) formattedString = formattedString+', '+formattedActualString;
          else formattedString = formattedActualString; 
        }
        if (formattedString.startsWith(' ') || formattedString.startsWith(',')) {
        
              for (let i = 0; i < formattedString.length; i++) {
        if (formattedString[i] !== ' ' && formattedString[i] !== ',') {
          var startIndex = i;
          break;
            }
          }
          return formattedString.substring(startIndex);
              }

        if (coherent==1){
          formattedString=this.FormatMainNumberToCall(formattedString, 0);
        }
        return formattedString;
       
      },
      PhoneLineFormatStart(phoneValue){
        let formattedPhone = phoneValue;
        
          if (phoneValue.includes(this.predvolbaLinkaUOCHB)) {
              // Split the phone value at "220 183"
              const parts = phoneValue.split(this.predvolbaLinkaUOCHB);
  
              // Format the phone value with "220 183" in normal text and bold for the rest
              formattedPhone =parts[0]+ this.predvolbaLinkaUOCHB;
          } else {
            formattedPhone = phoneValue.slice(0, 3)+" "+phoneValue.slice(3, 6)+" ";
            
          }
          
          return formattedPhone;
  
      },
      PhoneLineFormatEnd(phoneValue){
        let formattedPhone = phoneValue;
  
          if (phoneValue.includes(this.predvolbaLinkaUOCHB)) {
              // Split the phone value at "220 183"
              const parts = phoneValue.split(this.predvolbaLinkaUOCHB);
  
              // Format the phone value with "220 183" in normal text and bold for the rest
              formattedPhone = parts.slice(1).join("");
          } else {
            formattedPhone = phoneValue.slice(-3);
          }
          return formattedPhone;
      },
      functionTEST(room){
        window.this.$log.debug(room)
        room.replace(/[()]/g, '');
        return room;
      },
      TableDataTel(Search) {
        window.this.$log.debug(Search + " funkce tabledata")
        window.this.$log.debug(this.page + " page")
        var ph = new window.Phones(window.channel, window.message);
        let self = this.rows;
        let self3 = this.rowsDetails;
        self3.length=0;
        let self2 = this;
        window.this2 = this;
        this.disable = false;
        // window.this.$log.debug(this.prevsearch)
        if (this.prevsearch == Search && this.page == 1) self.length = 0;
        if (window.this2.prevsearch != Search && window.this2.page > 0) 
        {
          self.length = 0;
          window.this2.page = 1;
        }
        if (window.this2.page==0) {self.length=0}
        this.prevsearch = Search;
        //   window.this.$log.debug(Search);
      //  this.open();
        ph.GetPhoneList_v2(Search, window.this2.page, 20,
          function (result) {
            if (result.items.length == 0 || result.items.length < 20) {
              window.this2.disable = true
            }
            window.this.$log.debug('Zásek')
            window.this.$log.debug(result)
            var previouszamid = 0;
              var previousid = 0;

            for (var x = 0; x < result.items.length; x++) {
              const phoneValue = (result.items[x].Phone.value).replace(/[()]/g, '');
              let formattedPhone = phoneValue;

              //var i = self.length;
                if (result.items[x].Zam_id.value != 0) {
                var aktualnizamid = result.items[x].Zam_id.value;
                let resultzamid = result.items[x].Zam_id.value;
                  let resultname = result.items[x].Name.value;
                  let resultactiv = result.items[x].Active.value;
                  let resultfavorite = result.items[x].Favorite.value;
                  let resultemail = result.items[x].Email.value;
                  let aktualniid = x;
                  let resultid = undefined;

                  if (result.items[x].Zam_id.value != 0) {

                    self3.push({
                      id: x + 1,
                      zamid: result.items[x].Zam_id.value,
                      name: result.items[x].Name.value,
                      phone: (result.items[x].Phone.value).replace(/[()]/g, ''),
                      email: result.items[x].Email.value,
                      favorite: result.items[x].Favorite.value,
                      room: result.items[x].Room.value,
                      active: result.items[x].Active.value,
                      titles: result.items[x].Titles.value,
                    })
                  }
                window.this.$log.debug('END')
                  if (resultzamid == previouszamid) {
                    resultname = previousid;
                    resultactiv = undefined;
                    resultfavorite = undefined;
                    resultemail = undefined;
                    resultid = previousid;
                  }
                  else { resultid = x + 1; }
                  if ((x == 0) || (aktualnizamid != result.items[x - 1].Zam_id.value)) {
                    self.push({
                      id: resultid,
                      zamid: resultzamid,
                      name: resultname,
                      phone: [formattedPhone],
                      email: resultemail,
                      favorite: resultfavorite,
                      room: [result.items[x].Room.value],
                      active: resultactiv
                    });
                  } else {
                    self[self.length - 1].phone.push(formattedPhone);
                    self[self.length - 1].room.push(result.items[x].Room.value);
                  }
                  previouszamid = aktualnizamid;
                  previousid = aktualniid;

                }

            }
            window.this.$log.debug(self)
              self2.dataGrid.getDataSource().reload();
              self2.needFocusToSearchPanel = true; 
            
           /* self2.$log.debug(self2.dataGrid.element())
              var SearchPanelElement = self2.dataGrid.element().querySelector('.dx-datagrid-search-panel.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-searchbox.dx-show-clear-button.dx-widget')
              self2.$log.debug(SearchPanelElement)
              self2.$log.debug(SearchPanelElement.firstElementChild.children[0].children[1])
              if(self2.dataGrid.option('searchPanel').text != '') self2.dataGrid.focus(SearchPanelElement.firstElementChild.children[0].children[1])
              */
         //   window.this2.hide();

          }, window.this.ApiError());
          
      },
      OnMasterDataOpen(e){
    //    this.$log.debug("OnMasterDataOpen",e,e.element.scrollHeight);
        this.$log.debug("scrollTop",e.element);
        this.$log.debug("a",e.component.getRowElement());
       this.$log.debug("a",e.component.getRowIndexByKey(e.key));
       var RowIndex = e.component.getRowIndexByKey(e.key);
        this.$log.debug("nb",e.component.getScrollable());
        var scrollable = e.component.getScrollable();
        scrollable.scrollBy({ left: 0, top: e.element.scrollHeight()+RowIndex*20 });
        const gridInstance = this.$refs.mygrid.instance;
        const scrollableFromGrid = gridInstance.getScrollable();
        window.this.$log.debug('EVENT2',gridInstance,scrollableFromGrid);
        window.this.$log.debug('EVENT3');
        scrollableFromGrid.scrollTo(gridInstance.scrollHeight())
        
      }
    },
  };
  </script>
  
  <style scoped>
  
  
      #menu {
          background-color: #6fb3db;
          border: 1px solid #CCCCCC;
          padding: 20px;
          margin-top: 80px;
      }
  
      #buttonAll,#button4 {
        margin: 2px;
      }
      #validation {
        color: rgb(255, 0, 0);
      }
      #favoriteicon {
      object-fit: cover;
      
      }
      :deep(.dx-datagrid .dx-column-lines>td:last-child){
        text-overflow: initial;
      }
      #favoriteicon:hover{
        cursor:pointer;
      }
      #text1 {  
        margin-bottom: 30px;
        margin-right: 10px;
        margin-top: 10px;
      }
      label { 
            margin-right: 30px;
      }
      #label1 {
        color:darkgreen;
      }
      #label2  {
        color:rgb(38, 0, 255);
      }
      #enablesearch{
        margin-bottom: 30px;
      }
      button {
        margin-right: 80px;
        margin-bottom: 15px;
      }
      #enablesearch {
        margin-right: 150px;
      }
       #labelmodal{
        margin-bottom: 30px;
      }
       
  
      #text2 {
        margin-bottom: 20px;
      }
      #DataGridTelSeznam{width: 100%; margin-right: 0; padding-right: 0;}
      #obaleniDataGrid{
        border-style: ridge;
      border-color: rgba(128, 128, 128, 0.226);
      border-radius: 10px;
      padding: 10px;
      height: 100%;
      }
      #buttonEditovatDuleziteKontakty:hover{
        background-color: rgba(168, 0, 0, 0.5);
        color: white;
      }
      #nadpisTelefony{text-align: center;}
      .bold-text {
      font-weight: bold;
  }
  #imgProfilovaFotkaDetail{max-width: 80px; 
    max-height:100px; 
    border: rgb(211, 211, 211) 0.5px solid; border-radius: 5%;
  align-self: center;
margin:0 auto 0;}
  /*#imgProfilovaFotkaDetail:hover{
    cursor:zoom-in;
    max-width:  100px;
    max-height: 125px;
    align-self: flex-start;
    display: block;

  

  }*/
    #DatagridDetailKontakt{max-height:100px; align-self: stretch; margin-left: 15px; max-width: 100% !important;}
    #flexOsobniInformaceDetailMAIN{
      display:flex;
  flex-direction:row;
  
  justify-content:flex-start;
  align-items:stretch;
    }
    #flexFotkaTituly{display:flex;
  flex-direction:column;
      justify-content:center;
      width:auto;
      align-items:center
}
  #detailiconInfo:hover{
    cursor:pointer;
  }
  #detailiconInfo{
    margin: 0 auto 0;
    padding: 0;
    margin-bottom:-3px !important;
  }
  #detailtitles{
    margin: 0 auto 0;
    
  }
  .cursorText:hover{
     cursor:text
  }
  .cursorPointer:hover{
    cursor:pointer;
  }
  .dx-data-row .columnPhonesOverflow{text-overflow: ellipsis;  
    overflow: hidden;  }
    .dx-datagrid-content .dx-datagrid-table .dx-row > td {
  overflow: hidden;
  text-overflow: ellipsis;
}
.oneRowMultiplePhones{
  width: fit-content;
}
.inlineFlexBox{
  display: flex;
}
.heightbtnEditImportant {
    height: auto;
  }

  /* Media query for viewport width less than or equal to 700px */
  @media screen and (max-width: 700px) {
    .heightbtnEditImportant {
      height: 40px;
      text-overflow: ellipsis;
    }
  }

  /* Media query for viewport width greater than 700px */
  @media screen and (min-width: 701px) {
    .heightbtnEditImportant {
      height: 45px;
    }
  }
  #horniListaTelSeznam{
    display: flex;
    align-items: center;
  }
  
  </style>