<template>
    <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000718213?lang=cs" 
  URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000718213?lang=en" 
PopisLinku='clanky.platebniKarta'></HelpLink>
    <div height="calc(100% - 57px)">
        
        <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false"
            :hide-on-outside-click="true" :show-close-button="true" :title="$t('central-Varovani')"
            :contentTemplate="popupAlertTemplate" :width="400" :height="200">
            <template #SmazatPoz>
                <h3>{{ $t('majetekVyrazeni-OpravduSiPrejeteSmazat') }}</h3>
                <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained"
                    :text="$t('majetekVyrazeni-Ano')" @click="SetState(-99, true); popupAlert = false"/>
                <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained"
                    :text="$t('majetekVyrazeni-Ne')" @click="popupAlert = false" />
            </template>
        </DxPopup>

        <div id="horniListaPozadavku">
            <DxButton icon="back" :text="$t('central-VratitSeZpet')" height="auto" type="outlined"
                styling-mode="contained" id="link" @click="$router.push('Pozadavky')" />
            <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-if="zakladani">{{ $t('Pozadavky.Platkarta.Zalozeni') }}
            </h2>
            <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-else>{{ $t('Pozadavky.Platkarta.Nadpis') }}{{ ' '+poz +
                ' (' + state + ')' }} </h2>
        </div>
        <DxScrollView ref="mainScroll" style="height: 100%; width: 100%;" direction="vertical">


        <form action="your-action" @submit="onFormSubmit($event)">
            <div id="obaleniFormulare">
                <div id="obalenigrid">
                    <DxResponsiveBox single-column-screen="sm" id="SmlouvaResponsiveBox">
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxCol :ratio="1" />
                        <DxCol :ratio="1" />
                        <DxCol :ratio="1" />
                        <DxItem>
                            <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
                            <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                            <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />
                            <div class="SmlouvaBox">
                                    <DxSelectBox :disabled="stateid != 4" :id="1" ref="cardOwnersSelectBox" width="auto" height="45px"
                                            :label="$t('Pozadavky.Platkarta.Drzitel')" :data-source="cardOwners" display-expr="content"
                                            value-expr="id" placeholder="" :value="formData.cardID" :search-enabled="true" :accept-custom-value="false" 
                                            @value-changed="setSelectedValue($event);"  no-data-text="">
                                            <DxValidator @validated="handleValidation">
                                                <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteDrzitele')" />
                                            </DxValidator>
                                    </DxSelectBox>
                            </div>
                        </DxItem>
                        <DxItem>
                                <DxLocation :row="1" :col="0" :colspan="3" screen="lg" />
                                <DxLocation :row="1" :col="0" :colspan="3" screen="md" />
                                <DxLocation :row="1" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="1" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox">
                                    <DxTextArea style="margin-bottom: 0px;" :disabled="stateid != 4" :id="2" height="100px"
                                        :label="$t('Pozadavky.Platkarta.Ucel')" placeholder="" @value-changed="setSelectedValue"
                                        :value="formData.ucel">
                                        <DxValidator @validated="handleValidation">
                                            <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteUcel')" />
                                        </DxValidator>
                                    </DxTextArea>
                                </div>
                            </DxItem>
                        <DxItem>
                                <DxLocation :row="2" :col="0" :colspan="3" screen="lg" />
                                <DxLocation :row="2" :col="0" :colspan="3" screen="md" />
                                <DxLocation :row="3" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="3" :col="0" :colspan="3" screen="xs" />

                                    <div class="SmlouvaBox" width='auto'>
                                        <Suppliers class="suppliersComponenta" :Disabled="stateid != 4" 
                                            :Supplier="supplier" :SmazBtnVisibility="true" 
                                            :Label="$t('Pozadavky.Platkarta.Dodavatel')" @value-changed="setSelectedValue($event)" />
                                                <DxTextBox v-if="AddressVisible" class="dxTextBoxAdresaDodavatel" :disabled="stateid != 4" :id="3"
                                                    :label="$t('Pozadavky.Platkarta.Adresa')" :placeholder="$t('Pozadavky.Platkarta.VyplnteAdresa')" @value-changed="setSelectedValue($event)"
                                                    :value="formData.adresa" :maxLength="150">
                                                    <DxValidator @validated="handleValidation">
                                                        <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteAdresu')" />
                                                    </DxValidator>
                                                </DxTextBox>

                                    </div>
                            </DxItem>
                         <DxItem>
                                <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
                                <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                <DxLocation :row="4" :col="0" :colspan="1" screen="sm" />
                                <DxLocation :row="4" :col="0" :colspan="1" screen="xs" />
                                <div class="SmlouvaBox">
                                    <DxNumberBox height="45px" width="100%" :disabled="stateid != 4" :id="4" 
                                        :label="$t('Pozadavky.Platkarta.Celkem')" placeholder="" @value-changed="setSelectedValue" @focus-in="this.ValidateCenaNumberBox()" 
                                        :value="formData.cena" format="#,##0.00" :step="0">

                                        <DxValidator ref="myCenaNumberBoxValidator" @validated="handleValidation">
                                            <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteCenu')" />
                                            <DxCustomRule
                                                :validation-callback="validateNumberNeZakladani"
                                                :message="messageNumberCustomRuleNeZakladani"
                                                :reevaluate="true"
                                            />
                                            <DxCustomRule
                                                :validation-callback="validateNumberPositive"
                                                :message="messageNumberCustomRulePositive"
    
                                            />
                                            <DxRangeRule
                                            :min="$decimalLimits.min"
                                            :max="$decimalLimits.max"
                                            :message="localizedMessage"
                                            />
                                        </DxValidator>
                                    </DxNumberBox>
                                </div>
                            </DxItem>
                            <DxItem>
                                    <DxLocation :row="3" :col="1" :colspan="1" screen="lg" />
                                    <DxLocation :row="3" :col="1" :colspan="1" screen="md" />
                                    <DxLocation :row="4" :col="1" :colspan="1" screen="sm" />
                                    <DxLocation :row="4" :col="1" :colspan="1" screen="xs" />
                                    <div class="SmlouvaBox">
                                        <DxSelectBox :disabled="stateid != 4" :id="5" width="auto" height="45px" :noDataText="$t('central-NenalezenyZadneZaznamy')"
                                                :label="$t('Pozadavky.Platkarta.Mena')" :data-source="meny" display-expr="content"
                                                value-expr="id" placeholder="" :value="menaid" :search-enabled="true"
                                                @value-changed=" setSelectedValue($event);" class="DxSelectBoxResponsive">
                                                <DxValidator @validated="handleValidation">
                                                    <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteMenu')" />
                                                </DxValidator>
                                        </DxSelectBox>
                                    </div>
                                </DxItem>
                        <DxItem>
                            <DxLocation :row="3" :col="2" :colspan="1" screen="lg" />
                            <DxLocation :row="3" :col="2" :colspan="1" screen="md" />
                            <DxLocation :row="4" :col="2" :colspan="1" screen="sm" />
                            <DxLocation :row="4" :col="2" :colspan="1" screen="xs" />
                            <div class="SmlouvaBox">
                                <DxDateBox  :disabed="stateid != 4" :id="6" height="45px" 
                                        :label="$t('Pozadavky.Platkarta.Datum')" placeholder=""
                                        v-model:value="formData.datum" @value-changed=" setSelectedValue($event);" :accept-custom-value="false" :open-on-field-click="true">
                                            <DxValidator @validated="handleValidation">
                                                <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteDatum')" />
                                            </DxValidator>      
                                    </DxDateBox>
                            </div>
                        </DxItem>

                        <DxItem>
                            <DxLocation :row="5" :col="0" :colspan="3" screen="lg" />
                            <DxLocation :row="5" :col="0" :colspan="3" screen="md" />
                            <DxLocation :row="6" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="6" :col="0" :colspan="3" screen="xs" />
                            <div class="SmlouvaBox" >
                                <DxSelectBox :disabled="stateid != 4" :id="7" width="auto" height="45px" :noDataText="$t('central-NenalezenyZadneZaznamy')"
                                                :label="$t('Pozadavky.Platkarta.Uplatneni')" :data-source="uplatneni" display-expr="content"
                                                value-expr="id" placeholder="" :value="formData.typPouziti" :search-enabled="true"
                                                @value-changed=" setSelectedValue($event);">
                                                <DxValidator @validated="handleValidation">
                                                    <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteUplatneni')" />
                                                </DxValidator>  
                                        </DxSelectBox>
                                        <DxCheckBox v-if="this.IdentPlatKartyJeOstatni()" class="textyOutsideOfBoxes clCheckBoxForma"  :disabled="stateid != 4" :id="10" :text="$t('Pozadavky.Platkarta.SouhlasObsah')" v-model:value="formData.souhlas" height="45px" @value-changed="setSelectedValue($event)" >
                                            <DxValidator @validated="handleValidation" ref="checkBoxSouhlas" @initialized="this.ValidateCheckBoxSouhlas();">
                                                    <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteUplatneni')" />
                                            </DxValidator>
                                        </DxCheckBox>
                                        <DxSelectBox v-else-if="zakladani && this.formData.typPouziti!=null" style="margin-top:10px !important" ref="cestyselectbox" :disabled="stateid != 4" :id="12" width="auto" height="45px" :noDataText="$t('central-NenalezenyZadneZaznamy')"
                                                        :label="$t('Pozadavky.Platkarta.MojeCesty')" :data-source="formData.PracCesty" display-expr="content"
                                                        value-expr="id" placeholder="" :value="formData.pracCesta" :search-enabled="true"
                                                        @value-changed=" setSelectedValue($event);">        
                                             </DxSelectBox>
                            </div>
                        </DxItem>
              <!--        <DxItem >
                                <DxLocation :row="6" :col="0" :colspan="3" screen="lg" />
                                <DxLocation :row="6" :col="0" :colspan="3" screen="md" />
                                <DxLocation :row="7" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="7" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox" v-if="Cesta && this.formData.typPouziti!=null" >
                                    <DxSelectBox ref="cestyselectbox" :disabled="stateid != 4" :id="7" width="auto" height="45px" :noDataText="$t('central-NenalezenyZadneZaznamy')"
                                                    :label="$t('Pozadavky.Platkarta.MojeCesty')" :data-source="formData.PracCesty" display-expr="content"
                                                    value-expr="id" placeholder="" :value="formData.pracCesta" :search-enabled="true"
                                                    @value-changed=" setSelectedValue($event);" class="DxSelectBoxResponsive">
                                                    <DxValidator>
                                                        <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteCestu')" />
                                                    </DxValidator>  
                                            </DxSelectBox>
                                            <DxCheckBox v-if="this.IdentPlatKartyJeOstatni()" class="textyOutsideOfBoxes clCheckBoxForma"  :disabled="stateid != 4" :id="10" :text="$t('Pozadavky.Platkarta.SouhlasObsah')" v-model:value="formData.souhlas" height="45px" @value-changed="setSelectedValue($event)" >
                                                <DxValidator ref="checkBoxSouhlas" @initialized="this.ValidateCheckBoxSouhlas();">
                                                        <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteUplatneni')" />
                                                </DxValidator>
                                    </DxCheckBox>
                                </div>
                            </DxItem>
                            -->  
                        <DxItem>
                            <DxLocation :row="7" :col="0" :colspan="3" screen="lg" />
                            <DxLocation :row="7" :col="0" :colspan="3" screen="md" />
                            <DxLocation :row="8" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="8" :col="0" :colspan="3" screen="xs" />
                            <div class="SmlouvaBox">
                                <label id="hrID"></label>
                                <div v-html=this.StringToHtml($t(translationKey)) v-if="zakladani"></div>
                                <DxCheckBox class="clCheckBoxForma clCheckBoxFormaLAST"  :disabled="stateid != 4" :id="11" :text="$t('Pozadavky.Platkarta.FormaVlastnenehoDokladu')" v-model:value="formData.souhlasElektroDokument" height="45px" @value-changed="setSelectedValue($event)" >
                                </DxCheckBox>
                                <div v-if="zakladani">
                                    <div v-if="formData.souhlasElektroDokument"> 
                                    <label id="doplnujiciTextPrilohy" >{{ $t('Pozadavky.Platkarta.PrilohyNahrajiVDalsimKroku') }}</label>
                                    </div>
                                </div>
                                
                                
                               
                            </div>
                        </DxItem>
                        <DxItem v-if="false">
                            <DxLocation :row="8" :col="0" :colspan="3" screen="lg" />
                            <DxLocation :row="8" :col="0" :colspan="3" screen="md" />
                            <DxLocation :row="9" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="9" :col="0" :colspan="3" screen="xs" />
                            <div class="SmlouvaBox">
                                <DxSelectBox ref="grantyselectbox" :disabled="stateid != 4" :id="8" width="auto" height="45px" :label="$t('central-Grant')"
                                        :data-source="granty" display-expr="content" value-expr="id" placeholder="" :search-enabled="true"
                                    :value=" formData.Grant " @value-changed="setSelectedValue($event); " class="DxSelectBoxResponsive">
                                    <DxValidator @validated="handleValidation">
                                        <DxRequiredRule :message="$t('central-GrantMusiBytVyplnen')" />
                                    </DxValidator>
                                </DxSelectBox>
                                <DxCheckBox :disabled="stateid != 4" :id="9" :text="$t('Pozadavky.Platkarta.OstatniGranty')" height="45px" @value-changed="ChangeDataSource($event)">      
                                </DxCheckBox>
                            </div>
                        </DxItem>
                        
                    </DxResponsiveBox>
                    <div id="obaleniFormulare2" padding-top="5px" v-if="!zakladani">
                <DxResponsiveBox single-column-screen="sm" id="DruhyResponsiveBox" class="ResponsiveBoxClass" v-if="!zakladani"
                    width="100%">
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="0.5" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxItem>       
                        <DxLocation :row="0" :col="3" :colspan="3" screen="lg" />
                        <DxLocation :row="0" :col="3" :colspan="3" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="xs" />
                        <div v-if="formData.souhlasElektroDokument" class="GrayedOutBackgroundBorder">
                            <label id="doplnujiciTextPrilohy" >{{ $t('Pozadavky.Platkarta.ZdeNahrajteElektroDokument') }}</label>
                            <Attachments :Disabled="stateid != 4" :Pozid="this.poz" :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1"/>
                        </div>

                    </DxItem>

                    <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="xs" />
                        <div class="dx-field" v-if="!zakladani">

                            <DxTextArea class="DxSelectBoxResponsive" :disabled="stateid != 4" :id="13"
                                :label="$t('majetekPrevod-Komentar')" width="auto" :height="100"
                                @value-changed="setSelectedValue" v-model:value="formData.komentar" placeholder="" />
                        </div>
                    </DxItem>
                </DxResponsiveBox>
            </div>
                    <div class="table" v-if="!zakladani" id="obaleniDataGridu">
                    <!--<h3>{{ $t('Pozadavky.Platkarta.NadpisGrid') }}</h3>-->
                        <DxDataGrid column-resizing-mode="widget" :allow-column-resizing="true" :column-min-width="60" ref="datagridplatkarta" :hoverStateEnabled="true" :rowAlternationEnabled="true" 
                        :data-source="GridRows" :allow-column-reordering="true" :show-borders="true" key-expr="Id" background-color="transparent"
                         :noDataText="$t('central-NenalezenyZadneZaznamy')" @init-new-row="InitNewRow" @saved="GridEditting" width="100%"
                        @editing-start="InitNewRow" 
                        :editing-texts="{ saveRowChanges: '*SAVE*', cancelRowChanges: '*CANCEL*', }">
                        <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
                        <DxColumnFixing :enabled="true" :texts="{ 
                            fix: $t('central.ColumnFixing.fix'), leftPosition: $t('central.ColumnFixing.leftPosition'), rightPosition: $t('central.ColumnFixing.rightPosition'), unfix: $t('central.ColumnFixing.unfix')
                            }" />
                        <DxStateStoring
                        :enabled="true"
                        type="localStorage"
                        storage-key="GridPlatKarta"
                        />
                        <DxExport
                        :enabled="true"
                        :formats="['xlsx']"
                        :allow-export-selected-data="false"
                        :texts="{ exportAll: $t('central-ExportVseExcel') }"
                        />
                        <DxToolbar :visible="stateid == 4">
                        
                            <DxItem location="after" locate-in-menu="auto">
                            <DxButton icon="add" id="PridatZaznam" height="auto" width="auto" :text="$t('central-PridatZaznam')"
                                            type="success" styling-mode="contained" @click="AddRowButtonClick" />
                            </DxItem>
                        
                    
                            <DxItem location="before" locate-in-menu="auto" >
                                <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                type="success" styling-mode="outlined" @click="GridLayoutReset" />      
                            </DxItem>
                            <DxItem
                            name="exportButton" location="after" locate-in-menu="auto"
                            />

                            <DxItem
                            name="searchPanel" location="after" locate-in-menu="auto"
                            :visible="true"
                            />
                        </DxToolbar>       
                        <DxSummary>
                        <DxTotalItem
                        summary-type="count"
                        showInColumn="Grant"
                        :display-format="$t('central-PolozekCelkem') + ' {0}'"
                        cssClass="polozekCelkem"
                        />
                        <DxTotalItem column="pocet" summary-type="sum" showInColumn="pocet" :display-format="'‎ '" cssClass="sum" />
                        <DxTotalItem 
                        column="Cena"
                        summary-type="sum"
                        showInColumn="Cena"
                        value-format="#,##0.00"
                        :display-format="$t('Pozadavky.Platkarta.CelkemCena') + ' {0} ' +this.formData.MenaTXT"
                        
                        /><!--cssClass="cenaCelkem"-->
                    </DxSummary>  
                    <template #EmployeesEditor="{ data: cellInfo }">
                        <Employees :Disabled=" false " :EmpId=" cellInfo.value " @value-changed="SetEmployeeInfo($event, cellInfo)" :Label="' '" Height="auto" width="auto"/>
                    </template>
                    
                        <DxColumn v-if="false" width="100px" data-field="Mena" :caption="$t('Pozadavky.Platkarta.Mena')" :visible="false" >
                            <DxLookup :data-source="meny" display-expr="content" value-expr="id" ></DxLookup>
                                <DxRequiredRule :message="$t('central-MenaMusiBytVyplnena')" />
                        </DxColumn>
                        <DxColumn  width="auto" data-field="Cena"  :caption="$t('Pozadavky.Platkarta.Cena')" format="#,###.00" data-type="number" >
                        <DxNumericRule :message="$t('central-CenaMusiBytCislo')" />
                                    <DxRequiredRule :message="$t('central-CenaMusiBytVyplnena')" />
                                    <DxRangeRule
                            :min="$decimalLimits.min"
                            :max="$decimalLimits.max"
                            :message="localizedMessage"
                            />
                        </DxColumn>
                        <DxColumn width="400px" data-field="Grant" :caption="$t('Pozadavky.Platkarta.Grant')">
                            <DxLookup height="auto" :data-source="allgranty" display-expr="content" value-expr="id" :noDataText="$t('central-NenalezenyZadneZaznamy')" ></DxLookup>
                                    <DxRequiredRule :message="$t('central-GrantMusiBytVyplnen')" />
                            </DxColumn>   
                        <DxColumn v-if="sloupceVisible == 1" width="140px" data-field="TripID" :caption="$t('Pozadavky.Platkarta.TripID')" :visible="sloupceVisible == 1" :editor-options="{ maxLength: 50 }"> 
                        <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteTrip')" />
                        </DxColumn>  
                        <DxColumn v-if="sloupceVisible == 1" width="140px" data-field="Zeme" :caption="$t('Pozadavky.Platkarta.Zeme')" :noDataText="$t('central-NenalezenyZadneZaznamy')" :visible="sloupceVisible ==1">
                        <DxLookup :data-source="zeme" display-expr="content" value-expr="id"></DxLookup>
                                <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteZemi')" />
                        </DxColumn>
                        <!--<DxColumn v-if="sloupceVisible == 1" width="120px" data-field="DatumOd" data-type="date" :caption="$t('Pozadavky.Platkarta.DatumOd')" :visible="sloupceVisible==1">
                            <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteDatumOd')" />
                                            <DxCustomRule
                                                :validation-callback="validateDatumOd"
                                                :message="messageValidaceDatumOd"
                                            />
                                        
                            </DxColumn> -->
                        <DxColumn v-if="sloupceVisible == 1" width="250px" data-field="Cesta" data-type="object" :calculate-cell-value="calculateCestaCellValue" :caption="$t('Pozadavky.Platkarta.CestaTrvani')" edit-cell-template="DateRangeEditor">   
                                <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteCesta')" />
                        </DxColumn>
                        <template #DateRangeEditor="{  }">
                        <DxDateRangeBox   height="auto" start-date-label="" end-date-label="" 
                                                apply-value-mode="instantly" multiview:="true" 
                                                :disabled="stateid != 4"
                                                v-model:startDate="datumCestaOd" 
                                                v-model:endDate="datumCestaDo" date-serialization-format="dd-mm-yyyy"
                                                :accept-custom-value="false" :open-on-field-click="true" @value-changed="setCestaOdDo($event)" label-mode="static" min="1/1/2000">
                                                <!-- :startDatePlaceholder="$t('pracCesta.DatumPocatekPracCesty')" :endDatePlaceholder="$t('pracCesta.DatumKonecPracCesty')" -->
                                                <DxValidator @validated="handleValidation">
                                                <DxRequiredRule :message="$t('pracCesta.VyplnteKonani')" />
                                            </DxValidator> 
                        </DxDateRangeBox> <!--:start-date-label="$t('pracCesta.KonaniOd')" :end-date-label="$t('pracCesta.KonaniDo')" -->

                        </template>

                         <DxColumn v-if="sloupceVisible == 1" width="200px" data-field="ZamID" :caption="$t('Pozadavky.Platkarta.ZamTXT')" :visible="sloupceVisible == 1"  :allow-sorting="false" edit-cell-template="EmployeesEditor">
                            <DxLookup :data-source="zam" display-expr="content" value-expr="id" :noDataText="$t('central-NenalezenyZadneZaznamy')" ></DxLookup>
                                    <DxRequiredRule :message="$t('Pozadavky.Platkarta.VyplnteZam')" />
                                    <DxCustomRule
                                        :validation-callback="validateZam"
                                        :message="$t('Pozadavky.Platkarta.VyplnteZam')"
                                    />
                        
                            </DxColumn>        
                    <DxColumn type="buttons" :width="smallScreen ? 60 : 250">
                    

                    <DxGridButton
                    icon="edit"
                    show-text="always"
                    :visible="({ row }) => !row.isEditing"
                    :onClick="(e) => e.component.editRow(e.row.rowIndex)"
                    />

                    <DxGridButton
                    v-if="!smallScreen"
                    name="edit"
                    :text="$t('central-Upravit')"
                    :visible="({ row }) => !row.isEditing"
                    />
                    
                    <DxGridButton
                    icon="save"
                    :visible="({ row }) => row.isEditing"
                    :onClick="(e) => e.component.saveEditData()"
                    />

                  
                    <DxGridButton
                     v-if="!smallScreen"
                    name="save"
                    :text="$t('central-Potvrdit')"
                    :visible="({ row }) => row.isEditing"
                    />

                   
                    <DxGridButton
                    icon="close"
                    :visible="({ row }) => row.isEditing"
                    :onClick="(e) => e.component.cancelEditData()"
                    />

                   
                    <DxGridButton
                     v-if="!smallScreen"
                    name="cancel"
                    :text="$t('central-ZrusitUpravy')"
                    :visible="({ row }) => row.isEditing"
                    />

                    
                    <DxGridButton
                    icon="trash"
                    :visible="({ row }) => !row.isEditing"
                    :onClick="(e) => e.component.deleteRow(e.row.rowIndex)"
                    />

                    
                    <DxGridButton
                     v-if="!smallScreen"
                    name="delete"
                    :text="$t('central-Smazat')"
                    :visible="({ row }) => !row.isEditing"
                    />

                    </DxColumn>
                    
                        <DxColumn width="60px" data-field="Id" :caption="$t('Pozadavky.Platkarta.SloupecCislo')" :visible="false"/> 
                        <DxColumn data-field="PolID" :caption="$t('Pozadavky.Platkarta.PolID')" :visible="false" />
                        <DxColumn  v-if="false" data-field="ZemeTXT" :caption="$t('Pozadavky.Platkarta.Zeme')" :visible="false"/>
                        <DxColumn  v-if="false" data-field="MenaTXT" :caption="$t('Pozadavky.Platkarta.Mena')" :visible="false"/>
                        <DxColumn  v-if="false" data-field="ZamTXT" :caption="$t('Pozadavky.Platkarta.ZamTXT')" :visible="false" />                  
                        <DxColumn v-if="false" data-field="GrantTXT" :caption="$t('Pozadavky.Platkarta.Grant')" :visible="false"/>       
                        
                        <DxEditing :use-icons="false" :allow-updating="stateid == 4" :allow-deleting="stateid == 4" 
                            :allow-adding="stateid == 4" mode="row" :editRow="$t('central-Upravit')" :texts="{
                                saveRowChanges: $t('central-Potvrdit'), cancelRowChanges: $t('central-ZrusitUpravy'), editRow: $t('central-Upravit'), deleteRow: $t('central-Smazat'),
                                confirmDeleteTitle: $t('central-Varovani'), confirmDeleteMessage: $t('central-OpravduSiPrejeteSmazatZaznam'),        
                            }">
                            
                        </DxEditing>
                        
                        <DxPaging :enabled="false" />
                        <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                        <DxGrouping :auto-expand-all="true" />
                        <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                        <DxSearchPanel :width="300" :visible="true" :placeholder="$t('central-Hledat')" />
                        </DxDataGrid>
                    
                    </div>
                </div>
            </div>
            
            <DxResponsiveBox single-column-screen="sm" class="SpodniMenuDetailPozadavku">
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />

                <DxItem>
                    <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
                    <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                    <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
                    <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />
                    <div id="ResponsiveBoxItemActionButtons" v-if="zakladani">
                        <DxButton icon="add" id="vytvorit" height="auto" width="auto"
                            :text="$t('Pozadavky.Platkarta.Zalozeni')" :use-submit-behavior="true" type="success" />

                    </div>
                </DxItem>

                <DxItem>
                    <DxLocation :row="1" :col="0" :colspan="3" screen="lg" />
                    <DxLocation :row="1" :col="0" :colspan="3" screen="md" />
                    <DxLocation :row="1" :col="0" :colspan="3" screen="sm" />
                    <DxLocation :row="1" :col="0" :colspan="3" screen="xs" />

                    <div id="ResponsiveBoxItemActionButtons" v-if="!zakladani">
                        <div class="child" v-for="item in actionsPozadavky" :key="item" id="actionbuttons">
                            <DxButton class="button1" height="max-content" width="auto" type="default"
                                styling-mode="contained" :text="item.nazev" :id="item.id" @click="SetState(item.id)"
                                :class="item.class" :icon="item.icon"
                                v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
                        </div>

                        <div class="child">
                            <ChangeOwner :Disabled="false" :RequestID="poz" @owner-changed="setSelectedValue">
                            </ChangeOwner>
                        </div>

                    </div>

                </DxItem>
            </DxResponsiveBox>

        </form>
    </DxScrollView> 


    </div>
</template>

<script>
const dataGridRefKey = 'datagridplatkarta';
//import axios from 'axios'
import DxDateRangeBox from 'devextreme-vue/date-range-box';
import Employees from '@/components/Employees.vue';
import ChangeOwner from '@/components/ChangeOwner.vue';
import notify from 'devextreme/ui/notify';
import Suppliers from '@/components/Suppliers.vue';
import Attachments from '@/components/Attachments.vue';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import HelpLink from '@/components/HelpLink.vue';
import {
  DxValidator,
  DxRequiredRule,
  DxNumericRule,
  DxRangeRule,
  DxCustomRule,
} from 'devextreme-vue/validator';
import {
    DxSummary,
    DxTotalItem,
    DxStateStoring,
    DxExport,
    DxColumnFixing,
    DxLoadPanel,
    DxToolbar,
    DxSorting,
    DxButton as DxGridButton
} from 'devextreme-vue/data-grid';

import { isNull, isUndefined } from 'lodash';
export default {
    name: "PlatKartaView",

    components: {
        DxRequiredRule,
        DxNumericRule,
        DxRangeRule,
        DxValidator,
        DxCustomRule,
        Employees,
        Suppliers,
        Attachments,
        DxTextBox,
        DxNumberBox,
        ChangeOwner,
        DxSummary,
        DxTotalItem,
        DxStateStoring,
        DxExport,
        DxColumnFixing,
        DxLoadPanel,
        DxToolbar,
        HelpLink,
        DxDateRangeBox,
        DxSorting,
        DxGridButton
    },

    data() {
        return {
            //  pattern: /^\d{}/i,
            smallScreen: false,
            formData: {
                cardID: '',
                ucel: '',
                cena: null,
                adresa: '',
                datum: null,
                komentar: '',
                dodavatel: [{ content: '', id: -1 }],
                dodavatelTXT: "",
                cardOwner: '',
                typPouziti: null,
                typPouzitiTXT: '',
                TypUhradyIDENT:null,
                Grant: null,
                ostatniGranty:false,
                souhlas:null,
                souhlasElektroDokument:false,
                MenaTXT:'',
                PracCesty: [],
                pracCesta: null,
                
            },
            Cesta: false,
            validaceCelkem: true,
            polozkyCelkem:0,
            sloupceVisible: 0,
            zam: [],
            zeme: [],
            meny: [],
            uplatneni: [],
            allgranty: [],
            granty: [],
            mena: [{ "content": 'CZK', "id": 129 }],
            menaid: null,
            PocetKolonek: 9,  // kolikrat se zavola setselectedvalue, pokud je komentar vyplneny, dava se PocetKolonek++
            MountingData: false,
            stateid: 4,
            state: 'Uložený',
            popupAlert: false,
            popupAlertTemplate: '',
            actionsPozadavky: [],
            Via: [{ content: '', id: -1 }],
            suppliers: [],
            supplier: [{ content: '', id: -1 }],
            vias: [],
            poz: 0,
            pozCardPaymentID: 0,
            funding:0,
            via:0,
            zakladani: true,
            cardOwners: [],
            AddressVisible: false,
            GridRows: [],
            MountCount: 0,
            typyKartyUplatneni:[],
            messageNumberCustomRulePositive:'',
            messageNumberCustomRuleNeZakladani:'',
            messageValidaceDatumDo:'',
            messageValidaceDatumOd:'',
            datumCestaOd:'',
            datumCestaDo:'',
            ValidatorEvent:null,
            dataGridRefKey,
            textFormaDokladu:'',
            translationKey: "Pozadavky.Platkarta.vysvetlivkyKDokladu",
            visibleAttachmentsElectronicsDocs:false,
            hasValidationError: false,
        }
    },
    computed: {
        dataGrid: function () {
      return this.$refs[dataGridRefKey].instance;
    },
    isCreatingAndElectroDoc: function(){
        if ((this.zakladani) && ((this.formdata.souhlasElektroDokument) || (this.formdata.souhlasElektroDokument==1))) { 
        return true } else return false;
    },
    localizedMessage() {
      if (window.localStorage.getItem("jazyk") == 'en')
    { 
      return (
        this.$t('pozadavky-HodnotaMezi') +
        ' ' +
        this.$decimalLimits.min.toLocaleString('en-US', { minimumFractionDigits: 2 }) +
        ' — ' +
        this.$decimalLimits.max.toLocaleString('en-US', { minimumFractionDigits: 2 })
      );
    }
      else {
        return (
        this.$t('pozadavky-HodnotaMezi') +
        ' ' +
        this.$decimalLimits.min.toLocaleString('cs-CZ', { minimumFractionDigits: 2 }) +
        ' — ' +
        this.$decimalLimits.max.toLocaleString('cs-CZ', { minimumFractionDigits: 2 })
      );
      } 
    },
       
    },
    watch: {
    },
    created() {
    },
    beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
    mounted() { 
        this.ListAllFunding();
        this.ListFunding();
        this.GetKartyUplatneni();
        this.ListTrips();
        this.Zeme();
        this.Meny();
        this.Zam();
        this.Uplatneni();
        this.ListVias();
        this.CardOwners();
        this.MountingData = true
        if (window.localStorage.getItem("pozid") != '' && window.history.state.back!=null) {
            this.zakladani = false
            this.poz = window.localStorage.getItem("pozid")
            this.DisplayRequest();
            setTimeout(() => {
            // Přidáme nový řádek
            this.$refs.datagridplatkarta.instance.addRow();
                this.$nextTick(() => {
                var scrollContainer = this.$root.$refs.mainScrollApp.instance; 
                const positionBottom = scrollContainer.scrollHeight(); 
                scrollContainer.scrollTo(positionBottom);
                });

            }, 2000);

            
        }
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.LoadBarvyTheme();
        if (this.zakladani){this.formData.souhlas=false}
        
       
        if (!this.zakladani) {if (this.poz) this.GetCardPaymentNotice(this.poz);}
        /*  
           var wh = new window.WorkFlow(window.channel, window.message);
             wh.GetAdditionalFields(2526647,
               function (result) {
                 window.this.$log.debug(result);
               }, window.this.ApiError());
               
                wh.SetAdditionalField(2526736,'pozadavky_prac_cesta','cis_pouziti',666,
               function (result) {
                 window.this.$log.debug(result);
               }, window.this.ApiError());
            */

    },
    methods: {
        handleResize() {
      this.smallScreen = window.innerWidth < 1000; 
    },
        handleValidation(e) {
        this.$log.debug(e)
        this.hasValidationError = !e.isValid;
        },
        ListTrips() { 
            let self = this;
            self.$log.debug('ListTrips')
            self.formData.PracCesty.length = 0;
            var wf = new window.WorkFlow(window.channel, window.message);
        //     var vyberListType = 3;
            //    if (self.formData.ostatniGranty == true) { vyberListType = 0 } else { vyberListType = 3 }
            wf.ListLookupTrips(0, 0,
                function (result) {
                     self.$log.debug('ListTrips')
                    self.$log.debug(result);
                    for (var x = 0; x < result.Items.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        self.formData.PracCesty[x] = {
                            content: result.Items.value.items[x].TripTXT.value,
                            id: result.Items.value.items[x].PolID.value,
                            DateFrom: result.Items.value.items[x].DateFrom.value,
                            DateTo: result.Items.value.items[x].DateTo.value,
                            RequestGroup: result.Items.value.items[x].RequestGroup.value,
                            ZamId: result.Items.value.items[x].ZamId.value,
                            TripID: result.Items.value.items[x].TripID.value,
                            CountryNum: result.Items.value.items[x].CountryNum.value,
                        };
                        
                    }
                    //  if (self.zakladani && result.List.value.items.length == 1)
                    //      self.currentGrant = self.granty[0].id;
                    self.$refs.cestyselectbox.instance.getDataSource().reload()
                }, window.this.ApiError());
        },
        StringToHtml(input) {
            if (input.startsWith('"') && input.endsWith('"')) {
                this.$log.debug("inputSLICE",input.slice(1, -1))
                return input.slice(1, -1);
            } else {
                //  this.$log.debug("inputSLICENOT",input)
                return input;
            }
        },
        ValidateCenaNumberBox(){
            let result = this.$refs.myCenaNumberBoxValidator.instance.validate();
            this.$log.debug(this.$refs.myCenaNumberBoxValidator.instance,"this.$refs.myCenaNumberBoxValidator");
            this.$log.debug(result,"result of Validate")
            this.$refs.myCenaNumberBoxValidator.instance.validate();
        },
        ValidateCheckBoxSouhlas(){
            if (this.$refs.checkBoxSouhlas.instance){
            let result = this.$refs.checkBoxSouhlas.instance.validate();
            this.$log.debug(this.$refs.checkBoxSouhlas.instance,"this.$refs.checkBoxSouhlas.instance");
            this.$log.debug(result,"result of Validate")
            this.$refs.myCenaNumberBoxValidator.instance.validate();
        }
        },
        MATHvalidateNumber(e) {
            this.$log.debug(e);
            this.$log.debug(`${this.polozkyCelkem} total items`);
            this.$log.debug(`${e.value} total price`);
            var totalItems = parseFloat(this.polozkyCelkem);
            var totalPrice = parseFloat(e.value);
            if (isNaN(totalItems) || isNaN(totalPrice)) {
                this.$log.debug('Invalid input: Not a number');
                this.validationStatus = 'invalid';
                return false;
            }
            var tolerance = 0.01; 
            var isValid = Math.abs(totalItems - totalPrice) < tolerance;
            this.validationStatus = isValid ? 'valid' : 'invalid';
            return isValid;
        },
        setCestaOdDo(e){
            this.$log.debug(e,'setCestaOdDo');
            this.datumCestaOd=e.value[0];
            this.datumCestaDo=e.value[1];
            this.$log.debug(this.datumCestaOd,'datumCestaOd',this.datumCestaDo,'datumCestaDo');

        },
        SetTripGridRow(id) {        
            this.$log.debug(id)
            let foundItem = this.formData.PracCesty.find(item => item.id == id);
            this.$log.debug(foundItem)
                    // this.$refs.datagridplatkarta.instance.addRow()
                    this.$refs.datagridplatkarta.instance.cellValue(0, "Cena", null)
                    this.$refs.datagridplatkarta.instance.cellValue(0, "Grant", foundItem.RequestGroup)
                    this.$refs.datagridplatkarta.instance.cellValue(0, "TripID", foundItem.TripID)
                    this.$refs.datagridplatkarta.instance.cellValue(0, "Zeme", foundItem.CountryNum)
                   // this.$refs.datagridplatkarta.instance.cellValue(cellInfo.rowIndex, "Cesta", foundItem)
                    this.$refs.datagridplatkarta.instance.cellValue(0, "Id", foundItem.PolID)
                    this.$refs.datagridplatkarta.instance.cellValue(0, "PolId", foundItem.PolID)
                    this.$refs.datagridplatkarta.instance.cellValue(0, "ZamID", foundItem.ZamId)
                    this.datumCestaOd = foundItem.DateFrom;
                    this.datumCestaDo = foundItem.DateTo;
                    
        },
        IdentPlatKartyJeOstatni() {
            var vysledek = false;
            
                    //this.$log.debug('IdentPlatKartyJeOstatni START',vysledek);
                    if (this.formData.typPouziti) {
                        this.$log.debug('IdentPlatKartyJeOstatni this.formData.typPouziti',this.formData.typPouziti);
                        let foundItem = this.typyKartyUplatneni.find(item => item.id == this.formData.typPouziti);
                        if (foundItem) {
                            //this.$log.debug("Item found: ", foundItem);
                            //this.$log.debug(foundItem.ident);
                            if (foundItem.ident == 'PlatKartOstatni') {vysledek = true } else {vysledek = false} } 
                        else {vysledek = false}
                    } else {
                        if (this.formData.TypUhradyIDENT == 'PlatKartOstatni') { vysledek = true } else { vysledek = false }
                    }
                    //this.$log.debug('IdentPlatKartyJeOstatni END',vysledek);
                    this.Cesta = !vysledek;
                    return vysledek;
                },
                validateNumberPositive(e) {
                    this.ValidatorEvent=e;
                        this.$log.debug(e.value+' celkova cena')
                    const isValid = e.value > 0;
                    this.validationStatus = isValid ? 'valid' : 'invalid';
                    this.messageNumberCustomRulePositive=this.$t('Pozadavky.Platkarta.ValidaceSumyZakladani');
                    return isValid;
           },   
        validateNumberNeZakladani(e) {
                this.ValidatorEvent=e;
                if(!this.zakladani){
                    this.$log.debug(e)    
                this.$log.debug(this.polozkyCelkem+' polozkycelkem')
                this.$log.debug(e.value+' celkova cena')
                const isValid =this.polozkyCelkem == e.value
                this.validationStatus = isValid ? 'valid' : 'invalid';
                
                this.messageNumberCustomRuleNeZakladani = this.$t("Pozadavky.Platkarta.ValidaceCelkem") +
                                                        ' (' + this.formatStringWithSpaces(this.polozkyCelkem) + ')' +
                                                        ". " + this.$t("Pozadavky.Platkarta.KontrolaUlozeni");
                                                        

                
                return isValid;
                } else return 'invalid'
        },
        validateDatumOd(e) {
            this.$log.debug(e, 'validateDatumOd');
            const startDate = new Date(e.data.DatumOd);
            const endDate = new Date(e.data.DatumDo);
            let isValid = true;
            if (isNaN(startDate.getTime())) {
                isValid = false;
                this.messageValidaceDatumOd = this.$t("Pozadavky.Platkarta.NeplatnaCestaOd");
            }
            else if (startDate > endDate) {
                isValid = false;
                this.messageValidaceDatumOd = this.$t("Pozadavky.Platkarta.CestaOdZaCestaDo");
            }
            this.validationStatus = isValid ? 'valid' : 'invalid';

            if (isValid) {
                this.messageValidaceDatumOd = this.$t("Pozadavky.Platkarta.okCestaOd");
            }
            return isValid;
        },
        validateDatumDo(e) {
            this.$log.debug(e, 'validateDatumdo');
            const startDate = new Date(e.data.DatumOd);
            const endDate = new Date(e.data.DatumDo);
            let isValid = true;
            if (isNaN(endDate.getTime())) {
                isValid = false;
                this.messageValidaceDatumDo = this.$t("Pozadavky.Platkarta.NeplatnaCestaDo");
            }
            else if (startDate > endDate) {
                isValid = false;
                this.messageValidaceDatumDo = this.$t("Pozadavky.Platkarta.CestaOdZaCestaDo");
            }
            this.validationStatus = isValid ? 'valid' : 'invalid';

            if (isValid) {
                this.messageValidaceDatumDo = this.$t("Pozadavky.Platkarta.okCestaDo");
            }
            return isValid;
        },
        GetKartyUplatneni(){
            let self = this;
            var gen = new window.General(window.channel, window.message);
                gen.ListLookupDetail('KartyUplatneni', 1, 25,
                    function (result) {
                        window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.typyKartyUplatneni.length;
                        self.typyKartyUplatneni[i] = {
                        content: result.List.value.items[x].Value.value,
                        id: result.List.value.items[x].Id.value,
                        idx: i,
                        ident: result.List.value.items[x].Ident.value,
                        };
                    }
                    }, window.this.ApiError());
        },
        validateZam(e) {
            this.$log.debug(e)
            var result = e.value>0
            return result;
        },
        SetEmployeeInfo(value, cellInfo){
            this.$log.debug(value)
            this.$log.debug(cellInfo)
            if(value.value != undefined) cellInfo.setValue(value.value)
        },
        AddRowButtonClick(e) {
            this.$log.debug(e)
            this.$refs.datagridplatkarta.instance.addRow();
           
        },
        InitNewRow(e) {
            this.$log.debug(e, 'InitNewRow')
           // this.$refs.datagridplatkarta.instance.addRow({ Cena: 12345 });
           if(e.component.getDataSource().items().length===0) e.data.Cena=this.formData.cena;
            if (e.key == undefined) {
               e.data.ZamID = -1; 
              
            }
            else { 
                this.datumCestaOd=e.data.DatumOd
                this.datumCestaDo= e.data.DatumDo
            }

            
        },
        GridEditting(data) {
            // var self = this;
            window.this.$log.debug(data)
            if (data.changes.length != 0) {
                switch (data.changes[0].type) {
                    case 'insert':
                         this.$log.debug('insert')
                        this.NewCardPaymentNoticeItem(this.pozCardPaymentID, 0, data.changes[0].data.TripID, data.changes[0].data.Zeme, this.datumCestaOd,
                        this.datumCestaDo, data.changes[0].data.ZamID, data.changes[0].data.Cena, data.changes[0].data.Grant)
                        break;
                        
                    case 'update': 
                        this.$log.debug('update')
                        this.NewCardPaymentNoticeItem(this.pozCardPaymentID, data.changes[0].key, data.changes[0].data.TripID, data.changes[0].data.Zeme, this.datumCestaOd,
                        this.datumCestaDo, data.changes[0].data.ZamID, data.changes[0].data.Cena, data.changes[0].data.Grant)
                        break;
                    case 'remove':
                        window.this.$log.debug("remove")
                        window.this.$log.debug(data.changes[0].key)
                        this.DeleteCardPaymentNoticeItem(data.changes[0].key)
                        break;
                }
                setTimeout(() => {
                        this.ValidateCenaNumberBox();
                    }, 2000);
            }
        },
        Zam() {
            let self = this;
            self.zam.length = 0
            var gen = new window.General(window.channel, window.message);
            gen.ListEmp('',
                function (result) {
                    //  window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.zam.length;

                        self.zam[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }
                    
                }, window.this.ApiError());
        },
        Meny() {
            let self = this;
            self.meny.length = 0
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('Meny', '', 1, 25,
                function (result) {
                    //  window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.meny.length;

                        self.meny[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }
                    
                }, window.this.ApiError());
        },
        Zeme() {
            let self = this;
            self.zeme.length = 0
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('Zeme', '', 1, 25,
                function (result) {
                    //  window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.zeme.length;

                        self.zeme[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }
                    
                }, window.this.ApiError());
        },
        Uplatneni() {
            let self = this;
            self.uplatneni.length = 0
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('KartyUplatneni', '', 1, 25,
                function (result) {
                    //  window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.uplatneni.length;

                        self.uplatneni[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }
                }, window.this.ApiError());
        },
        GridLayoutReset(e) {
            this.$log.debug(e)
            this.$refs.datagridplatkarta.instance.state(null);
        },
        LoadBarvyTheme() {
            var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
            var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
            var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
            var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.25)");
            //console.log('Background Color:', FillColor);
            document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
            document.documentElement.style.setProperty('--colorByThemeQuarterOpacity', rgbaColorTheme);
        },
        SetState(stav, alert) {
            window.this.$log.debug(alert + ' alert<<<??>')
            let self = this;
            var wh = new window.WorkFlow(window.channel, window.message);

            if (stav == -1) {
                window.this.$log.debug('konec')
                this.popupAlert = true
                this.popupAlertTemplate = 'SmazatPoz'
                return 1;
            }
            if (alert == true) stav = -1
            if( stav==0 && !this.validaceCelkem){ 
                notify({
                            message: self.$t('Pozadavky.Platkarta.ValidaceCelkem')+' ('+this.formatStringWithSpaces(this.polozkyCelkem)+')',
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 5000);
                return 2;
            }
            // self.open();
            wh.SetState(self.poz, stav, '',
                function (result) {
                    window.this.$log.debug(result);
                    if (result.ErrorNum.value != 0) {
                        notify({
                            message: result.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        self.DisplayRequest();
                        if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4)) self.$router.push('/Pozadavky?Vypis=0')

                        var stavText;
                        switch (stav) {
                            case -1: stavText = self.$t('pozadavky-StavZrusen')
                                break;
                            case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                                break;
                            case 4: stavText = self.$t('pozadavky-StavUlozeny')
                                break;
                        }
                        notify({
                            message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 5000);
                    }
                    //   self.hide();
                }, window.this.ApiError());

        },
        GetCardPaymentNoticeID(id) {
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetCardPaymentNotice(id,
                function (result) {
                    self.$log.debug(result,'GetCardPaymentNoticeID');
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.pozCardPaymentID = result.RequestCardID.value                    
                    }
                }, window.this.ApiError());
        },
        GetCardPaymentNotice(id) {
            var celkempolozky=0;
            let self = this
            this.GridRows.length=0;
            let self2 = this.GridRows;
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetCardPaymentNotice(id,
                function (result) {
                    self.$log.debug(result,'GetCardPaymentNotice');
                    if (result.Error.value.ErrorNum.value != 0) {
                        self.$log.debug('B_BOD GetCardPaymentNotice');
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.$log.debug('A_BOD GetCardPaymentNotice');
                        self.pozCardPaymentID = result.RequestCardID.value
                        self.formData.cardID = result.CardID.value
                        self.formData.ucel = result.PurposeTXT.value
                        self.formData.cena = parseFloat(result.Price.value).toFixed(2);
                        self.formData.datum = result.DateOfPayment.value
                        self.menaid = result.Currency.value
                        self.formData.typPouziti = result.TypeOfCardUse.value
                        self.formData.TypUhradyIDENT=result.TypeOfCardUseIDENT.value;
                        self.formData.MenaTXT= result.CurrencyTXT.value;
                       // self.formData.Grant = result.Request.value.Funding.value
                      //  self.formData.cardOwner = result.CardOwner.value
                        if(result.Supplier.value==0) self.formData.adresa = result.SupplierOtherTXT.value
                      //  if (self.formData.adresa != '') self.PocetKolonek++
                        self.formData.komentar = result.Request.value.Comment.value
                        if (self.formData.komentar != '') self.PocetKolonek++
                        self.formData.dodavatel = {
                            content: result.SupplierTXT.value,
                            id: result.Supplier.value,
                        };
                        if(result.Consent.value==1) {result.Consent.value==true} else {result.Consent.value==false}
                        self.formData.souhlas= result.Consent.value;
                      //  if (result.ConsentElectroDocument.value == 0) self.PocetKolonek++
                        self.formData.souhlasElektroDokument= result.ConsentElectroDocument.value;
                        self.supplier = self.formData.dodavatel
                        self.$log.debug('B_BOD GetCardPaymentNotice');
                        for (var x = 0; x < result.Items.value.items.length; x++) {
                            self.$log.debug('BB_BOD GetCardPaymentNotice');
                            //  self.$log.debug(result.List.value.items[x]);
                            var i = self2.length;
                          //  self.$log.debug('c_BOD GetCardPaymentNotice',result.Items.value.items[x]);
                                var dateFrom=result.Items.value.items[x].DateFrom.value;
                                var dateTo=result.Items.value.items[x].DateTo.value;
                                var tempTripID = result.Items.value.items[x].TripID.value;
                                var tempZeme = result.Items.value.items[x].CountryNum.value;
                                self.$log.debug('C_BOD GetCardPaymentNotice');
                            if (!dateFrom || new Date(dateFrom).getFullYear() < 1900) {
                                dateFrom = '';
                            }
                            self.$log.debug('D_BOD GetCardPaymentNotice');
                            if (!dateTo || new Date(dateTo).getFullYear() < 1900) {
                                dateTo = '';
                            }
                            self.$log.debug('E_BOD GetCardPaymentNotice');
                            if (!tempTripID || tempTripID == 0) {
                                tempTripID = null;
                            }
                            self.$log.debug('G_BOD GetCardPaymentNotice');
                            if (!tempZeme || tempZeme == 0) {
                                tempZeme = null;
                            }
                            self.$log.debug('H_BOD GetCardPaymentNotice');
                            celkempolozky = celkempolozky + result.Items.value.items[x].Value.value;
                            self.$log.debug('I_BOD GetCardPaymentNotice');
                            self2[i] = {
                                Zeme: tempZeme,
                                Cesta: {
                                    start: dateFrom,
                                    end: dateTo
                                },
                                DatumOd:dateFrom,
                                DatumDo:dateTo,
                                Grant: result.Items.value.items[x].RequestGroup.value,
                                TripID: tempTripID,
                                Cena: result.Items.value.items[x].Value.value,
                                ZamID: result.Items.value.items[x].ZamId.value,
                                Id: result.Items.value.items[x].PolID.value
                                        //  ZemeTXT: result.Items.value.items[x].CountryTXT.value,
                              //  Mena: result.Items.value.items[x].Currency.value,
                            //    MenaTXT: result.Items.value.items[x].CurrencyTXT.value,
                            //   GrantTXT: result.Items.value.items[x].RequestGroupTXT.value,
                            //  TripTXT: result.Items.value.items[x].TripTXT.value,
                            //  ZamTXT: result.Items.value.items[x].ZamTXT.value,

                            };
                        }
                        self.$log.debug('TOTO JSOU ITEMS z GetCardPaymentNotice',self2);
                        self.polozkyCelkem=celkempolozky
                        self.$log.debug(celkempolozky+' celkem polozky GetCardPaymentNotice')
                      //  self.GrantInDatasource()
                        setTimeout(() => {                        
                                self.$refs.datagridplatkarta.instance.getDataSource().reload();   
                            }, 500);

                    }
                }, window.this.ApiError());
        },
        GrantInDatasource(){
            const isGrantInArray = this.granty.some(grant => grant.id === this.formData.Grant);
            if (isGrantInArray) {
            this.$log.debug('Hodnota je v poli.');
            this.formData.ostatniGranty = false
            } else { 
            this.$log.debug('Hodnota není v poli.');
            this.formData.ostatniGranty = true
            }
        },
        ListVias() {
            let self = this;
            self.vias.length = 0;
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('REQUEST_VIAS[2]', '', 1, 50,
                function (result) {
                    window.this.$log.debug(result);
                    /*
                    for (var x = 0; x < result.List.value.items.length; x++) {
          
                      //  window.this.$log.debug(result.List.value.items[x]);
                      var i = self.vias.length;
                      self.vias[i] = {
                        content: result.List.value.items[x].Value.value,
                        id: result.List.value.items[x].Id.value,
                      };
                    }
                    */
                    window.this.$log.debug(self.zakladani);
                    


                }, window.this.ApiError());

        },
    ListFunding() {
      let self = this;
      self.granty.length = 0;
      var wf = new window.WorkFlow(window.channel, window.message);
      var vyberListType=3;
      if (self.formData.ostatniGranty==true) {vyberListType=0} else {vyberListType=3} 
      wf.ListFunding(1, vyberListType,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            self.granty[x] = {
              content: result.List.value.items[x].Text.value + ' ' + result.List.value.items[x].Name.value,
              //  number: result.List.value.items[x].Text.value,
              id: result.List.value.items[x].ID.value,
            };
          }
          self.granty[self.granty.length] = {
            content: self.$t('central-GrantNevybran'),
            id: 0,
          }
          
          if(self.zakladani && result.List.value.items.length == 1 )
             self.currentGrant = self.granty[0].id;
            self.$refs.grantyselectbox.instance.getDataSource().reload()
        }, window.this.ApiError());
    },
        ListAllFunding() {
      let self = this;
      self.allgranty.length = 0;
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.ListFunding(1, 0,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            self.allgranty[x] = {
              content: result.List.value.items[x].Text.value + ' ' + result.List.value.items[x].Name.value,
              //  number: result.List.value.items[x].Text.value,
              id: result.List.value.items[x].ID.value,
            };
          }
          self.allgranty[self.allgranty.length] = {
            content: self.$t('central-GrantNevybran'),
            id: 0,
          }
        }, window.this.ApiError());
    },
        DisplayRequest() {
            window.this.$log.debug('display request')
            let self = this;
            self.actionsPozadavky.length = 0;
            var wh = new window.WorkFlow(window.channel, window.message);
            wh.GetRequest(self.poz,
                function (result2) {
                    window.this.$log.debug(result2);
                    self.state = result2.ReqStateText.value;
                    self.stateid = result2.ReqState.value;
                    for (var x = 0; x < result2.Actions.value.items.length; x++) {
                        var id = result2.Actions.value.items[x].Id.value;
                        var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
                        var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
                        self.actionsPozadavky[x] = {
                            nazev: result2.Actions.value.items[x].Value.value,
                            id: result2.Actions.value.items[x].Id.value,
                            class: colorClass,
                            icon: iconName,
                            pozid: self.poz
                        };
                    }
                }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
        },
        onFormSubmit(e) {

            window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
            window.this.$log.debug(e)
            this.SetCardPaymentNotice();
            e.preventDefault();
        },
        CardOwners() {
            let self = this;
            var gen = new window.General(window.channel, window.message);
            self.cardOwners.length = 0
            gen.ListLookup('CARD_OWNERS', '', 0, 25,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.cardOwners.length;
                        self.cardOwners[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }

                }, window.this.ApiError());
        },
        setSelectedValue(e) {
            this.MountCount++
            window.this.$log.debug(e);
            window.this.$log.debug(this.MountCount + 'MountCount');
            window.this.$log.debug(this.PocetKolonek + 'Dokolika');
            if (this.MountCount == this.PocetKolonek) this.MountingData = false // tohle neni uplne dobry napad delat
            switch (e.element.id) {
                case '1':
                    if (e.value == null) return 1;
                    this.formData.cardID = e.value
                    break;
                case '2':
                    if (e.value == '') return 1;
                    this.formData.ucel = e.value
                    break;
                case '3':
                    this.formData.adresa = e.value
                    if (e.value == '') return 1;
                    break;
                case '4':
                    this.formData.cena = e.value
                    break;
                case '5':
                    this.menaid = e.value
                    break;
                case '6':
                    this.formData.datum = e.value
                    break;
                case '7':
                    this.formData.typPouziti = e.value
                    if (this.IdentPlatKartyJeOstatni()) {this.sloupceVisible = 0;}
                    else {this.sloupceVisible = 1;}
                    if(this.$refs.datagridplatkarta!=undefined) {this.$refs.datagridplatkarta.instance.refresh();}
                    break;
                case '8':
                    this.formData.Grant = e.value
                    break;
                case '9':
                    this.formData.ostatniGranty = e.value
                    window.this.$log.debug( this.formData.ostatniGranty);
                    window.this.$log.debug('ostatniGranty');
                    this.ChangeDataSource(e.value);
                    return 2;
                case '10':
                    this.formData.souhlas=e.value
                    window.this.$log.debug(this.formData.souhlas);
                    window.this.$log.debug('Souhlas'); break;
                case '11':
                    this.formData.souhlasElektroDokument=e.value
                    window.this.$log.debug(this.formData.souhlasElektroDokument);
                    window.this.$log.debug('souhlasElektroDokument'); break;
                case '12':
                    this.formData.pracCesta = e.value
                    window.this.$log.debug(this.formData.pracCesta);
                    window.this.$log.debug('Souhlas'); break;    
                    case '13':
                    this.hasValidationError = false
                    break;    
                case 'suppliers':
                    if (e.value == null) {
                        this.$log.debug('supplier null')
                        break;
                    }
                    if (e.value.id == undefined || e.value.id == null || e.value.id == 0) {
                        this.AddressVisible = true
                    }
                    else {
                        this.AddressVisible = false    
                    }
                    this.formData.dodavatel = e.value
                    //this.zverejnit = e.value
                    break;
                case 'ownerchanged':
                    this.$router.push('Pozadavky')
                    return 1;
            }
            if (!isUndefined(e.value) && !isNull(e.value) && !this.zakladani && !this.MountingData && !this.hasValidationError)
                this.SetCardPaymentNotice()
        },
        ChangeDataSource(e){
            this.$log.debug(e)
            if(e.value) this.$refs.grantyselectbox.instance.option("dataSource",this.allgranty)
            else {
                
                const isGrantInArray = this.granty.some(grant => grant.id === this.formData.Grant);
                if (!isGrantInArray) {
                this.$refs.grantyselectbox.instance.option('value',0); 
                }
                this.$refs.grantyselectbox.instance.option("dataSource",this.granty)
            }
            this.$refs.grantyselectbox.instance.getDataSource().reload()        
        },        
        SetCardPaymentNotice() {
            var self = this;
            var wh = new window.WorkFlow(window.channel, window.message);
            var dodavatel;
            window.this.$log.debug(this.formData.dodavatel)
            if (!isUndefined(this.formData.dodavatel.id)){
                dodavatel = this.formData.dodavatel.id;
                //this.formData.adresa = ''
            }               
            else dodavatel = undefined
            var dodavatelTXT = ''
          //  window.this.$log.debug(this.formData.dodavatel)
            if (dodavatel == undefined || dodavatel == null || dodavatel == 0) {
                dodavatelTXT = this.formData.dodavatel.content;
                dodavatel = 0;
            }
            //  0, 0, '', 0, '', '', 1000, 6374, 175811, 175812, true, '',
            this.$log.debug(this.formData)
            /*
            	PaymentCardID	Integer	
In	PurposeTXT	WideString	
In	Supplier	Integer	
In	Price	Double	
In	Currency	Integer	
In	DateOfPayment	DateTime	
In	TypeOfCardUse	Integer	
In	SupplierTXT	WideString	

            */
           var souhlasToSet;
            if (this.formData.souhlas==true){souhlasToSet=1} else {souhlasToSet=0}
           var souhlasElektroDokument;
            if (this.formData.souhlasElektroDokument==true){souhlasElektroDokument=1} else {souhlasElektroDokument=0}
                    window.this.$log.debug(this.formData.souhlas, "this.formData.souhlas");
            wh.SetCardPaymentNotice(self.poz,self.via, self.formData.cardID, self.formData.ucel, dodavatel, self.formData.cena, self.menaid,
                self.formData.datum, self.formData.typPouziti, dodavatelTXT, self.formData.adresa, self.formData.komentar, souhlasToSet, souhlasElektroDokument, 
                function (result) {

                    window.this.$log.debug(result);

                    if (result.Error.value.ErrorNum.value == 0) {
                        //console.log(self.poz)
                        if (self.poz == 0) {
                            
                            self.poz = result.Value.value
                            window.localStorage.setItem("pozid", result.Value.value)
                            if(self.zakladani) self.GetCardPaymentNoticeID(self.poz)
                            self.zakladani = false
                            self.MountingData = false
                            setTimeout(() => {
                                self.$refs.datagridplatkarta.instance.addRow()
                                self.$nextTick(() => {
                                var scrollContainer = self.$root.$refs.mainScrollApp.instance; 
                                const positionBottom = scrollContainer.scrollHeight(); 
                                scrollContainer.scrollTo(positionBottom);
                                });
                               if(self.Cesta && self.formData.pracCesta!=null ) self.SetTripGridRow(self.formData.pracCesta)
                            }, 200);
                            notify({
                                message: self.$t('Pozadavky.Platkarta.Zalozena'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 3000);
                            self.DisplayRequest();  
                        }
                        else {
                            notify({
                                message: self.$t('Pozadavky.Platkarta.ZmenyUlozeny'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 3000);
                        }
                        
                    }
                    else {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 3000);

                    }
                }, window.this.ApiError());
        },
        DeleteCardPaymentNoticeItem(itemkey){
            var wf = new window.WorkFlow(window.channel, window.message);
            let self = this;
            wf.DeleteCardPaymentNoticeItem(itemkey,
                function (result) {
                    window.this.$log.debug(result);
                    if (result.Error.value.ErrorNum.value == 0) {

                        notify({
                            message: self.$t('Pozadavky.Platkarta.Smazano'),
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 3000);

                    }
                    else {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 3000);

                    }
                    self.GetCardPaymentNotice(self.poz);
                }, window.this.ApiError());
            
        }
        ,
        NewCardPaymentNoticeItem(cardPaymentID, itemID, TripID, zeme, datumOd, datumDo, zamID, cena, grant) {
            var wf = new window.WorkFlow(window.channel, window.message);
            let self = this;          
            if (this.sloupceVisible != 1) {
                TripID = 0
                datumOd = ''
                datumDo = ''
                zeme = 0
                zamID = -1
            }
            wf.SetCardPaymentNoticeItem(self.poz,cardPaymentID, itemID, TripID, zeme, datumOd, datumDo, zamID, cena, grant,
                function (result) {
                    window.this.$log.debug(result);
                    if (result.Error.value.ErrorNum.value == 0) {
                        window.NelzeSchvalovat = false

                        notify({
                            message: self.$t('Pozadavky.Platkarta.ZmenyUlozeny'),
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 3000);

                    }
                    else {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 3000);

                    }
                    self.GetCardPaymentNotice(self.poz);
                    setTimeout(() => {
                        self.ValidateCenaNumberBox();
                    }, 2000);
                }, window.this.ApiError());
        },
        formatStringWithSpaces(input) {
    if ((typeof input === 'number') || (typeof input === 'string' && !isNaN(parseFloat(input)) && isFinite(input))) {
        let parts = input.toString().split('.');
        let cleanedString = parts[0].replace(/[^0-9]/g, '');

        // Initialize the result string
        let result = '';

        // Iterate over the cleaned string and add spaces every three characters
        for (let i = cleanedString.length - 1, count = 0; i >= 0; i--) {
            result = cleanedString[i] + result;
            count++;

            if (count % 3 === 0 && i > 0) {
                result = ' ' + result;
            }
        }

        // Append the decimal part back if it exists
        if (parts.length > 1) {
            result += ',' + parts[1];
        }

        return result;
    } else return input;
},
calculateCestaCellValue(rowData){
    return this.returnDateFormatFromString(rowData.DatumOd) +' -> '+this.returnDateFormatFromString(rowData.DatumDo);
},returnDateFormatFromString(e){
            const date = new Date(e);
            if (isNaN(date.getTime())) { return ''; }
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); 
            const year = date.getFullYear();
            var formattedDate = ''
            if (year<=2000){formattedDate = ''}else {formattedDate = `${day}. ${month}. ${year}`;}
            
            
            return formattedDate;

        },
        
    }
};
</script>



<style scoped> 
/*
// barva save
:deep(.dx-datagrid .dx-link.dx-link-save) {
  background-color: green !important; 
  color: white !important;
  padding: 5px 10px;
  border-radius: 10px;
  text-decoration: none; 
}
*/

:deep(.dx-datagrid .dx-link:not(.dx-link-icon)) {
  position: relative;
  top: -1px;  /* posun textu nahoru */
  margin-left: 0px !important;
  margin-right: 10px !important;
}
:deep(.dx-datagrid .dx-link.dx-link-icon) {
  margin-right: 0px !important;
}
/* Příklad – změna barvy ikony "save" 
:deep(.dx-datagrid .dx-link.dx-icon-save.dx-link-icon) {
  color: green !important;
  
}
*/
:deep(.dx-daterangebox .dx-dropdowneditor-icon::before){
    content: "\f026";
    
}
:deep(.dx-icon-to::before) {
    content: "\f00e";
}.ResponsiveBoxClass {
     padding: 10px;
 }
#ObnovitRozlozeni{scale: 85%; color: inherit; border-color: gray;}
#PridatZaznam{background-color: rgb(0, 214, 0); scale: 85%;}
#PridatZaznam:hover{background-color: rgb(0, 197, 0);
scale: 85%;}
#obaleniDataGridu{border-style: ridge;
    border-color: rgba(128, 128, 128, 0.1);
    border-radius: 10px;
    padding: 10px;}
 #DruhyResponsiveBox {
     border-style: ridge;
     border-color: rgba(128, 128, 128, 0.1);
     border-radius: 10px;

     background-color: rgba(128, 128, 128, 0.075);
     flex: none !important;
 }

 #obaleniFormulare2 {
     /*padding: 10px;*/
     margin-bottom: 10px;
 }

 .SmlouvaBox {
     padding: 5px;
 }

 #obalenigrid {
     padding: 10px;
 }

 #SmlouvaResponsiveBox {
     border-style: ridge;
     border-color: rgba(128, 128, 128, 0.1);
     border-radius: 10px;
     background-color: rgba(128, 128, 128, 0.075);
     padding: 5px;
     margin-bottom: 10px;
 }

 #checkboxzverejnit {
     padding-top: 25px;

 }

 .checkboxzverejnitZvyraz {
     padding: 5px;
     border-style: ridge;
     border-color: rgba(46, 46, 46, 0.1);
     border-radius: 10px;
     background-color: rgba(170, 3, 3, 0.459);
     width: max-content;
     font-weight: bold;
 }

 .buttonDefault:hover,
 .buttonUlozitProUpravy:hover,
 .buttonKeSchvaleni:hover,
 .buttonSmazat:hover,
 .buttonKEditaci:hover {
     background-color: var(--colorByThemeLighter);
     border: 0px;

 }

 .buttonKeSchvaleni {
     background: #5cb83c;
     border-color: none;
     border: 0px;
 }

 .buttonUlozitProUpravy {
     background: #3cb8b8;
     border-color: none;
     border: 0px;
 }

 .buttonSmazat {
     background: #d9534f;
     border-color: none;
     border: 0px;
 }

 .buttonKEditaci {
     background: #ffcc33;
     border-color: none;
     border: 0px;
 }

 .buttonDefault {
     background: none;
     border-color: none;
     border: 0px;
 }
 .dxTextBoxAdresaDodavatel{
    margin-top: 10px !important;
    height:45px;
 }

 .suppliersComponenta,.dxTextBoxAdresaDodavatel{
    width: 100%;
 }
 .dx-command-edit{
   float:right !important; 
 }
 .cenaCelkem{
    padding-left:15px  !important;
    left: 0 !important;
    right: none !important;
 }
 :deep(.dx-icon-edit){
    color:orange !important
 }
 :deep(a.dx-link.dx-link-delete.dx-icon-trash.dx-link-icon){
    color:red !important
 }
 :deep(i.dx-icon.dx-icon-trash){
    color:none!important
 }
 :deep(.dx-icon-save){
    color:rgb(0, 214, 0) !important
 }
 #hrID{
    border-top: 2px dotted black;
    width: 100%;
    text-align: center;
 }

 .clCheckBoxFormaLAST{
    margin-bottom: 5px;
 }
 label#doplnujiciTextPrilohy{
 font-style: italic;
 padding: 5px 0 5px 5px
 }
 .GrayedOutBackgroundBorder{
    /*background-color: #80808045;*/
    border: 1px solid #808080;
    border-radius: 5px;
 }
</style>