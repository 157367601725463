<template>
    <div v-if="false" class="divOrangeWarning">
        <img class="warningIconOrange" :class="{ invertColorBasedOnTheme: isDarkThemeActive }" src="@/assets/images/alert.png" >
        <span class="warningText">{{translatedString}}</span>
    </div> 
    <DxPopup ref="popupinstructions" :title="$t('central.Aktualizace')" width="270px" height="auto" :showCloseButton="false"
        v-model:visible="PopupVisible" :drag-enabled="true" :focusStateEnabled="true" :shading="false">
        <DxToolbarItem v-if="false"
            widget="dxButton" 
            :options="buttonOptions" 
            location="after">
        </DxToolbarItem>
        <DxPosition at="top" my="center" collision="fit" :offset="{ y: 100, x: 0 }" />
     <!-- left top position
          <DxPosition at="left top" my="left top" collision="fit" of="#napovedaText" :offset="{ y: 0, x: -120 }" /> -->
          
        <!--<DxPosition at="left center" my="right center" collision="fit" of="#zmenavzhled"  :offset="{ y: -22, x: -5 }"/> -->
        <div>
            <div @click="NewVersionReload" class="divOrangeWarning">
                <img class="warningIconOrange" :class="{ invertColorBasedOnTheme: isDarkThemeActive }" src="@/assets/images/alert.png" >
                <span class="warningText">{{translatedString}}</span>
            </div> 
        </div>
    </DxPopup> 
</template>
  
<script>
//import notify from 'devextreme/ui/notify';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
export default {
    name: 'WarningImage',
    components: {
        DxPopup,
        DxToolbarItem
    },
    props: {
        Disabled: {
            type: Boolean,
            required: true
        },
        RequestID: {
            type: Number,
            required: true
        },
        PopisChybyKEY: {
            type: String,
            required: false,
            default: 'pracCesta.ZkontrolujtePlatnost'
        },
        ColorRGBWarningBox: {
            type: String,
            required: false,
            default: 'rgb(255, 136, 0)' // Ensure the default value is a string and enclosed in quotes
        },
        ColorRGBAnimation: {
            type: String,
            required: false,
            default: 'rgb(255, 136, 0)' // Ensure the default value is a string and enclosed in quotes
        },
        WidthIcon:{
            type: String,
            required: false,
            default: '16px'
        },
        HeightIcon:{
            type: String,
            required: false,
            default: '16px'
        },
        WidthMessage: {
            type: String,
            required: false,
            default: 'auto' 
        },
        TextColor: {
            type: String,
            required: false,
            default: 'rgb(255, 255, 255)' // Ensure the default value is a string and enclosed in quotes 
        },
        BackgroundColor: { 
            type: String,
            required: false,
            default: 'rgb(0, 0, 0)' // Ensure the default value is a string and enclosed in quotes
        },
        Position: {
            type: String,
            required: false,
            default: 'absolute' // Ensure the default value is a string and enclosed in quotes
        },
        Border: {
            type: String,
            required: false,
            default: 'none' // Ensure the default value is a string and enclosed in quotes
        },
        TextPadding: {
            type: String,
            required: false,
            default: '5px 0' // Ensure the default value is a string and enclosed in quotes
        },
        Cursor: {
            type: String,
            required: false,
            default: 'text' // Ensure the default value is a string and enclosed in quotes
        },

    },
    watch: {
    },
    computed: {
        translatedString() {
        return this.$t(this.PopisChybyKEY);
    }        

    },
    data() {
        return {
            buttonOptions: {
                text: '',
                icon: 'minus',
                onClick: this.TogglePopup
            },
            isDarkThemeActive: false, 
            observer: null,
            PopupVisible: false,
        };
    },
    mounted() {
        this.MountedSetup();
        this.checkTheme();
        this.startObserving(); 
        this.PopupVisible=true     
    },
    beforeUnmount(){
        this.stopObserving();   
    },
    
    methods: {
        NewVersionReload() {
            // window.location.reload();
            var a = window.location.href
            window.location.href = a
        },
        TogglePopup(e){
            var popupInstance=this.$refs.popupinstructions.instance
            var currentHeight = popupInstance.option('height');
            this.$log.debug(currentHeight)
            if (currentHeight > 50 || currentHeight== 'auto' ) {
                popupInstance.option('height', 50);
                e.component.option('icon', 'plus');
            } else {
                popupInstance.option('height', 'auto');
                e.component.option('icon', 'minus');
            }
        }, 
        MountedSetup() {
            if (this.ColorRGBWarningBox != this.ColorRGBWarningBox.default) {
                document.documentElement.style.setProperty('--colorOfTextInfoBoxByInput', this.ColorRGBWarningBox);
            }
            if (this.ColorRGBAnimation != this.ColorRGBAnimation.default) {
                document.documentElement.style.setProperty('--colorOfBackgroundBlinkByInput', this.ColorRGBAnimation);
            }
            if (this.WidthIcon != this.WidthIcon.default) {
                document.documentElement.style.setProperty('--widthOfIconByInput', this.WidthIcon);
            }
            if (this.HeightIcon != this.HeightIcon.default) {
                document.documentElement.style.setProperty('--heightOfIconByInput', this.HeightIcon);
            }         
            if (this.WidthMessage != this.WidthMessage.default) {
                document.documentElement.style.setProperty('--widthMessageByInput', this.WidthMessage);
            }   
            if (this.TextColor != this.TextColor.default) {
                document.documentElement.style.setProperty('--textColorByInput', this.TextColor);
            }   
            if (this.BackgroundColor != this.BackgroundColor.default) {
                this.$log.debug('backgroundcolor nastavovani' + this.BackgroundColor)
                this.$log.debug(this.BackgroundColor)
                document.documentElement.style.setProperty('--backgroundColorByInput', this.BackgroundColor);
            } 
            if (this.Position != this.Position.default) {
                document.documentElement.style.setProperty('--positionByInput', this.Position);
            } 
            if (this.Border != this.Border.default) {
                document.documentElement.style.setProperty('--borderByInput', this.Border);
            } 
            if (this.TextPadding != this.TextPadding.default) {
                document.documentElement.style.setProperty('--textPaddingByInput', this.TextPadding);
            } 
            if (this.Cursor != this.Cursor.default) {
                document.documentElement.style.setProperty('--cursorByInput', this.Cursor);
            } 
            
        },
        isDarkTheme() {
      const theme = window.localStorage.getItem("theme");
      this.$log.debug('isDarkTheme theme', theme);
      return theme ? theme.includes('dark') || theme.includes('contrast') : false;
    },
    checkTheme() {
      this.isDarkThemeActive = this.isDarkTheme();
    },
    startObserving() {
      const targetNode = document.documentElement;

      const config = { attributes: true, childList: false, subtree: false };

      this.observer = new MutationObserver(() => {
        this.checkTheme(); 
      });

      this.observer.observe(targetNode, config);
    },
    stopObserving() {
      if (this.observer) {
        this.observer.disconnect();
      }
    }
       
    },

};
</script>
<style scoped>
element {
    --colorOfBackgroundBlinkByInput: rgb(255, 136, 0);
}
.divOrangeWarning{display:flex;flex-direction:row;align-self:center}
.divOrangeWarning .warningText{
  visibility: visible; /* Zobrazeno i bez hoveru */
  width: var(--widthMessageByInput);
  background-color: var(--backgroundColorByInput);
  /*color: var(--textColorByInput);*/
  border-radius: 6px;
  padding: var(--textPaddingByInput);
  padding-left:5px;
  padding-right: 5px;
  position: var(--positionByInput);
  z-index: 2;
  border: var(--borderByInput);
  border-width: thin;
  border-color: rgb(255, 136, 0);
  font-size: small;
  text-align: center;
} 
.divOrangeWarning:hover .warningText {
  visibility: visible;
  cursor: var(--cursorByInput);
}
.divOrangeWarning:hover{
    cursor:pointer;
}
.warningIconOrange{border-radius: 10%;padding:1.5px; 
    height:var(--heightOfIconByInput);
    width:var(--widthOfIconByInput);
    align-self: center;margin-left: 5px;
    animation: blinkingwarning 3s linear infinite; 
}
.invertColorBasedOnTheme {
  filter: invert(1);
}
@keyframes blinkingwarning {
  50% {
   visibility:hidden;
   background-color: var(--colorOfBackgroundBlinkByInput);
  }
  100% {
    visibility:visible;
  }
}
@keyframes blinkingBorder {
  50% {
   visibility:hidden;
   border-color: var(--colorOfBackgroundBlinkByInput);
  }
  100% {
    visibility:visible;
  }
}
</style>
  