<template>
  <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000744685?lang=cs"
    URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000744685?lang=en" PopisLinku='clanky.hostKarta'>
  </HelpLink>
  <div height="calc(100% - 57px)">
    <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false"
      :hide-on-outside-click="true" :show-close-button="true" :title="$t('central-Varovani')"
      :contentTemplate="popupAlertTemplate" :width="400" :height="200">
      <template #SmazatPoz>
        <h3>{{ $t('majetekVyrazeni-OpravduSiPrejeteSmazat') }}</h3>
        <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained"
          :text="$t('central-Ano')" @click="SetState(-99, true); popupAlert = false" />
        <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained"
          :text="$t('central-Ne')" @click="popupAlert = false" />
      </template>
    </DxPopup>

    <div id="horniListaPozadavku">
      <DxButton icon="back" :text="$t('majetekVyrazeni-VratitSeZpet')" height="auto" type="outlined"
        styling-mode="contained" id="link" @click="$router.push('Pozadavky')" />
      <span class="nadpisH2Pozadavky fontAndColor prevent-select">
        {{ computedRequestTitle }}
      </span>
    </div>
    <form action="your-action" @submit="onFormSubmit($event)">
      <div id="obaleniFormulare">

        <DxResponsiveBox single-column-screen="xs" >
          <DxRow :ratio="1" />
          <DxRow :ratio="1" />
          <DxCol :ratio="3" />
          <DxCol :ratio="3" />
          <DxItem>
            <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
            <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
            <DxLocation :row="0" :col="0" :colspan="2" screen="sm" />
            <DxLocation :row="0" :col="0" :colspan="2" screen="xs" />
            <div class="obaleniFormularInner">
              <DxResponsiveBox single-column-screen="" id="SmlouvaResponsiveBox">
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxCol :ratio="1.5" />
                <DxCol :ratio="0.5" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxItem>
                  <DxLocation :row="0" :col="0" :colspan="10" screen="lg" />
                  <DxLocation :row="0" :col="0" :colspan="10" screen="md" />
                  <DxLocation :row="0" :col="0" :colspan="10" screen="sm" />
                  <DxLocation :row="0" :col="0" :colspan="10" screen="xs" />

                  <div class="SmlouvaBox">
                    <span class="form-part-title" id="host-info">{{ $t('Pozadavky.HostKarta.UdajeHost') }}</span>
                  </div>
                </DxItem>
                <DxItem>
                  <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
                  <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
                  <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                  <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />

                  <div class="SmlouvaBox">
                    <div class="dx-field">
                      <DxTextBox :disabled="stateid != 4" :id="1" width='auto' height="45px"
                        :label="$t('Pozadavky.HostKarta.Prijmeni')" :maxLength="255" placeholder=""
                        @value-changed="setSelectedValue" :value="formData.prijmeni" v-model:value="formData.prijmeni">
                        <DxValidator @validated="handleValidation">
                          <DxRequiredRule :message="$t('Pozadavky.HostKarta.VyplntePrijmeni')" />
                        </DxValidator>
                      </DxTextBox>

                    </div>
                  </div>
                </DxItem>

                <DxItem width="400px">
                  <DxLocation :row="1" :col="5" :colspan="5" screen="lg" />
                  <DxLocation :row="1" :col="5" :colspan="5" screen="md" />
                  <DxLocation :row="1" :col="5" :colspan="5" screen="sm" />
                  <DxLocation :row="1" :col="5" :colspan="5" screen="xs" />

                  <div class="SmlouvaBox" width='auto'>
                    <DxTextBox style="margin-bottom: 0px;" :disabled="stateid != 4" height="45px" :id="2"
                      :label="$t('Pozadavky.HostKarta.Jmeno')" placeholder="" @value-changed="setSelectedValue"
                      :value="formData.jmeno" :maxLength="255">
                      <DxValidator @validated="handleValidation">
                        <DxRequiredRule :message="$t('Pozadavky.HostKarta.VyplnteJmeno')" />
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </DxItem>
                <DxItem  id="fixed-width-predcisli" :width="100">
                  <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                  <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                  <DxLocation :row="3" :col="0" :colspan="2" screen="sm" />
                  <DxLocation :row="3" :col="0" :colspan="2" screen="xs" />
                  <div class="SmlouvaBox">
                    <DxTextBox :disabled="stateid != 4" :id="3" height="45px" class="centered-textbox"
                      :label="$t('Pozadavky.HostKarta.Predcisli')" placeholder="+420" :value="formData.predcisli"
                      @value-changed="setSelectedValue($event)" mask="+099" :use-mask-behavior="true" mask-char=" "
                      show-mask-mode="onFocus" :maskInvalidMessage="$t('Pozadavky.HostKarta.VyplntePredcisli')"
                      @input="handleInput" @focus-in="onFocus" @key-down="onKeyDown">
                      <DxValidator @validated="handleValidation">
                        <DxRequiredRule :message="$t('Pozadavky.HostKarta.VyplntePredcisli')" />
                      </DxValidator>

                    </DxTextBox>
                  </div>
                </DxItem>
                <DxItem>
                  <DxLocation :row="2" :col="1" :colspan="4" screen="lg" />
                  <DxLocation :row="2" :col="1" :colspan="4" screen="md" />
                  <DxLocation :row="3" :col="2" :colspan="3" screen="sm" />
                  <DxLocation :row="3" :col="2" :colspan="3" screen="xs" />
                  <div class="SmlouvaBox">
                    <DxTextBox :disabled="stateid != 4" :id="4" width="auto" height="45px"
                      :label="$t('Pozadavky.HostKarta.Telefon')" placeholder="" :max-length="14"
                      :value="formData.telefon" @value-changed="onPhoneInput" @key-down="blockNonNumericInput($event)">

                      <DxValidator @validated="handleValidation">
                        <DxRequiredRule :message="$t('Pozadavky.HostKarta.VyplnteTelefon')" />
                        <DxPatternRule :pattern="/^\d{9,14}$/"
                          :message="$t('Pozadavky.HostKarta.SpatnyFormatTelefonu')" />
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </DxItem>
                <DxItem>
                  <DxLocation :row="2" :col="5" :colspan="5" screen="lg" />
                  <DxLocation :row="2" :col="5" :colspan="5" screen="md" />
                  <DxLocation :row="3" :col="5" :colspan="5" screen="sm" />
                  <DxLocation :row="3" :col="5" :colspan="5" screen="xs" />
                  <div class="SmlouvaBox">
                    <DxTextBox :id="5" width="auto" height="45px" :label="$t('Pozadavky.HostKarta.Email')"
                      placeholder="" :search-enabled="true" :value="formData.email"
                      @value-changed=" setSelectedValue($event);">
                      <DxValidator @validated="handleValidation">
                        <DxRequiredRule :message="$t('Pozadavky.HostKarta.VyplnteEmail')" />
                        <DxEmailRule :message="$t('Pozadavky.HostKarta.ZadejtePlatnyEmail')" />
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </DxItem>
                <DxItem>
                  <DxLocation :row="3" :col="0" :colspan="10" screen="lg" />
                  <DxLocation :row="3" :col="0" :colspan="10" screen="md" />
                  <DxLocation :row="4" :col="0" :colspan="10" screen="sm" />
                  <DxLocation :row="4" :col="0" :colspan="10" screen="xs" />
                  <div class="SmlouvaBox">
                    <DxTextBox height="45px" :disabled="stateid != 4" :id="6" :label="$t('Pozadavky.HostKarta.Adresa')"
                      placeholder="" @value-changed="setSelectedValue" :value="formData.adresa" :maxLength="255">
                      <DxValidator @validated="handleValidation">
                        <DxRequiredRule :message="$t('Pozadavky.HostKarta.VyplnteAdresu')" />
                      </DxValidator>
                    </DxTextBox>
                  </div>
                </DxItem>
              </DxResponsiveBox>
            </div>
          </DxItem>
          <DxItem>
            <DxLocation :row="0" :col="1" :colspan="1" screen="lg" />
            <DxLocation :row="0" :col="1" :colspan="1" screen="md" />
            <DxLocation :row="1" :col="0" :colspan="2" screen="sm" />
            <DxLocation :row="1" :col="0" :colspan="2" screen="xs" />
            <div class="obaleniFormularInner">
              <DxResponsiveBox single-column-screen="xs" id="SmlouvaResponsiveBox">
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxItem>
                  <DxLocation :row="0" :col="0" :colspan="10" screen="lg" />
                  <DxLocation :row="0" :col="0" :colspan="10" screen="md" />
                  <DxLocation :row="0" :col="0" :colspan="10" screen="sm" />
                  <DxLocation :row="0" :col="0" :colspan="10" screen="xs" />

                  <div class="SmlouvaBox">
                    <span class="form-part-title" id="stay-details">{{ $t('Pozadavky.HostKarta.UdajePobyt') }}</span>
                  </div>
                </DxItem>
                
                <DxItem>
                  <DxLocation :row="1" :col="0" :colspan="10" screen="lg" />
                  <DxLocation :row="1" :col="0" :colspan="10" screen="md" />
                  <DxLocation :row="1" :col="0" :colspan="10" screen="sm" />
                  <DxLocation :row="1" :col="0" :colspan="10" screen="xs" />
                  <div class="SmlouvaBox">
                    <DxTextArea style="margin-bottom: 0px;" :disabled="stateid != 4" :id="8"
                      :label="$t('Pozadavky.HostKarta.Duvod')" width="auto" height="100px"
                      v-model:value="formData.duvod" @value-changed="setSelectedValue" :maxLength="255">
                      <DxValidator>
                        <DxRequiredRule :message="$t('Pozadavky.HostKarta.VyplnteDuvod')" />
                      </DxValidator>
                    </DxTextArea>
                  </div>
                </DxItem>
                <DxItem>
                  <DxLocation :row="2" :col="1" :colspan="8" screen="lg" />
                  <DxLocation :row="2" :col="1" :colspan="8" screen="md" />
                  <DxLocation :row="2" :col="1" :colspan="8" screen="sm" />
                  <DxLocation :row="2" :col="0" :colspan="5" screen="xs" />
                  <div class="SmlouvaBox">
                    <DxDateRangeBox :id="7" ref="dateRangeBoxPlatnost" height="45px"
                      :start-date-label="$t('Pozadavky.HostKarta.PlatnostOd')"
                      :end-date-label="$t('Pozadavky.HostKarta.PlatnostDo')" apply-value-mode="instantly"
                      multiview:="true" :startDatePlaceholder="$t('Pozadavky.HostKarta.PocatekPlatnosti')"
                      :endDateOutOfRangeMessage="$t('Pozadavky.HostKarta.ChybaPlatnostDo') + ' (' + new Intl.DateTimeFormat('cs-CZ', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(maxDate.getTime() - 24 * 60 * 60 * 1000) + ')'"
                      :endDatePlaceholder="$t('Pozadavky.HostKarta.KonecPlatnosti')" :disabled="stateid != 4"
                      v-model:startDate="formData.platnostOd" v-model:endDate="formData.platnostDo"
                      date-serialization-format="dd-mm-yyyy" @value-changed=" setSelectedValue($event);"
                      :accept-custom-value="false" :open-on-field-click="true" :min="today" :max="maxDate">
                      <DxValidator ref="dateRangeBoxValidator">
                        <DxRequiredRule :message="$t('Pozadavky.HostKarta.VyplntePlatnost')" />
                      </DxValidator>
                    </DxDateRangeBox>
                  </div>
                </DxItem>
              </DxResponsiveBox>

            </div>
          </dxitem>
        </DxResponsiveBox>
      </div>

      <div id="obaleniFormulare" padding-top="5px" v-if="!zakladani">
        <DxResponsiveBox single-column-screen="sm" id="SmlouvaResponsiveBox" class="ResponsiveBoxClass obaleniFormularInner" width="100%">
          <DxRow :ratio="1" />
          <DxRow :ratio="1" />
          <DxRow :ratio="1" />
          <DxCol :ratio="1" />
          <DxCol :ratio="1" />
          <DxCol :ratio="0.5" />
          <DxCol :ratio="1" />
          <DxCol :ratio="1" />
          <DxCol :ratio="1" />
          <DxItem>
            <DxLocation :row="0" :col="3" :colspan="3" screen="lg" />
            <DxLocation :row="0" :col="3" :colspan="3" screen="md" />
            <DxLocation :row="1" :col="0" :colspan="6" screen="sm" />
            <DxLocation :row="1" :col="0" :colspan="6" screen="xs" />
            <div>
              <Attachments :Disabled="stateid != 4" :Pozid="this.poz" :FileUploadFileType="1" :ListFilesfileType="1"
                :FileDeleteFileType="1" />
            </div>

          </DxItem>

          <DxItem>
            <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
            <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
            <DxLocation :row="0" :col="0" :colspan="6" screen="sm" />
            <DxLocation :row="0" :col="0" :colspan="6" screen="xs" />
            <div class="dx-field" v-if="!zakladani">

              <DxTextArea class="DxSelectBoxResponsive" :disabled="stateid != 4" :id="11"
                :label="$t('majetekPrevod-Komentar')" width="auto" :height="100" @value-changed="setSelectedValue"
                v-model:value="formData.komentar" placeholder="" />
            </div>
          </DxItem>
        </DxResponsiveBox>
      </div>
      <DxResponsiveBox single-column-screen="sm" class="SpodniMenuDetailPozadavku">
        <DxRow :ratio="1" />
        <DxRow :ratio="1" />
        <DxRow :ratio="1" />
        <DxCol :ratio="1" />
        <DxCol :ratio="1" />
        <DxCol :ratio="1" />
        <DxCol :ratio="1" />
        <DxCol :ratio="1" />

        <DxItem>
          <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
          <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
          <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
          <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />
          <div id="ResponsiveBoxItemActionButtons" v-if="zakladani">
            <DxButton icon="add" id="vytvorit" height="auto" width="auto" :text="$t('Pozadavky.HostKarta.Zalozit')"
              :use-submit-behavior="true" type="success" />

          </div>
        </DxItem>

        <DxItem>
          <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
          <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
          <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
          <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
          <DxScrollView direction="horizontal" showScrollbar="always" width="100%" v-if="!zakladani">
            <div id="ResponsiveBoxItemActionButtons" v-if="!zakladani">
              <div class="child actionButtonsPozadavkyMargin" v-for="item in actionsPozadavky" :key="item"
                id="actionbuttons">
                <DxButton class="button1 actionButtonsPozadavkyResizableA" height="100%" width="auto" type="default"
                  styling-mode="contained" :text="item.nazev" :id="item.id" @click="SetState(item.id)"
                  :class="item.class" :icon="item.icon"
                  v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
              </div>

              <div class="child">
                <ChangeOwner :Disabled="false" :RequestID="poz" @owner-changed="setSelectedValue"> </ChangeOwner>
              </div>
            </div>
          </DxScrollView>

        </DxItem>
      </DxResponsiveBox>

    </form>



  </div>

</template>

<script>
//import axios from 'axios'
import ChangeOwner from '@/components/ChangeOwner.vue';
import notify from 'devextreme/ui/notify';
//import Employees from '@/components/Employees.vue';
//import Suppliers from '@/components/Suppliers.vue';
import Attachments from '@/components/Attachments.vue';
import DxTextBox from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import HelpLink from '@/components/HelpLink.vue';
import DxDateRangeBox from 'devextreme-vue/date-range-box';
import {
  DxValidator,
  DxRequiredRule,
  DxEmailRule,
  //   DxRangeRule,
  //  DxCustomRule,
} from 'devextreme-vue/validator';
import { isNull, isUndefined } from 'lodash';
import DxScrollView from 'devextreme-vue/scroll-view';
export default {
  name: "SmlouvaView",

  components: {
    DxTextArea,
    DxDateRangeBox,
    //  Employees,
    //    Suppliers,
    Attachments,
    DxTextBox,
    ChangeOwner,
    HelpLink,
    DxValidator,
    DxEmailRule,
    // DxCustomRule,
    DxScrollView,
    DxRequiredRule,
    //   DxRangeRule,
  },

  data() {
    return {
      //  pattern: /^\d{}/i,
      DateRangeBoxValidationErrors: [],
      formData: {
        jmeno: "",
        prijmeni: '',
        predcisli: '420',
        telefon: null,
        adresa: '',
        platnostOd: null,
        platnostDo: null,
        duvod: '',
        komentar: '',
        oddeleni: '',
        grantID: null,
        vedouci: '',
        vedouciID: null,
      },
      today: new Date(),
      maxDate: (() => {
        const date = new Date(); // Dnešní datum
        date.setMonth(date.getMonth() + 12); // Přidání 3 měsíců
        date.setDate(date.getDate() - 1);   // Odečtení 1 dne
        return date;
      })(),
      smlouvyTypy: [],
      PocetKolonek: 10,  // kolikrat se zavola setselectedvalue +1, pokud je komentar vyplneny, dava se PocetKolonek++
      MountingData: false,
      stateid: 4,
      state: 'Uložený',
      popupAlert: false,
      popupAlertTemplate: '',
      actionsPozadavky: [],
      Via: [{ content: '', id: -1 }],
      komentar: '',
      suppliers: [],
      supplier: [{ content: '', id: -1 }],
      vias: [],
      poz: 0,
      zakladani: true,
      selectedOption: null,
      hasValidationError: false,
      endDateUpdated: false,
    }
  },
  computed: {
    computedRequestTitle() {
      return this.zakladani
        ? this.$t('Pozadavky.HostKarta.Zalozeni')
        : `${this.$t('Pozadavky.HostKarta.Nadpis')}: ${this.poz} (${this.state})`;
    },
  },
  watch: {

  },
  created() {
    window.MountCount = 0
  },
  mounted() {
    this.ListVias();
    this.MountingData = true
    this.GetUserInfo()
    if (window.localStorage.getItem("pozid") != '' && window.history.state.back != null) {
      this.zakladani = false
      this.poz = window.localStorage.getItem("pozid")
      this.DisplayRequest();

    }
    if (!this.zakladani) { if (this.poz) this.GetGuestCard(this.poz); }
    this.LoadBarvyTheme();
  },

  methods: {
    onKeyDown(e) {
      this.$log.debug(e)
      if (e.event.key === ' ') {
        e.event.preventDefault();
      }
    },
    onFocus(e) {
      this.$log.debug(e)
      const input = e.event.target;
      const value = this.formData.predcisli;
      this.$log.debug("-" + value + "-")
      if (value === "" || value === "+") {
        // Pokud není vyplněno nic kromě "+", umístíme kurzor na pozici 1
        input.setSelectionRange(1, 1);
      }
    },
    handleInput(e) {
      let val = e.event.target.value;
      this.$log.debug(e)
      // Povolit jen plus a číslice
      val = val.replace(/[^+\d]/g, '');

      // Plus musí být na začátku
      if (!val.startsWith('+')) {
        val = '+' + val.replace(/\+/g, '');
      }

      // Omezit délku na max. 4 znaky (např. +420 má 4 znaky)
      if (val.length > 4) {
        val = val.slice(0, 4);
      }

      // Aktualizujeme hodnotu v textboxu i v datech
      e.event.target.value = val;
      //  this.formData.predcisli = val;
    },

    GetUserInfo() {
      var user = JSON.parse(window.localStorage.getItem("user"))
      this.formData.grantID = user.PrimaryUnit?.value;
      this.formData.oddeleni = user.PrimaryUnitTXT?.value;
      this.formData.vedouci = user.PrimaryUnitLeaderTXT?.value;
      this.formData.vedouciID = user.PrimaryUnit?.value;

    },
    handleValidation(e) {
      this.$log.debug(e)
      this.hasValidationError = !e.isValid;
    },
    LoadBarvyTheme() {
      var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
      var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
      var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
      var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.25)");
      //console.log('Background Color:', FillColor);
      document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
      document.documentElement.style.setProperty('--colorByThemeQuarterOpacity', rgbaColorTheme);
    },
    SetState(stav, alert) {
      window.this.$log.debug(alert + ' alert<<<??>')
      let self = this;
      var wh = new window.WorkFlow(window.channel, window.message);

      if (stav == -1) {
        window.this.$log.debug('konec')
        this.popupAlert = true
        this.popupAlertTemplate = 'SmazatPoz'
        return 1;
      }
      if (alert == true) stav = -1
      // self.open();
      wh.SetState(self.poz, stav, '',
        function (result) {
          window.this.$log.debug(result);
          if (result.ErrorNum.value != 0) {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          }
          else {
            self.DisplayRequest();
            if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4)) self.$router.push('/Pozadavky?Vypis=0')

            var stavText;
            switch (stav) {
              case -1: stavText = self.$t('pozadavky-StavZrusen')
                break;
              case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                break;
              case 4: stavText = self.$t('pozadavky-StavUlozeny')
                break;
            }
            notify({
              message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'success', 5000);
          }
          //   self.hide();
        }, window.this.ApiError());

    },
    GetGuestCard(id) {
      let self = this
      var wf = new window.WorkFlow(window.channel, window.message);
      wf.GetGuestCard(id,
        function (result) {
          self.$log.debug(result);
          if (result.Error.value.ErrorNum.value != 0) {
            notify({
              message: result.Error.value.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

          }
          else {
            self.formData.jmeno = result.Name.value
            self.formData.prijmeni = result.Surname.value
            self.formData.predcisli = result.PhonePrefix.value
            self.formData.telefon = result.Phone.value
            self.formData.email = result.Mail.value
            self.formData.adresa = result.Address.value
            self.formData.platnostOd = result.ValidFrom.value
            self.formData.platnostDo = result.ValidUntil.value
            self.formData.duvod = result.PurposeOfVisit.value

          }
        }, window.this.ApiError());
    },
    ListVias() {
      let self = this;
      self.vias.length = 0;
      var gen = new window.General(window.channel, window.message);
      gen.ListLookup('REQUEST_VIAS[2]', '', 1, 50,
        function (result) {
          window.this.$log.debug(result);
          /*
          for (var x = 0; x < result.List.value.items.length; x++) {
 
            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.vias.length;
            self.vias[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
          */
          window.this.$log.debug(self.zakladani);
          if (!self.zakladani) self.GetContract(self.poz)
          //  self.Via[0] = self.vias[0]
          //else

        }, window.this.ApiError());

    },
    DisplayRequest() {
      window.this.$log.debug('display request')
      let self = this;
      self.actionsPozadavky.length = 0;
      var wh = new window.WorkFlow(window.channel, window.message);
      wh.GetRequest(self.poz,
        function (result2) {
          window.this.$log.debug(result2);
          self.formData.komentar = result2.Comment.value;
          if (self.formData.komentar != '') self.PocetKolonek++
          self.state = result2.ReqStateText.value;
          self.stateid = result2.ReqState.value;
          for (var x = 0; x < result2.Actions.value.items.length; x++) {
            var id = result2.Actions.value.items[x].Id.value;
            var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
            var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
            self.actionsPozadavky[self.actionsPozadavky.length] = {
              nazev: result2.Actions.value.items[x].Value.value,
              id: result2.Actions.value.items[x].Id.value,
              class: colorClass,
              icon: iconName,
              pozid: self.poz
            };
          }
        }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
    },
    onFormSubmit(e) {

      window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
      window.this.$log.debug(e)
      this.SetGuestCard();
      e.preventDefault();

    },
    setSelectedValue(e) {
      window.MountCount++
      window.this.$log.debug(e);
      window.this.$log.debug(window.MountCount + 'MountCount');
      window.this.$log.debug(this.PocetKolonek + 'Dokolika');
      if (window.MountCount == this.PocetKolonek) this.MountingData = false // tohle neni uplne dobry napad delat
      if (e.value == null) return 1;
      switch (e.element.id) {
        case '1':
          this.formData.prijmeni = e.value
          break;
        case '2':
          this.formData.jmeno = e.value
          break;
        case '3':
          this.formData.predcisli = e.value
          break;
        case '4':
          this.formData.telefon = e.value
          break;
        case '5':
          this.formData.email = e.value
          break;
        case '6':
          this.formData.adresa = e.value
          break;
        case '7':
          /*
          if (e.previousValue[0]!=e.value[0]) {
            var date = e.value[0];
            date.setMonth(date.getMonth() + 3); 
            date.setDate(date.getDate() - 1); 
            const dateRangeBox = this.$refs.dateRangeBoxPlatnost.instance;
            
            this.$log.debug(date)
            dateRangeBox.option('max', date); // Nastavíme nové maximum  
            this.maxDate = date
            dateRangeBox.repaint();
            dateRangeBox.open();
          }
          */

          if (e.previousValue[0] !== e.value[0]) {
            var date = new Date(e.value[0]); // Vytvoříme kopii původního data
            date.setMonth(date.getMonth() + 3);
            date.setDate(date.getDate() - 1);



            const originalStartDate = e.value[0];
            const dateRangeBox = this.$refs.dateRangeBoxPlatnost.instance;
            dateRangeBox.option('max', date);
            this.maxDate = date;
            dateRangeBox.option('startDate', originalStartDate);
            if (e.value[1] > date) {
              this.endDateUpdated = true;
              dateRangeBox.option('endDate', date);

            }
            this.$log.debug(dateRangeBox)
            //  dateRangeBox.open();
          } else if (this.endDateUpdated) {
            this.endDateUpdated = false;
            return 1;
          }
          this.$log.debug('min', this.$refs.dateRangeBoxPlatnost.instance.option('min'))
          this.$log.debug('max', this.$refs.dateRangeBoxPlatnost.instance.option('max'))
          this.$log.debug('max2', this.formData.platnostDo)
          this.$log.debug(this.$refs.dateRangeBoxPlatnost.instance)
          this.$refs.dateRangeBoxPlatnost.instance.repaint();

          //this.formData.platnostOd = e.value[0]
          //  this.formData.platnostDo = e.value[1]
          break;
        case '8':
          this.formData.duvod = e.value
          break;
        case '11':
          // setuje se komentar v komponente primo, ale je potreba zavolat setselectedvalue
          break;
        case 'ownerchanged':
          this.$router.push('Pozadavky')
          return 1;
      }
      if (!isUndefined(e.value) && !isNull(e.value) && !this.zakladani && !this.MountingData && !this.hasValidationError)
        this.SetGuestCard()

    },
    SetGuestCard() {
      var self = this;
      var wh = new window.WorkFlow(window.channel, window.message);
      var predvolba = self.formData.predcisli
      if (predvolba && !predvolba.startsWith('+')) {
        predvolba = '+' + predvolba;
      }
      this.$log.debug(this.formData)
      wh.SetGuestCard(self.poz, 0, self.formData.jmeno, self.formData.prijmeni,
        self.formData.email, predvolba, self.formData.telefon, self.formData.adresa, self.formData.duvod, self.formData.platnostOd, self.formData.platnostDo, self.formData.komentar,
        function (result) {
          window.this.$log.debug(result);
          if (result.Error.value.ErrorNum.value == 0) {
            //console.log(self.poz)
            if (self.poz == 0) {
              self.poz = result.Value.value
              window.localStorage.setItem("pozid", result.Value.value)
              self.zakladani = false
              self.MountingData = false
              notify({
                message: self.$t('Pozadavky.HostKarta.Zalozeno'),
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'success', 3000);
              self.DisplayRequest()
            }
            else {
              notify({
                message: self.$t('Pozadavky.HostKarta.ZmenyUlozeny'),
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'success', 3000);
            }

          }
          else {
            notify({
              message: result.Error.value.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 3000);

          }
        }, window.this.ApiError());
    },
    onPhoneInput(event) {
      let value = event.component.option("value") || "";


      if (value.includes("+")) {
        value = value.replace(/\+/g, "");
      }

      let sanitizedValue = value.replace(/\D/g, '').substring(0, 14);

      this.formData.telefon = sanitizedValue;
      event.component.option("value", sanitizedValue);
      this.setSelectedValue(event);
    },
    blockNonNumericInput(event) {
      const key = event.event.originalEvent?.key; 

      this.$log.debug('blockNonNumericInput event:',event, key);
      if (!/^\d$/.test(key) &&
          !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(key)) {
            event.event.originalEvent.preventDefault(); 
      }
    }
  }
};
</script>

<style scoped>
:deep(.dx-daterangebox-separator) {
  padding-left: 0;
}

:deep(.dx-daterangebox .dx-dropdowneditor-icon::before) {
  content: "\f026";
}

:deep(.dx-icon-to::before) {
  content: "\f00e";
}

.ResponsiveBoxClass {
  padding: 10px;
}

#DruhyResponsiveBox {
  border-style: ridge;
  border-color: rgba(128, 128, 128, 0.1);
  border-radius: 10px;

  background-color: rgba(128, 128, 128, 0.075);
  flex: none !important;
}

.SmlouvaBox {
  padding: 5px;
}

#SmlouvaResponsiveBox {
  border-style: ridge;
  border-color: rgba(128, 128, 128, 0.1);
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.075);
  padding: 5px;
}

#checkboxzverejnit {
  padding-top: 25px;

}

.checkboxzverejnitZvyraz {
  padding: 5px;
  border-style: ridge;
  border-color: rgba(46, 46, 46, 0.1);
  border-radius: 10px;
  background-color: rgba(170, 3, 3, 0.459);
  width: max-content;
  font-weight: bold;
}

.buttonDefault:hover,
.buttonUlozitProUpravy:hover,
.buttonKeSchvaleni:hover,
.buttonSmazat:hover,
.buttonKEditaci:hover {
  background-color: var(--colorByThemeLighter);
  border: 0px;

}

.buttonKeSchvaleni {
  background: #5cb83c;
  border-color: none;
  border: 0px;
}

.buttonUlozitProUpravy {
  background: #3cb8b8;
  border-color: none;
  border: 0px;
}

.buttonSmazat {
  background: #d9534f;
  border-color: none;
  border: 0px;
}

.buttonKEditaci {
  background: #ffcc33;
  border-color: none;
  border: 0px;
}

.buttonDefault {
  background: none;
  border-color: none;
  border: 0px;
}

.clCheckBoxForma {
  margin-top: 0px !important;
}

.radio-option {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.radio-option input[type="radio"] {
  margin-right: 5px;
}

.radio-option label {
  font-weight: normal;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

#smlouvyZpusobPodpis {
  margin: 2px 0px;
  font-weight: bold;
}

.form-part-title {
  display: block;
  padding: 5px 10px 5px 10px;
  font-size: 17px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.form-part-title {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}

#obaleniFormulare {
  /*display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;*/
}

#obaleniFormulare>.obaleniFormularInner:first-child {
  padding: 5px 5px 0px 10px;
}

#obaleniFormulare>.obaleniFormularInner:not(:first-child) {
  padding: 5px 5px 5px 5px;
}
#fixed-width-predcisli{
  width: 65px !important;
  min-width: 65px !important;
    max-width: 65px !important;
}
#obaleniFormulare {
  padding: 5px 10px 0px 10px;
}

.obaleniFormularInner {
  width: 50%;
  padding: 2.5px 2px;
}
.centered-textbox ::v-deep(.dx-texteditor-input) {
  text-align: center;
}
</style>