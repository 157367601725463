

<template>
<DxScrollView height="90%">
    <div id="vydejky">
        <div class="clNadpisAktualniStranky" height="50px">
        <h2 class="nadpisH2Moduly prevent-select">{{$t('sklad-vydejky')}}</h2>
      </div>
       
        <!-- Modal form for click in the datatable -->
        <div>
            <DxPopup height="auto" v-model:visible="popupVisible" :show-title="true" :drag-enabled="true" :resize-enabled="true"
                :hide-on-outside-click="true" :show-close-button="true" :title="$t('sklad-Informace')" id="popupDataGridVydejky" width="100vmin">
                    <DxScrollView height="100%" width="100%" directions="vertical">
                        
                        <div class="labels">
                                            
                            <label id="labelmodal2" class="modal-label" >{{$t('sklad-Misto:')}} <label class="lighterWeightFont">{{ description }}</label></label>
                            <label id="labelmodal3" class="modal-label">{{$t('sklad-Zamestnanec:')}} <label class="lighterWeightFont" >{{ empname }}</label></label>
                            <label id="labelmodal4" class="modal-label">{{$t('sklad-Grant:')}} <label class="lighterWeightFont" >{{ fundingname }}</label> </label>
                            <div id="dxDateBox1">
                            <DxDateBox width="150px" display-format="dd. MM. yyyy" :label="$t('sklad-Datum')" :value="date" type="datetime" :readOnly="true" :accept-custom-value="false" :open-on-field-click="true"/>
                            </div>
                        </div>
                        <div class="modal-content">

                        </div>
                        
                        <hr class="hrModuly">
                        <div class="bordersDataGridPozadavkyModuly">
                        <DxDataGrid ref="popupgrid" id="DataGridPopUp" :data-source="rows1" :hoverStateEnabled="true" :rowAlternationEnabled="true" :height="(vyskaZobrazitelna/1.8)" width="100%"
                            :allow-column-reordering="true" :show-borders="true" key-expr="id" column-resizing-mode="widget"  :allow-column-resizing="true" :column-auto-width="true" 
                            :noDataText="$t('central-NenalezenyZadneZaznamy')">
                            <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
                            <DxExport
                            :enabled="true"
                            :formats="['xlsx']"
                            :allow-export-selected-data="false"
                            :texts="{ exportAll: $t('central-ExportVseExcel') }"
                            />
                            <DxStateStoring
                            :enabled="true"
                            type="localStorage"
                            storage-key="GridVydejkyDetail"
                            />
                            <DxToolbar >
                                        <DxItem location="before" locate-in-menu="auto"
                                                name="groupPanel"
                                        />
                                        <DxItem  location="after" locate-in-menu="auto" >
                                            <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                            type="success" styling-mode="outlined" @click="GridLayoutReset('DataGridPopUp'); " />      <!---->
                                        </DxItem>
                                        <DxItem location="after" locate-in-menu="auto"
                                            name="exportButton"
                                        />
                                        <DxItem location="after" locate-in-menu="auto"
                                            name="searchPanel"
                                            :visible="true"
                                        />
                                        
                            </DxToolbar>                        
                            <DxColumn data-field="name" :caption="$t('central-Nazev')" width="max-content" />
                            <DxColumn  format=",##0.00" data-field="amount" :caption="$t('central-Pocet')" width="50px" />
                            <DxColumn data-field="measureUnit" :caption="$t('central-Jednotka')" width="50px" />
                            <DxColumn format=",##0.00"  data-field="value" :caption="$t('central-Cena')" width="150px" />
                            <DxPaging :enabled="false" />
                            <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form" />
                            <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                            <DxGrouping :auto-expand-all="true" />
                            <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                            <DxSearchPanel :width="300" :visible="true" :placeholder="$t('central-Hledat')" />
                            <DxFilterRow :visible="true"/>
                            <DxFilterPanel :visible="true" :texts="{
                                clearFilter: $t('central-ZrusitFiltr'), createFilter: $t('central-VytvoritFiltr'), filterEnabledHint: $t('central-FiltrVysvetlivka'), saveChanges: 'Povrdit',
                            }"/>
                            <DxFilterBuilderPopup value="text" />
                            <DxHeaderFilter :visible="true"/>

                        </DxDataGrid>
                    </div>
                    </DxScrollView>
            </DxPopup>

        </div>
            <hr class="hrModuly">
            <div class="bordersDataGridPozadavkyModuly">
            <DxDataGrid  ref="mygrid" id="DataGrid" :data-source="rows" :showScrollbar="true" column-resizing-mode="widget" :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"  :hoverStateEnabled="true" :rowAlternationEnabled="true"
                 :show-borders="true" key-expr="id_vydejky" @editing-start="logEvent('EditingStart')" :height="(vyskaZobrazitelna/1.3)" 
                @init-new-row="logEvent('InitNewRow')" @row-inserting="logEvent('RowInserting')"
                @row-inserted="logEvent('RowInserted')" @row-updating="logEvent('RowUpdating')"
                @row-updated="logEvent('RowUpdated')" @row-removing="logEvent('RowRemoving')"
                @row-removed="logEvent('RowRemoved')" @saving="logEvent('Saving')" @saved="logEvent('Saved')"
                @edit-canceling="logEvent('EditCanceling')" @edit-canceled="logEvent('EditCanceled')"
                @option-changed="SearchInput" :noDataText="$t('central-NenalezenyZadneZaznamy')" @row-click="ShowPopUp" @exporting="onExporting">
                <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
                <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="GridVydejkyZobrazeni"
                />
                <DxExport
                :enabled="true"
                :formats="['xlsx']"
                :allow-export-selected-data="false"
                :texts="{exportAll: $t('central-ExportVseExcel')}"
                />
                <DxToolbar >
                                        <DxItem location="before" locate-in-menu="auto"
                                                            name="groupPanel"
                                        />
                                        <DxItem  location="after" locate-in-menu="auto" widget="dxButton">
                                            <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                            type="success"  styling-mode="outlined" @click="GridLayoutReset('DataGrid')" />      
                                        </DxItem>
                                        <DxItem location="after" locate-in-menu="auto"
                                        name="exportButton"
                                        />
                                        <DxItem location="after" locate-in-menu="auto"
                                        name="searchPanel"
                                        :visible="true"
                                        />
                                        
                            </DxToolbar>         
                <DxColumn width="50px" data-field="id_vydejky" :caption="$t('sklad-Id')" :visible="false" />
                <DxColumn width="50px" data-field="cislo" :caption="$t('sklad-Cislo')" data-type="number" :visible="true" />
                <DxColumn width="100px" data-field="date" :caption="$t('sklad-Datum')" format="dd. MM. yyyy" />
                <DxColumn width="auto" data-field="grant" :caption="$t('sklad-Grant')" />
                <DxColumn width="225px" data-field="zamestnanec" :caption="$t('sklad-Zamestnanec')" />
                <DxColumn width="150px" data-field="typ_pohybu" :caption="$t('sklad.TypPohybu')" :fixed='true'  fixedPosition="right"/>
                <DxPaging :enabled="false" />
                <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                <DxSearchPanel :width="150" :visible="true" :placeholder="$t('central-Hledat')" />
                <DxFilterRow :visible="true"/>
                    <DxFilterPanel :visible="true" :texts="{
                        clearFilter: $t('central-ZrusitFiltr'), createFilter: $t('central-VytvoritFiltr'), filterEnabledHint: $t('central-FiltrVysvetlivka'), saveChanges: 'Povrdit',
                    }"/>
                    <DxFilterBuilderPopup value="text" />
                    <DxHeaderFilter :visible="true"/>

            </DxDataGrid>
        <br> 
            <DxButton icon="chevrondown" type="button" height="auto" width="auto" :text="$t('central-NacistDalsiZaznamy')" class="button4 buttonsDataGridSpodniLista" 
            v-on:click="Button4Click()" :disabled="disable" />
            <DxButton icon="arrowdown" type="button" height="auto" width="auto" :text="$t('central-NacistVse')" class="button4 buttonsDataGridSpodniLista" 
            v-on:click="ButtonAllClick()" :disabled="disable" />
        
        </div>

    </div>
</DxScrollView>
</template>

<script>

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid as exportDataGridToExcel} from 'devextreme/excel_exporter';
import {DxExport, DxStateStoring, DxLoadPanel, DxHeaderFilter,
  DxFilterRow,
  DxFilterPanel,
  DxFilterBuilderPopup,DxToolbar,DxSorting} from 'devextreme-vue/data-grid';
    export default {
    name: "VydejkyView",
    components: {
        DxExport, 
        DxStateStoring,
        DxLoadPanel,
        DxHeaderFilter,
        DxFilterRow,
        DxFilterPanel,
        DxFilterBuilderPopup,
        DxToolbar,
        DxSorting
    }, 
    data() {
        return {
            popupVisible: false,
            disable: false,
            page: 1,
            description: "",
            empname: "",
            fundingname: "",
            dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
            dateOutputFormat: "dd.MM.yyyy",
            date: "",
            showModal: false,
            hledat: "",
            events: [],        
            rows: [],
            rows1: [],
            msg:this.$t('sklad-vydejky'),
            vyskaZobrazitelna:250
        //    dataSource: this.rows,
        };
    },
    computed: {
        dataGrid: function () {
            let self = this;
            return self.$refs.mygrid.instance;
        },
        dataGridPopUp: function () {
            let self = this;      
            return self.$refs.popupgrid.instance;
        }
        

    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
        },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
      //  window.localStorage.setItem("refresh-page", 'Vydejky')
       // console.log(window.localStorage.getItem("refresh-page"))
        this.TableData(this.hledat);
        this.DrawerSelected();
        setTimeout(() => {
                    this.$nextTick(() => {
                        this.myEventHandler();
            });}, 1000);
    },
    created: function () {
        
        
    },
 
    methods: {
        myEventHandler(e){
            this.$log.debug(e);
            this.handleMainViewChange()
          },
    handleMainViewChange(){
       //     console.log("handleMainViewChange");
                let self = this;
                var element = document.getElementById("ScrollViewAPP");
                    if (element) {
             //           console.log(element);
                        var height = element.clientHeight;
                        if ((height<500) && (height>300))  {height=550} 
                        if (height<=300) {height=450} 
                        self.vyskaZobrazitelna = height;
                        
        
           // console.log("handleMainViewChange height:", height);
}
            
        },
        onExporting(e) {
      this.$log.debug('test uvnitr onexporting')
    if (e.format==="xlsx") {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Export");
        exportDataGridToExcel({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
          customizeCell: function(options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                    excelCell
                } 
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            const datum= new Date();
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              this.msg+" ("+datum.toLocaleDateString('cs-CZ')+").xlsx"
            );
          });
        });
        e.cancel = true;
      }
    },
        DrawerSelected() {
            var e = window.Drawer
            if (window.prevElement != undefined && window.prevPosition != undefined)
                window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
            this.$log.debug(window.Drawer)
            this.$log.debug(this.$route.meta.id)
            this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length)
            for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
                // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
                if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {
                    e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = '#FF9900'
                    window.prevElement = e
                    window.prevPosition = x
                }
            }
        },
        SearchDelay(search) {
            let self = this;
            clearTimeout(this.timer)
            // console.log(search)
            this.timer = setTimeout(() => {
                self.page = 1;
                self.TableData(search);
            }, 1000)

        },
        ShowPopUp(e) {
           // console.log(e)
           setTimeout(() => { // kvuli oznacovani aby se hned neotevrel popup
            const selection = window.getSelection();
            if (selection && selection.toString().length > 0) {
                // User is selecting text; do not proceed
                return;
            }
            this.popupVisible = true;
            this.Detail(e.data.id_vydejky);
            }, 0);       
        },
        SearchInput: function (e) {
           // window.this.$log.debug(e)

            if (e.name === "searchPanel") {
                this.searched = false
                window.this.$log.debug(e.value)
                if (e.fullName != 'searchPanel.placeholder')
                    this.SearchDelay(e.value)
            }
            else {

                // this.Detail(params.row.id_vydejky);

            }
        },
        /*
        SearchPanelEvent() {
           // console.log(this.dataGrid)
            if (this.dataGrid.fullName === "searchPanel.text")
              //  console.log(this.dataGrid.fullName)
                    // start time         
            dataGrid.onOptionChanged: function(e) {
                if (e.fullName === "searchPanel.text") {
                    // start time  
                }
            }, 
        },
*/
        RefreshDataGrid() {
            this.dataGrid.refresh()
                .then(function () {
                    // ...
                })
                .catch(function () {
                  //  console.log(error)
                    // ...
                });
        },
    /*    open() {
            window.loader = this.$loading.show({
                loader: "dots"
            });
        },
        hide() {
            window.loader.hide();
        },
        */
        Button4Click() {
            this.page++;
            let self = this;
            this.TableData(self.hledat);
        },
        ButtonAllClick() {
          this.page=0;
          this.disable=true;
          this.TableData(self.hledat);
        },        
        TableData(Hledat) {
            var wh = new window.Warehouse(window.channel, window.message);
            let self = this.rows;
            let self2 = this;
            if (this.page != 0) this.disable = false
            if (this.page <= 1) {
                //console.log('nulovani')
                self.length = 0;
            }
           // console.log(Hledat)
        
            wh.ListDelivery_v3(Hledat, 0, this.page, 15,'Vydej,Navrat', function (result2) {
                if (result2.List.value.items.length == 0 || result2.List.value.items.length < 15) {
                    self2.disable = true;
                }
               self2.$log.debug(result2)
                for (var x = 0; x < result2.List.value.items.length; x++) {
                    var i = self.length;
                    self[i] = {                      
                        id_vydejky: result2.List.value.items[x].Id.value,
                        date: result2.List.value.items[x].Date.value,
                        grant: result2.List.value.items[x].FundingName.value,
                        zamestnanec: result2.List.value.items[x].EmpName.value,
                        cislo: result2.List.value.items[x].Number.value,
                        typ_pohybu: result2.List.value.items[x].MovementType.value,
                    };
                }
                self2.RefreshDataGrid()
               // console.log('refresh')
           
            }, window.this.ApiError());   
            
        },
        GridLayoutReset(vstup) {
            switch (vstup) {
                case 'DataGridPopUp': this.dataGridPopUp.state(null);
                    break;
                case 'DataGrid': this.dataGrid.state(null);
                    break;
                
            }   
            //this.$log.debug('GridLayoutReset');
    },
        Detail(id) {
            var det = new window.Warehouse(window.channel, window.message);
            let self = this.rows1;
            let self2 = this;
            self.length = 0;
            // this.open();  
            det.GetDelivery(id, function (result) {   
                for (var x = 0; x < result.List.value.items.length; x++) {
                    var i = self.length;
                    self[i] = {
                        id: result.List.value.items[x].Id.value,
                        name: result.List.value.items[x].Name.value,
                        amount: result.List.value.items[x].Amount.value,
                        measureUnit: result.List.value.items[x].MeasureUnit.value,
                        value: result.List.value.items[x].Value.value
                    };
                   
                }
                self2.description = result.Description.value;
                self2.empname = result.EmpName.value;
                self2.fundingname = result.FundingName.value;
                self2.date = result.Date.value;
                self2.dataGridPopUp.refresh()
            }, window.this.ApiError());
        },
    },
}
</script>

<style scoped >


.dx-datagrid-search-panel {
    float: left !important;
}

.dx-field-label{
    width:15%;
    margin-left: 25px;
}


    #menu {
        background-color: #6fb3db;
        border: 1px solid #CCCCCC;
        padding: 20px;
        margin-top: 80px;
    }
    #text1 {
      margin-bottom: 30px;
      margin-right: 10px;
      margin-top: 10px;
    }
    #label1  {
      color:darkgreen;
    }
    #label2  {
      color:rgb(38, 0, 255);
    }
    #labelmodal{
      margin-bottom: 30px;
    }
    #labelmodal2{order: 3;}
    #labelmodal3{order: 2;}
    #labelmodal4{order: 1;}
    #dxDateBox1{order:4;
        border-style: ridge;
    border-color: black;
    border-radius: 0px;
    border-top: 00;
    border-bottom: 00;
    border-right: 00;
    padding: 5px;
    margin-right: 25px;
    flex-shrink: 0;
}
.modal-label{
    font-weight: bold;
}
    .labels {
      margin-top: 10px;
      color:rgb(0, 0, 0);
      padding:10px;
      width: 100%;
    
    display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
    }


   
    #obaleniDataGrid1,#obaleniDataGrid2{
        border-style: ridge;
    border-color: rgba(128, 128, 128, 0.226);
    border-radius: 10px;
    padding: 10px;
    height: max-content;
    }
    #checkboxMeVydejky{margin: 5px;}
    #h2Vydejky{text-align: center;}
    #popupDataGridVydejky{width: 20vmin;}
    #labelobsah{font-weight: lighter;}
    #ObnovitRozlozeni{scale: 85%;color: inherit; border-color: gray;}
   
    

   

</style>